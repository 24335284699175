
  import { defineComponent, PropType, watch } from 'vue'
  import Dropdown from '@/components/Dropdown.vue'
  import DropdownItem from '@/components/DropdownItem.vue'
  import store, { UserProps } from '../store'
  import createMessage from '@/hooks/createMessage'
  import router, { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'GlobalHeader',
    components: {
      Dropdown,
      DropdownItem
    },
    props: {
      user: {
        type: Object as PropType<UserProps>,
        required: true
      },
      is_bg: {
        type: String,
        default: 'other'
      }
    },
    setup() {
      let cityCode = localStorage.getItem('cityCode')
      let cityName = localStorage.getItem('cityName')
      if (!cityCode) {
        cityCode = '450100'
        cityName = '南宁'
        localStorage.setItem('cityCode', '450100')
        localStorage.setItem('cityName', '南宁')
      }
      const router = useRouter()
      const exit = () => {
        // console.log('exit', '退出登录')
        store.commit('outLogin', () => {
          createMessage('已退出登录', 'success')
          setTimeout(() => {
            // 跳转页面
            router.push('/')
          }, 500)
        })
      }
      const link_select_city = () => {
        router.push('/select_city.html')
      }
      return {
        exit,
        link_select_city,
        cityCode,
        cityName
      }
    }
  })
