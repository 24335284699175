<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #F6F6F6;">
    <div class="d-flex flex-row align-items-center mt-3 mb-3" style="width: 1150px;">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/center.html">个人中心</a></a-breadcrumb-item>
        <a-breadcrumb-item>房源管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex align-items-center" style="margin: 40px 50px 20px 50px;">
          <span style="font-size: 17px;margin-right: 20px;" :style="data.type == 20?'color: #FF9326;':'color: #999;'" @click="click_type(20)">二手房</span>
          <span style="font-size: 17px;" :style="data.type == 30?'color: #FF9326;':'color: #999;'" @click="click_type(30)">租房</span>
        </div>
        <div class="d-flex flex-column" style="margin: 20px 50px 20px 50px;">
          <div class="d-flex flex-column mt-3" v-for="(item,index) in list" :key="index">
            <div class="d-flex flex-row align-items-center">
              <img :src="item.cover_pic" style="width: 253px;height: 188px;border-radius: 10px;">
              <div class="d-flex flex-column ms-4" style="flex: 1;">
                <div class="d-flex flex-row align-items-start justify-content-between">
                  <span style="font-size: 23px;color: #333333;font-weight: bold;flex: 1;">{{item.name}}</span>
                  <div class="d-flex flex-row align-items-end justify-content-end ms-3" style="min-width: 150px;">
                    <span style="font-size: 24px;color: #FD6769;margin-right: 5px;font-weight: bold;">{{item.total_price}}</span>
<!--                    <span style="font-size: 14px;color: #FD6769;margin-bottom: 6px;">万元</span>-->
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-between mt-1">
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-row align-items-center mt-1">
                      <img src="../../assets/image/fangyuanguanli_icon_dingwei_default.png" style="width: 19px;height: 19px;margin-right: 5px;">
                      <span style="font-size: 17px;color: #999;">{{item.community}}</span>
                    </div>
                    <div class="d-flex flex-row align-items-center mt-1">
                      <img src="../../assets/image/fangyuanguanli_icon_huxing_default.png" style="width: 19px;height: 19px;margin-right: 5px;">
                      <span style="font-size: 17px;color: #999;">{{item.synopsis}}</span>
                    </div>
                  </div>
                  <a-popconfirm
                    title="是否确认删除该房源?"
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="delHouse(item)"
                  >
                  <img src="../../assets/image/fangyuanguanli_icon_delete_default.png" style="width: 56px;height: 56px;">
                  </a-popconfirm>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-between mt-4">
                  <span style="font-size: 17px;color: #999;font-weight: bold;">{{item.status_str}}</span>
                  <div class="d-flex align-items-center flex-row">
                    <router-link :to="data.type === 20 ?`/sencond_hand_edit.html/${item.id}`:`/rent_edit.html/${item.id}`" style="height: auto;">
                      <span style="background-color: #FF9326;color: white;padding: 4px 20px;border-radius: 5px;margin-left: 10px;">编辑房源</span>
                    </router-link>
                    <a-popconfirm
                      title="是否确认此操作?"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="upDownHouseShow(item)"
                    >
                    <span style="background-color: #FF9326;color: white;padding: 2px 20px;border-radius: 5px;margin-left: 10px;" v-if="item.is_jump == 1">{{item.is_show == 1 ? '下架房源':'立即上架'}}</span>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
            <a-divider />
          </div>
          <div class="d-flex flex-row align-items-center w-100 justify-content-end">
            <a-button @click="goBack" v-if="data.page !== 1">上一页</a-button>
            <div style="font-size: 18px;color: #666;margin: 0 20px;">第{{data.page}}页</div>
            <a-button @click="goNext" v-if="nextpage">下一页</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, getCurrentInstance, onMounted, toRaw } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import createMessage from '@/hooks/createMessage'
  import { useRouter } from 'vue-router'

  interface DataState {
    id ? : number;
    page ? : number;
    type ? : number;
  }

  export default defineComponent({
    name: 'HousePublish',
    inject: ['reload'],
    setup() {
      const router = useRouter()
      // const page = 1
      let nextpage = false
      // const datab = getCurrentInstance()
      const data: DataState = {
        page: 1,
        type: 20
      }
      onMounted(() => {
        store.dispatch('userMyRelease', data)
      })
      const store = useStore()
      const list = computed(() => store.state.secondhand_list)
      if (list.value.length === 10) {
        nextpage = true
      }
      // 上下架
      const upDownHouseShow = (item: any) => {
        // console.log('上下架房源', item)
        data.id = item.id
        store.dispatch('userUpDownHouseShow', data).then(data => {
          createMessage('操作成功', 'success')
          setTimeout(() => {
            location.reload()
          }, 500)
        }).catch(e => {
          // console.log('onFormSubmit', e)
        })
      }
      const delHouse = (item: any) => {
        // console.log('删除房源', item)
        data.id = item.id
        let url = 'delSecondHandHouse'
        if (data.type === 30) {
          url = 'delRentHouse'
        }
        store.dispatch(url, data).then(data => {
          createMessage('操作成功', 'success')
          setTimeout(() => {
            location.reload()
          }, 500)
        }).catch(e => {
          // console.log('onFormSubmit', e)
        })
      }
      const goBack = () => {
        if (data.page) {
          data.page = data.page - 1
          if (data.page < 1) {
            data.page = 1
          }
        }
        store.dispatch('userMyRelease', data)
      }
      const goNext = () => {
        if (data.page) {
          if (nextpage) {
            data.page = data.page + 1
          }
        }
        store.dispatch('userMyRelease', data)
      }
      const click_type = (type:number) => {
        data.type = type
        store.dispatch('userMyRelease', data)
      }
      return {
        list,
        goBack,
        goNext,
        nextpage,
        data,
        upDownHouseShow,
        delHouse,
        click_type
      }
    }
  })
</script>

<style scoped>

</style>
