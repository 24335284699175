
  import { defineComponent, computed, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { GlobalDataProps } from '../store'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import ColumnList from '@/components/ColumnList.vue'
  import Uploader from '@/components/Uploader.vue'
  import Message from '@/components/Message.vue'
  import createMessage from '@/hooks/createMessage'

  export default defineComponent({
    name: 'App',
    components: {
      ColumnList,
      Uploader
    },
    setup() {
      const store = useStore<GlobalDataProps>()
      onMounted(() => {
        store.dispatch('fetchColumns')
      })
      const list = computed(() => store.state.columns)
      console.log('list', list)
      const biggerColumnLen = computed(() => store.getters.biggerColumnLen)
      const beforeUpload = (file: File) => {
        console.log('beforeUpload')
        const isJPG = file.type === 'image/jpeg'
        if (!isJPG) {
          createMessage('上传图片智能是JPG格式！', 'error')
        }
        return isJPG
      }
      return {
        list: list,
        biggerColumnLen,
        beforeUpload
      }
    }
  })
