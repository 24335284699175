<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #ffffff;">
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex flex-row align-items-center justify-content-between mt-3">
          <a-breadcrumb>
            <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
            <a-breadcrumb-item><a href="/secondhand_list.html">二手房</a></a-breadcrumb-item>
            <a-breadcrumb-item>{{house.name}}</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="d-flex flex-row align-items-center me-3">
            <a-input-search
              v-model:value="keyword"
              placeholder="请输入房屋信息"
              size="small"
              class="mt-3 ms-5"
              style="width: 348px;"
              @search="onSreach"
            >
              <template #enterButton>
                <a-button style="background-color: #FF9326;color: white;width: 94px;">搜索</a-button>
              </template>
            </a-input-search>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center" style="margin-top: 40px;">
          <span style="font-size: 26px;color: #333333;font-weight: bold;">{{house.name}}</span>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <div style="width: 516px;margin-top: 20px;">
              <a-carousel arrows dots-class="slick-dots slick-thumb">
                <template #customPaging="props">
                  <a>
                    <img :src="getImgUrl(props.i)"/>
                  </a>
                </template>
                <div v-for="(item,index) in house.detail_albums" :key="index">
                  <a-image :src="item" style="width: 500px;height: 337px;object-fit: cover;"/>
                </div>
              </a-carousel>
            </div>
          </div>
          <div class="d-flex flex-column" style="margin-left: 45px;flex: 1;">
            <div class="d-flex flex-row align-items-center" style="margin-top: 60px;">
              <span style="font-size: 15px;color: #999999;margin-right: 15px;">售价</span>
              <span style="font-size: 28px;color:#FD6769;font-weight: bold;margin-bottom: 4px;margin-right: 2px;">{{house.total_price}}</span>
              <span style="font-size: 17px;color:#FD6769;margin-top: 2px;">万元</span>
            </div>
            <a-divider />
            <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 0 40px;">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span style="font-size: 18px;color: #333333;font-weight: bold;">{{house.decoration_str}}</span>
                <span style="font-size: 13px;color: #333333;margin-top: 5px;">装修</span>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span style="font-size: 18px;color: #333333;font-weight: bold;">{{house.house_type}}</span>
                <span style="font-size: 13px;color: #333333;margin-top: 5px;">房型</span>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span style="font-size: 18px;color: #333333;font-weight: bold;">{{house.building_area}}</span>
                <span style="font-size: 13px;color: #333333;margin-top: 5px;">面积</span>
              </div>
            </div>
            <a-divider />
            <div class="d-flex flex-column">
              <div class="d-flex flex-row align-items-center mt-2">
                <img src="../../assets/default_img.png" style="width: 130px;height: 130px;border-radius: 5px;">
                <div class="d-flex flex-column ms-4">
                  <div class="d-flex flex-row align-items-center">
                    <span style="font-size: 21px;color: #333333;font-weight: bold;margin-right: 10px;">{{house.sale_name}}</span>
                    <span style="font-size: 14px;color: #96AFC0;background-color: #F2F5F7;padding: 1px 10px;border-radius: 5px;">{{house.is_saler == 0 ? '个人':'经纪人'}}</span>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-3">
                    <img src="../../assets/image/xinfangxiangqing_icon_dianhua_default.png" style="width: 18px;height: 18px;">
                    <span style="font-size: 15px;color: #FF9326;margin-left: 5px;">{{house.sale_phone}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 24px;color: #333333;font-weight: bold;">房屋信息</span>
          <div class="d-flex flex-row align-items-center justify-content-between mt-3">
            <div class="d-flex flex-row align-items-center" style="width: 300px;">
              <span style="width: 80px;font-size: 17px;color: #BBB;">单价</span>
              <span style="font-size: 17px;color: #333;">{{house.average_price}}</span>
            </div>
            <div class="d-flex flex-row align-items-center flex-grow-1">
              <span style="width: 80px;font-size: 17px;color: #BBB;">挂牌</span>
              <span style="font-size: 17px;color: #333;">{{house.on_date_str}}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-1">
            <div class="d-flex flex-row align-items-center" style="width: 300px;">
              <span style="width: 80px;font-size: 17px;color: #BBB;">朝向</span>
              <span style="font-size: 17px;color: #333;">{{house.orientation}}</span>
            </div>
            <div class="d-flex flex-row align-items-center flex-grow-1">
              <span style="width: 80px;font-size: 17px;color: #BBB;">楼层</span>
              <span style="font-size: 17px;color: #333;">{{house.all_floor_str}}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-1">
            <div class="d-flex flex-row align-items-center" style="width: 300px;">
              <span style="width: 80px;font-size: 17px;color: #BBB;">梯户比</span>
              <span style="font-size: 17px;color: #333;">{{house.all_proportion_str}}</span>
            </div>
            <div class="d-flex flex-row align-items-center flex-grow-1">
              <span style="width: 80px;font-size: 17px;color: #BBB;">电梯</span>
              <span style="font-size: 17px;color: #333;">{{house.elevator_str}}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-1">
            <div class="d-flex flex-row align-items-center" style="width: 300px;">
              <span style="width: 80px;font-size: 17px;color: #BBB;">装修</span>
              <span style="font-size: 17px;color: #333;">{{house.decoration_str}}</span>
            </div>
            <div class="d-flex flex-row align-items-center flex-grow-1">
              <span style="width: 80px;font-size: 17px;color: #BBB;">年代</span>
              <span style="font-size: 17px;color: #333;">{{house.housing_years}}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-1">
            <div class="d-flex flex-row align-items-center" style="width: 300px;">
              <span style="width: 80px;font-size: 17px;color: #BBB;">用途</span>
              <span style="font-size: 17px;color: #333;">{{house.house_use}}</span>
            </div>
            <div class="d-flex flex-row align-items-center flex-grow-1">
              <span style="width: 80px;font-size: 17px;color: #BBB;">权属</span>
              <span style="font-size: 17px;color: #333;">{{house.ownership_str}}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-1">
            <div class="d-flex flex-row align-items-center" style="width: 300px;">
              <span style="width: 80px;font-size: 17px;color: #BBB;">小区</span>
              <span style="font-size: 17px;color: #333;">{{house.community_name}}</span>
            </div>
            <div class="d-flex flex-row align-items-center flex-grow-1">
              <span style="width: 80px;font-size: 17px;color: #BBB;">抵押</span>
              <span style="font-size: 17px;color: #333;">{{house.mortgage_str}}</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 24px;color: #333333;font-weight: bold;margin-bottom: 20px;">小区信息</span>
          <div class="d-flex flex-row align-items-center mb-3">
            <img src="../../assets/default_img.png" style="width: 251px;height: 187px;object-fit: cover;" v-if="!house.community_cover_pic">
            <img :src="house.community_cover_pic" style="width: 251px;height: 187px;object-fit: cover;" v-if="house.community_cover_pic">
            <div class="d-flex flex-column flex-grow-1 ms-5">
              <span style="font-size: 20px;color: #333333;font-weight: bold;">{{house.community_name}}</span>
              <div class="d-flex flex-row align-items-center mt-2">
                <span style="font-size: 16px;color: #999;margin-right: 10px;">参考价格</span>
                <span style="font-size: 16px;color: #FF9326;font-weight: bold;">{{house.community_average_price}}</span>
              </div>
              <div class="d-flex flex-row align-items-center mt-2">
                <span style="font-size: 16px;color: #666;">{{house.community_info}}</span>
              </div>
              <div class="d-flex flex-row align-items-center mt-2">
                <span style="font-size: 16px;color: #666;">{{house.community_count}}</span>
              </div>
            </div>
          </div>
          <map-container :lat="house.latitude" :lng="house.longitude" :title="house.community_name" :address="house.community_count"></map-container>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 24px;color: #333333;font-weight: bold;">为您推荐</span>
          <div class="d-flex flex-row align-items-center justify-content-between mt-3 flex-wrap">
            <div class="d-flex flex-column mt-2" style="width: 267px;" v-for="(item,index) in secondhand_list" :key="index">
              <div class="d-flex flex-column position-relative" style="border-radius: 5px;" v-if="index < 8">
                <a :href="`/secondhand_detail.html/${item.id}`">
                  <img :src="item.cover_pic" class="w-100" style="height: 205px;object-fit: cover;border-radius: 5px;">
                </a>
                <span class="font_size5 text-dark mt-2" style="font-weight: bold!important;">{{item.name}}</span>
                <div class="d-flex flex-row align-items-center justify-content-between mt-1">
                  <span class="font_size5 mt-1" style="color: #666;">{{item.building_area}} {{item.house_type}}</span>
                  <span class="font_size5 mt-1" style="color: #FF9326;">{{item.total_price}}万</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import store from '@/store'
  import MapContainer from '@/components/MapContainer.vue'
  export default defineComponent({
    name: 'SecondHandDetail',
    components: {
      MapContainer
    },
    setup() {
      onMounted(() => {
        store.dispatch('getsecondHandHouseDetail', id)
      })
      setTimeout(() => {
        store.dispatch('getRandESHouseList', {
          city_id: localStorage.getItem('cityCode'),
          community_id: house.value.community_id
        })
      }, 1000)
      const route = useRoute()
      const router = useRouter()
      const id = +route.params.id
      // console.log('id', id)
      const keyword = ref('')
      const house = computed(() => store.state.secondhand)
      const secondhand_list = computed(() => store.state.secondhand_list)
      const getImgUrl = (i) => {
        return house.value.detail_albums[i]
      }
      const onSreach = () => {
        // console.log('搜索', keyword)
        router.push({ name: 'secondhand_list', params: { keywords: keyword.value } })
      }
      return {
        keyword,
        house,
        getImgUrl,
        secondhand_list,
        onSreach
      }
    }
  })
</script>

<style scoped>
  .ant-carousel :deep(.slick-dots) {
    position: relative;
    height: auto;
    width: 96% !important;
    text-align: left!important;
    margin-left: 3px!important;
  }
  .ant-carousel :deep(.slick-slide img) {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    max-width: 100%;
  }
  .ant-carousel :deep(.slick-arrow) {
    display: none !important;
  }
  .ant-carousel :deep(.slick-thumb) {
    bottom: 0px;
  }
  .ant-carousel :deep(.slick-thumb li) {
    width: 95px!important;
    height: 66px!important;
  }
  .ant-carousel :deep(.slick-thumb li img) {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
  }
  .ant-carousel :deep .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
</style>
