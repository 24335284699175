<template>
  <!-- 瞬间移动 -->
  <teleport to="#back">
    <div class="d-flex justify-content-center align-items-center h-100 loading-container"
         :style="{backgroundColor:backgroud || ''}">
      <div class="loading-content">
        <div class="spinner-border text-danger" role="status">
          <!--        <span class="sr-only">{{ text || 'loading...'}}</span>-->
        </div>
        <p v-if="text" class="text-danger small">{{text}}</p>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent, onUnmounted } from 'vue'
  import useDOWCreate from '@/hooks/useDOWCreate'

  export default defineComponent({
    name: 'Loader',
    props: {
      text: {
        type: String
      },
      backgroud: {
        type: String
      }
    },
    setup() {
      // 在页面中加入节点实现瞬间移动
      useDOWCreate('back')
    }
  })
</script>

<style>
  .loading-container {
    background: rgba(255, 255, 255, .5);
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .loading-content {
    text-align: center;
  }
</style>
