
  import { computed, defineComponent, getCurrentInstance, onMounted, toRaw } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import createMessage from '@/hooks/createMessage'
  import { useRouter } from 'vue-router'

  interface DataState {
    id ? : number;
    page ? : number;
    type ? : number;
  }

  export default defineComponent({
    name: 'HousePublish',
    inject: ['reload'],
    setup() {
      const router = useRouter()
      // const page = 1
      let nextpage = false
      // const datab = getCurrentInstance()
      const data: DataState = {
        page: 1,
        type: 20
      }
      onMounted(() => {
        store.dispatch('userMyRelease', data)
      })
      const store = useStore()
      const list = computed(() => store.state.secondhand_list)
      if (list.value.length === 10) {
        nextpage = true
      }
      // 上下架
      const upDownHouseShow = (item: any) => {
        // console.log('上下架房源', item)
        data.id = item.id
        store.dispatch('userUpDownHouseShow', data).then(data => {
          createMessage('操作成功', 'success')
          setTimeout(() => {
            location.reload()
          }, 500)
        }).catch(e => {
          // console.log('onFormSubmit', e)
        })
      }
      const delHouse = (item: any) => {
        // console.log('删除房源', item)
        data.id = item.id
        let url = 'delSecondHandHouse'
        if (data.type === 30) {
          url = 'delRentHouse'
        }
        store.dispatch(url, data).then(data => {
          createMessage('操作成功', 'success')
          setTimeout(() => {
            location.reload()
          }, 500)
        }).catch(e => {
          // console.log('onFormSubmit', e)
        })
      }
      const goBack = () => {
        if (data.page) {
          data.page = data.page - 1
          if (data.page < 1) {
            data.page = 1
          }
        }
        store.dispatch('userMyRelease', data)
      }
      const goNext = () => {
        if (data.page) {
          if (nextpage) {
            data.page = data.page + 1
          }
        }
        store.dispatch('userMyRelease', data)
      }
      const click_type = (type:number) => {
        data.type = type
        store.dispatch('userMyRelease', data)
      }
      return {
        list,
        goBack,
        goNext,
        nextpage,
        data,
        upDownHouseShow,
        delHouse,
        click_type
      }
    }
  })
