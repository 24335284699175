<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #F6F6F6;">
    <div class="d-flex flex-row align-items-center mt-3 mb-3" style="width: 1150px;">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/center.html">个人中心</a></a-breadcrumb-item>
        <a-breadcrumb-item><a href="/house_publish.html">房源管理</a></a-breadcrumb-item>
        <a-breadcrumb-item>出租房源编辑</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex flex-row align-items-center justify-content-between" style="margin: 60px 80px 0px 80px;">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/image/form_tag_1.png" style="width: 40px;height: 40px;">
            <span style="font-size: 20px;font-weight: normal;color: #FF9326;margin-top: 10px;">填写信息</span>
          </div>
          <div style="height: 1px;flex: 1;background-color: #FF9326;margin: 0 20px;"></div>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/image/form_tag_2.png" style="width: 40px;height: 40px;">
            <span style="font-size: 20px;font-weight: normal;color: #FF9326;margin-top: 10px;">审核认证</span>
          </div>
          <div style="height: 1px;flex: 1;background-color: #FF9326;margin: 0 20px;"></div>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/image/form_tag_3.png" style="width: 40px;height: 40px;">
            <span style="font-size: 20px;font-weight: normal;color: #FF9326;margin-top: 10px;">房产展示</span>
          </div>
        </div>
        <a-form ref="formRef" :model="form" label-width="120px" label-position="left" :label-col="labelCol" :rules="rules"
                style="margin: 60px 80px;">
          <a-form-item label="标题" name="title">
            <a-input v-model:value="form.title" placeholder="例如:小区名称+装修情况+户型+特点" maxlength="50" show-word-limit
                     type="text" style="width: 800px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="小区" name="community_id">
            <a-select
              v-model:value="form.community_name"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="data"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
            ></a-select>
          </a-form-item>
          <a-form-item label="面积" name="building_area">
            <a-input-number :min="1" :max="999" v-model:value="form.building_area" placeholder="请输入面积" maxlength="5"
                            show-word-limit type="text"
                            style="width: 300px;margin-left: 10px;"></a-input-number>
            <span style="font-size: 14px;color: #333333;margin-left: 10px;">㎡</span>
          </a-form-item>
          <a-form-item label="户型" name="house_type_name">
            <a-select
              v-model:value="form.house_type_name"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="houseTypeData"
            ></a-select>
          </a-form-item>
          <a-form-item label="房屋类型" name="property_type">
            <a-select
              v-model:value="form.property_type"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="floorTypeStrData"
            ></a-select>
          </a-form-item>
          <a-form-item label="装修" name="renovation_str">
            <a-select
              v-model:value="form.renovation_str"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectDecoration"
            ></a-select>
          </a-form-item>
          <a-form-item label="朝向" name="orientation">
            <a-select
              v-model:value="form.orientation"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectOrientation"
            ></a-select>
          </a-form-item>
          <a-form-item label="电梯" name="elevator">
            <a-select
              v-model:value="form.elevator"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectElevator"
            ></a-select>
          </a-form-item>
          <a-form-item label="租赁方式" name="rent_type">
            <a-select
              v-model:value="form.rent_type"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="rentTypeData"
            ></a-select>
          </a-form-item>
          <a-form-item label="付费方式" name="pay_type">
            <a-select
              v-model:value="form.pay_type"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="payTypeData"
            ></a-select>
          </a-form-item>
          <a-form-item label="楼层" name="floor">
            <a-input-group style="margin-left: 10px;" compact>
              <a-input style="width: 150px;" v-model:value="form.floor" maxlength="3" suffix="楼"/>
              <a-input style="width: 150px;" v-model:value="form.total_floor" maxlength="3" suffix="总层"/>
            </a-input-group>
          </a-form-item>
          <a-form-item label="月租金" name="rental_price">
            <a-input-number :min="1" :max="999" v-model:value="form.rental_price" placeholder="请输入金额" maxlength="7"
                            show-word-limit type="text"
                            style="width: 300px;margin-left: 10px;"></a-input-number>
            <span style="font-size: 14px;color: #333333;margin-left: 10px;">元/月</span>
          </a-form-item>
          <a-divider>补充信息</a-divider>
          <a-form-item label="入驻时间" name="settlement_str">
            <a-input v-model:value="form.settlement_str" placeholder="请输入入驻时间" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="押金方式" name="deposit_str">
            <a-input v-model:value="form.deposit_str" placeholder="请输入押金方式" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="车位情况" name="parking_str">
            <a-input v-model:value="form.parking_str" placeholder="请输入车位情况" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="是否免佣">
            <a-switch v-model:checked="form.is_free_intermediary" style="width: 100px;margin-left: 10px;"/>
          </a-form-item>
          <a-form-item label="服务费" name="service_str" v-if="!form.is_free_intermediary">
            <a-input v-model:value="form.service_str" placeholder="请输入服务费" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="中介费" name="intermediary_str" v-if="!form.is_free_intermediary">
            <a-input v-model:value="form.intermediary_str" placeholder="请输入中介费" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-divider>配套设施</a-divider>
          <div class="d-flex flex-row flex-wrap align-items-center justify-content-between mt-3 w-100">
            <div class="d-flex flex-column align-items-center justify-content-center mt-3" v-for="(item,index) in form.rentUsed" :key="index">
              <img :src="item.show ? item.simg : item.nimg" style="width: 56px;height: 56px;" @click="click_used(index)">
              <span style="font-size: 21px;font-weight: normal;margin-top: 10px;" :style="item.show ? 'color: #333333;' : 'color: #999999;'">{{item.name}}</span>
            </div>
          </div>
          <a-divider>上传房屋图片（第一张将作为封面图,至少上传三张）</a-divider>
          <div class="d-flex flex-row align-items-center flex-wrap">
            <div style="width: 113px;height: 133px" class="me-3 mt-3 position-relative d-flex flex-column align-items-center" v-for="(item,index) in form.imgs" :key="index">
              <a-image :src="item" style="width: 113px;height: 113px;object-fit: cover"/>
              <span @click.prevent="del(index)">删除</span>
            </div>
            <uploader action="/upload" class="mt-3" style="margin-bottom: 21px;" :before-upload="beforeUpload" :finish-upload="finishUpload"></uploader>
          </div>
          <a-divider/>
          <a-form-item>
            <a-button type="primary" @click="onSubmit">提交审核</a-button>
            <a-button @click="goBack">返回上一步</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import createMessage from '@/hooks/createMessage'
  import moment, { Moment } from 'moment'
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
  import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
  import {
    houseTypeData,
    floorTypeStrData,
    selectDecoration2,
    selectPropertyRight,
    selectOrientation,
    selectElevator,
    payTypeData,
    rentTypeData,
    rentUsedData,
    selectHouseUse, SelectData, RentUsedData
  } from '@/testData'
  import Uploader from '@/components/Uploader.vue'
  import axios from 'axios'
  import { useRoute, useRouter } from 'vue-router'

  export const imgsData:string[] = []
  export interface FormState {
    id: number; // id
    title?: string; // 标题
    community_id?: string; // 小区名称
    community_name?: string, // 小区名称
    building_area?: string; // 面积
    house_type_name?: string; // 户型
    property_type?: string; // 房屋类型
    renovation_str?: string; // 装修
    orientation?: string; // 朝向
    elevator?: string; // 电梯
    rent_type?: string; // 租赁方式
    pay_type?: string; // 付费方式
    floor?: string; // 楼层
    total_floor?: string; // 总层
    rental_price?: string; // 月租金
    settlement_str?: string; // 入住时间
    deposit_str?: string; // 押金方式
    parking_str?: string; // 车位情况
    is_free_intermediary?: boolean|string; // 是否免佣
    service_str?: string; // 服务费
    intermediary_str?: string; // 中介费
    pic1?: string; // 室内图字符串
    tags?: string; // 配套设施字符串
    imgs?: string[]; // 室内图
    rentUsed?: any; // 配套设置
  }

  let timeout: any
  let currentValue = ''
  function fetch(value: string, callback: any) {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value

    function fake() {
      let cityCode = localStorage.getItem('cityCode')
      if (!cityCode) {
        cityCode = '450100'
      }
      const param = new URLSearchParams()
      param.append('city_id', cityCode)
      param.append('name', value)
      axios.post('community/searchCommunityList', param, {}).then(resp => {
        // console.log('community/searchCommunityList', resp.data.data)
        const result = resp.data.data
        const data: any[] = []
        result.forEach((r: any) => {
          data.push({
            value: r.id,
            label: r.name
          })
        })
        callback(data)
      })
    }
    timeout = setTimeout(fake, 300)
  }
  export default defineComponent({
    name: 'RentAdd',
    components: {
      Uploader
    },
    methods: {
      change() {
        this.$forceUpdate()
      },
      goBack() {
        this.$router.go(-1)
      }
    },
    setup(props) {
      onMounted(() => {
        // 读取租房数据
        store.dispatch('getRentDetail', id)
      })
      const rent = computed(() => store.state.rent)
      setTimeout(() => {
        // console.log('rent', rent.value)
        formState.title = rent.value.name
        formState.community_id = rent.value.community_id
        formState.community_name = rent.value.community_name
        formState.building_area = rent.value.house_area
        formState.house_type_name = rent.value.house_type_name
        formState.property_type = rent.value.property_type_id
        formState.renovation_str = rent.value.renovation_str
        formState.orientation = rent.value.orientation
        formState.elevator = rent.value.elevator_str
        formState.rent_type = rent.value.rent_type_str
        formState.pay_type = rent.value.pay_type_str
        if (rent.value.floor) {
          const arr = rent.value.floor.split('/')
          if (arr.length > 1) {
            formState.floor = arr[0].replace('层', '')
          }
        }
        formState.total_floor = rent.value.total_floor
        formState.rental_price = rent.value.rental_price
        formState.settlement_str = rent.value.settlement_str
        formState.deposit_str = rent.value.deposit_str
        formState.parking_str = rent.value.parking_str
        formState.service_str = rent.value.service_str
        formState.intermediary_str = rent.value.intermediary_str
        formState.is_free_intermediary = rent.value.is_free_intermediary === 1
        formState.imgs = rent.value.detail_albums ?? []
        formState.tags = rent.value.ancillary_facility
        const tmp = formState.tags
        formState.rentUsed.forEach((item:any) => {
          if (tmp && tmp.indexOf(item.name) !== -1) {
            item.show = 1
          }
        })
      }, 1000)
      const router = useRouter()
      const route = useRoute()
      const id = +route.params.id
      // console.log('id', id)
      const datab = getCurrentInstance()
      const formRef = ref()
      const formState: UnwrapRef<FormState> = reactive({
        id: id,
        title: '', // 标题
        community_id: '', // 小区id
        community_name: '', // 小区名称
        building_area: '', // 面积
        house_type_name: '', // 户型
        property_type: '', // 房屋类型
        renovation_str: '', // 装修
        orientation: '', // 朝向
        elevator: '', // 电梯
        rent_type: '', // 租赁方式
        pay_type: '', // 付费方式
        floor: '', // 楼层
        total_floor: '', // 总层
        rental_price: '', // 月租金
        settlement_str: '', // 入住时间
        deposit_str: '', // 押金方式
        parking_str: '', // 车位情况
        is_free_intermediary: true, // 是否免佣
        service_str: '', // 服务费
        intermediary_str: '', // 中介费
        pic1: '', // 室内图字符串
        tags: '', // 配套设置字符串
        imgs: [], // 室内图
        rentUsed: rentUsedData // 配套设置
      })
      const checkNumber = async (rule: RuleObject, value: number) => {
        if (!value) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('请输入内容')
        } else {
          return Promise.resolve()
        }
      }
      const rules = {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 8, max: 50, message: '必须输入8-50个字符的标题信息', trigger: 'blur' }
        ],
        community_id: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        building_area: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        house_type_name: [
          { required: true, message: '请选择户型', trigger: 'change' }
        ],
        property_type: [
          { required: true, validator: checkNumber, trigger: 'change' }
        ],
        renovation_str: [
          { required: true, message: '请选择装修情况', trigger: 'change' }
        ],
        orientation: [
          { required: true, message: '请选择房屋朝向', trigger: 'change' }
        ],
        elevator: [
          { required: true, message: '请选择电梯情况', trigger: 'change' }
        ],
        rent_type: [
          { required: true, message: '请选择租赁方式', trigger: 'change' }
        ],
        pay_type: [
          { required: true, message: '请选择付费方式', trigger: 'change' }
        ],
        floor: [
          { required: true, message: '请输入楼层', trigger: 'blur' }
        ],
        total_floor: [
          { required: true, message: '请输入总层', trigger: 'blur' }
        ],
        rental_price: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        settlement_str: [
          { required: true, message: '请输入入住时间', trigger: 'blur' }
        ],
        deposit_str: [
          { required: true, message: '请输入押金方式', trigger: 'blur' }
        ],
        parking_str: [
          { required: true, message: '请输入车位情况', trigger: 'blur' }
        ]
      }
      // 提交
      const onSubmit = () => {
        // 是否免租
        formState.is_free_intermediary = formState.is_free_intermediary ? '1' : '0'
        // 配套设施
        var tags = ''
        formState.rentUsed.forEach((item:any) => {
          if (item.show) {
            tags += item.name + ','
          }
        })
        if (tags.length > 0) {
          tags = tags.substr(0, tags.length - 1)
        }
        formState.tags = tags
        // 室内图
        let imgstr = ''
        const imglist:any = formState.imgs
        imglist.forEach((item:string) => {
          item = item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '')
          item = item.replace('!syp1', '')
          imgstr += item + ','
        })
        if (imgstr.length > 0) {
          imgstr = imgstr.substr(0, imgstr.length - 1)
        }
        // console.log('室内图', imgstr)
        formState.pic1 = imgstr
        // console.log('values', formState, toRaw(formState))
        // 表单验证
        // console.log('submit', formState)
        formRef.value.validate().then(() => {
          if (imglist.length < 3) {
            createMessage('房屋图片至少上传三张', 'error')
            return false
          }
          // 提交表单
          store.dispatch('sellRentEdit', toRaw(formState)).then(data => {
            // console.log('sellRentEdit', data)
            createMessage('提交成功', 'success')
            setTimeout(() => {
              // 跳转页面
              router.push('/house_publish.html')
            }, 500)
          }).catch(e => {
            // console.log('onFormSubmit', e)
          })
        }).catch((error: ValidateErrorEntity<FormState>) => {
          console.log('error', error)
          createMessage('请按照要求填写完整的表单信息', 'error')
        })
      }
      // 选择小区
      const data = ref<any[]>([])
      const handleSearch = (val: string) => {
        // console.log('handleSearch', val)
        fetch(val, (d: any[]) => (data.value = d))
      }
      const handleChange = (val: string) => {
        // console.log('handleChange', val)
        formState.community_id = val
        fetch(val, (d: any[]) => (data.value = d))
      }
      // 上传图片 - 室内图
      const beforeUpload = (file: File) => {
        // console.log('beforeUpload')
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJPG) {
          createMessage('上传图片只能是JPG或PNG格式！', 'error')
        }
        return isJPG
      }
      const finishUpload = (img: string) => {
        // console.log('finishUpload', img)
        if (formState.imgs) {
          formState.imgs.push(img)
        }
      }
      const del = (index:number) => {
        // console.log('删除', index)
        if (formState.imgs) {
          formState.imgs.splice(index, 1)
        }
      }
      const click_used = (index:number) => {
        if (formState.rentUsed[index].show) {
          formState.rentUsed[index].show = false
        } else {
          formState.rentUsed[index].show = true
        }
      }
      return {
        labelCol: { span: 2 },
        rules,
        formRef,
        form: formState,
        onSubmit,
        data,
        handleSearch,
        handleChange,
        houseTypeData: houseTypeData,
        floorTypeStrData: [
          { value: 1, label: '住宅' },
          { value: 2, label: '商铺' },
          { value: 3, label: '公寓' },
          { value: 4, label: '别墅' },
          { value: 5, label: '写字楼' },
          { value: 6, label: '洋房' },
          { value: 7, label: '其他' }
        ],
        selectDecoration: selectDecoration2,
        selectOrientation: selectOrientation,
        selectElevator: selectElevator,
        rentTypeData: rentTypeData,
        payTypeData: payTypeData,
        beforeUpload,
        finishUpload,
        del,
        click_used
      }
    }
  })
</script>

<style scoped>

</style>
