<template>
  <div class="d-flex flex-column">
    <div style="height: 534px;background-color: #F9F9F9;" class="d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center" style="width: 1150px;">
        <div class="d-flex flex-column" style="flex: 1">
          <span style="font-size: 34px;color: #333;font-weight: bold;">关于我们</span>
          <span style="font-size: 13px;color: #333;font-weight: bold;">About us</span>
          <span class="mt-3" style="font-size: 15px;color: #333;font-weight: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广西宜豆网络科技有限公司，简称“宜豆科技”宜豆科技前身“南宁中宜房地产投资置业有限公司”公司成立于2014年，致力打造房地产全程综合服务商，
            随着移动互联网应用迅猛发展，移动互联网已经覆盖各个领域，正在深刻改变信息时代的社会生活，中宜于2020年转型移动互联网行业，成立“宜豆科技”中宜在房产领域深耕多年，深知房产中介行业的痛点、压力以及市场环境，竞争愈演愈烈，获客成本越来越高，
            营销工具普遍较为单一，为了让行业从业人员更便捷的拓客，使用更好的营销工具，宜豆科技专为房产中介领域研发了一款营销实用工具，于2021年11月正式发布由宜豆科技研发首款“宜豆找房”房产软件正式推向市场，“宜豆找房”是一款打破传统营销模式的互联网房产软件，
            秉承“不断创造购房新体验、实现互联网交易新方式”的愿景，让创业更简单，让居住更美好，集聚创新的网络营销模式已正式开启。</span>
        </div>
        <img src="../../assets/image/home_img_tupian_default.png" style="height: 534px;width: 608px;">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About'
  }
</script>

<style scoped>

</style>
