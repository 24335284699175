<template>
  <div class="d-flex flex-column">
    <div class="header w-100 d-flex flex-column" style="height: 700px;position: relative;">
      <global-header :user="currentUser"></global-header>
      <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
        <div class="flex-column" style="width: 1150px;">
          <div class="d-flex flex-row align-items-center">
            <span class="font_size1 me-4 text-white">品质源于专业</span>
            <span class="font_size2"
                  style="color: #828282;background-color: white;padding: 2px 10px 2px 10px;margin-top: 16px;">PROFESSIONAL</span>
          </div>
          <div class="d-flex flex-row align-items-center">
            <span class="font_size3 text-white me-4">优质房源</span>
            <span class="font_size3 text-white me-4">温暖到家</span>
            <span class="font_size3 text-white me-4">大量真实房源</span>
          </div>
          <div class="d-flex flex-row align-items-center mt-5">
            <a-select
              v-model:value="sreach_type"
              size="large"
              style="width: 120px"
              :options="options"
            ></a-select>
            <div class="sreach_bg position-relative d-flex align-items-center">
              <a-input class="ms-4 w-75" style="background:none;utline:none;border:none;color: white;" v-model:value="keyword" placeholder="请输入商区域、商圈或小区名开始找房"/>
              <div style="width: 100px;height: 30px;" @click="onSearch"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-5">
      <div class="flex-column" style="width: 1150px;">
        <div class="flex-column">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <span class="font_size3" style="color: #FF9326;">特惠房源</span>
              <span class="font_size2 mt-3 ms-3 me-3" style="color: #999;">/</span>
              <span class="font_size2 mt-3" style="color: #999;">Preferential housing</span>
            </div>
            <a href="/community_list.html">
            <div class="d-flex flex-row align-items-center mt-3">
              <span class="font_size2 me-2" style="color: #999;">更多</span>
              <img src="../../assets/image/home_icon_xiangyou_default.png">
            </div>
            </a>
          </div>
          <div class="d-flex flex-row justify-content-between mt-3">
            <a :href="`/community_detail.html/${discount_list[0] ? discount_list[0].id : 0}`">
              <div class="position-relative" style="width: 375px;height: 281px;" v-if="discount_list[0]">
                <img :src="discount_list[0].cover_pic" style="object-fit: cover" class="w-100 h-100">
                <div class="position-absolute w-100 h-100" style="top: 0;z-index: 1;background-color: #1a1a1a66;"></div>
                <span class="position-absolute text-center w-100 font_size3 text-white"
                      style="top: 50%;left: 0;z-index: 2;">{{discount_list[0].name}}</span>
                <span class="font_size2 position-absolute text-white"
                      style="top: 0;left: 0;z-index: 3;background-color: #EE1212;padding: 5px 20px;border-radius: 0 0 10px 0;">{{discount_list[0].average_price ? discount_list[0].average_price : '价格待定'}}</span>
              </div>
            </a>
            <div class="d-flex flex-column align-items-center justify-content-between">
              <a :href="`/community_detail.html/${discount_list[1] ? discount_list[1].id : 0}`">
              <div class="position-relative" style="width: 375px;height: 135px;" v-if="discount_list[1]">
                <img :src="discount_list[1].cover_pic" style="object-fit: cover" class="w-100 h-100">
                <div class="position-absolute w-100 h-100" style="top: 0;z-index: 1;background-color: #1a1a1a66"></div>
                <span class="position-absolute text-center w-100 font_size3 text-white"
                      style="top: 50%;left: 0;z-index: 2;">{{discount_list[1].name}}</span>
                <span class="font_size2 position-absolute text-white"
                      style="top: 0;left: 0;z-index: 3;background-color: #EE1212;padding: 5px 20px;border-radius: 0 0 10px 0;">{{discount_list[1].average_price ? discount_list[1].average_price : '价格待定'}}</span>
              </div>
              </a>
              <a :href="`/community_detail.html/${discount_list[2] ? discount_list[2].id : 0}`">
              <div class="position-relative" style="width: 375px;height: 135px;" v-if="discount_list[2]">
                <img :src="discount_list[2].cover_pic" style="object-fit: cover" class="w-100 h-100">
                <div class="position-absolute w-100 h-100" style="top: 0;z-index: 1;background-color: #1a1a1a66"></div>
                <span class="position-absolute text-center w-100 font_size3 text-white"
                      style="top: 50%;left: 0;z-index: 2;">{{discount_list[2].name}}</span>
                <span class="font_size2 position-absolute text-white"
                      style="top: 0;left: 0;z-index: 3;background-color: #EE1212;padding: 5px 20px;border-radius: 0 0 10px 0;">{{discount_list[2].average_price ? discount_list[2].average_price : '价格待定'}}</span>
              </div>
              </a>
            </div>
            <a :href="`/community_detail.html/${discount_list[3] ? discount_list[3].id : 0}`">
            <div class="position-relative" style="width: 375px;height: 281px;" v-if="discount_list[3]">
              <img :src="discount_list[3].cover_pic" style="object-fit: cover" class="w-100 h-100">
              <div class="position-absolute w-100 h-100" style="top: 0;z-index: 1;background-color: #1a1a1a66"></div>
              <span class="position-absolute text-center w-100 font_size3 text-white"
                    style="top: 50%;left: 0;z-index: 2;">{{discount_list[3].name}}</span>
              <span class="font_size2 position-absolute text-white"
                    style="top: 0;left: 0;z-index: 3;background-color: #EE1212;padding: 5px 20px;border-radius: 0 0 10px 0;">{{discount_list[3].average_price ? discount_list[3].average_price : '价格待定'}}</span>
            </div>
            </a>
          </div>
        </div>
        <div class="flex-column mt-5">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <span class="font_size3" style="color: #FF9326;">精选新房</span>
              <span class="font_size2 mt-3 ms-3 me-3" style="color: #999;">/</span>
              <span class="font_size2 mt-3" style="color: #999;">Selected new houses</span>
            </div>
            <a href="/community_list.html">
            <div class="d-flex flex-row align-items-center mt-3">
              <span class="font_size2 me-2" style="color: #999;">更多</span>
              <img src="../../assets/image/home_icon_xiangyou_default.png">
            </div>
            </a>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-3">
            <div class="d-flex flex-column" style="width: 267px;" v-for="(item,index) in community_list" :key="index">
              <a :href="`/community_detail.html/${item.id}`">
              <img :src="item.cover_pic" class="w-100" style="height: 205px;object-fit: cover;">
              </a>
              <span class="font_size4 text-dark mt-1">{{item.name}}</span>
              <span class="font_size5 mt-1" style="color: #FF9326;">{{item.average_price}}</span>
            </div>
          </div>
        </div>
        <div class="w-100 mt-5">
          <img class="w-100" src="../../assets/image/home_banner_banner_default.jpg">
        </div>
        <div class="flex-column mt-5">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <span class="font_size3" style="color: #FF9326;">二手好房</span>
              <span class="font_size2 mt-3 ms-3 me-3" style="color: #999;">/</span>
              <span class="font_size2 mt-3" style="color: #999;">Second hand house</span>
            </div>
            <a href="/secondhand_list.html">
            <div class="d-flex flex-row align-items-center mt-3">
              <span class="font_size2 me-2" style="color: #999;">更多</span>
              <img src="../../assets/image/home_icon_xiangyou_default.png">
            </div>
            </a>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-3">
            <div class="d-flex flex-column" style="width: 267px;height: 300px;" v-for="(item,index) in secondhand_list"
                 :key="index">
              <a :href="`/secondhand_detail.html/${item.id}`">
              <img :src="item.cover_pic" class="w-100" style="height: 205px;object-fit: cover;">
              </a>
              <span class="font_size5 text-dark mt-2" style="font-weight: bold!important;">{{item.name}}</span>
              <div class="d-flex flex-row align-items-center justify-content-between mt-1">
                <span class="font_size5 mt-1" style="color: #666;">{{item.building_area}} {{item.house_type}}</span>
                <span class="font_size5 mt-1" style="color: #FF9326;">{{item.total_price}}万</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column mt-1">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <span class="font_size3" style="color: #FF9326;">品质租房</span>
              <span class="font_size2 mt-3 ms-3 me-3" style="color: #999;">/</span>
              <span class="font_size2 mt-3" style="color: #999;">Quality rental</span>
            </div>
            <a href="/rent_list.html">
            <div class="d-flex flex-row align-items-center mt-3">
              <span class="font_size2 me-2" style="color: #999;">更多</span>
              <img src="../../assets/image/home_icon_xiangyou_default.png">
            </div>
            </a>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-3">
            <div class="d-flex flex-column" style="width: 267px;height: 300px;" v-for="(item,index) in rent_list"
                 :key="index">
              <a :href="`/rent_detail.html/${item.id}`">
              <img :src="item.cover_pic" class="w-100" style="height: 205px;object-fit: cover;">
              </a>
              <span class="font_size5 text-dark mt-2" style="font-weight: bold!important;">{{item.name}}</span>
              <div class="d-flex flex-row align-items-center justify-content-between mt-1">
                <span class="font_size5 mt-1" style="color: #666;">{{item.house_area}} {{item.house_type_name}}</span>
                <span class="font_size5 mt-1" style="color: #FF9326;">{{item.rental_price}}元/月</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column mt-3">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <span class="font_size3" style="color: #FF9326;">购房流程</span>
              <span class="font_size2 mt-3 ms-3 me-3" style="color: #999;">/</span>
              <span class="font_size2 mt-3" style="color: #999;">For Buyer</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mt-5">
            <div class="d-flex flex-column" style="width: 115px;height: 137px;"
                 :key="index">
              <img src="../../assets/image/home_icon_loupan_default.png" class="w-100 h-100">
            </div>
            <div class="d-flex flex-column" style="width: 115px;height: 137px;"
                 :key="index">
              <img src="../../assets/image/home_icon_baoming_default.png" class="w-100 h-100">
            </div>
            <div class="d-flex flex-column" style="width: 115px;height: 137px;"
                 :key="index">
              <img src="../../assets/image/home_icon_dabache_default.png" class="w-100 h-100">
            </div>
            <div class="d-flex flex-column" style="width: 115px;height: 137px;"
                 :key="index">
              <img src="../../assets/image/home_icon_yaoshi_default.png" class="w-100 h-100">
            </div>
            <div class="d-flex flex-column" style="width: 115px;height: 137px;"
                 :key="index">
              <img src="../../assets/image/home_icon_huoche_default.png" class="w-100 h-100">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 534px;background-color: #F9F9F9;" class="mt-5 d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center" style="width: 1150px;">
        <div class="d-flex flex-column" style="flex: 1">
          <span style="font-size: 34px;color: #333;font-weight: bold;">关于我们</span>
          <span style="font-size: 13px;color: #333;font-weight: bold;">About us</span>
          <span class="mt-3" style="font-size: 15px;color: #333;font-weight: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广西宜豆网络科技有限公司，简称“宜豆科技”宜豆科技前身“南宁中宜房地产投资置业有限公司”公司成立于2014年，致力打造房地产全程综合服务商，
            随着移动互联网应用迅猛发展，移动互联网已经覆盖各个领域，正在深刻改变信息时代的社会生活，中宜于2020年转型移动互联网行业，成立“宜豆科技”中宜在房产领域深耕多年，深知房产中介行业的痛点、压力以及市场环境，竞争愈演愈烈，获客成本越来越高，
            营销工具普遍较为单一，为了让行业从业人员更便捷的拓客，使用更好的营销工具，宜豆科技专为房产中介领域研发了一款营销实用工具，于2021年11月正式发布由宜豆科技研发首款“宜豆找房”房产软件正式推向市场，“宜豆找房”是一款打破传统营销模式的互联网房产软件，
            秉承“不断创造购房新体验、实现互联网交易新方式”的愿景，让创业更简单，让居住更美好，集聚创新的网络营销模式已正式开启。</span>
        </div>
        <img src="../../assets/image/home_img_tupian_default.png" style="height: 534px;width: 608px;">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'
  import GlobalHeader from '@/components/GlobalHeader.vue'
  import { CommunityBuildings } from '@/model/bean'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'Index',
    components: {
      GlobalHeader
    },
    setup() {
      const router = useRouter()
      onMounted(() => {
        let cityCode = localStorage.getItem('cityCode')
        if (!cityCode) {
          cityCode = '450100'
        }
        store.dispatch('fetchIndex', cityCode)
      })
      const store = useStore()
      const discount_list = computed(() => store.state.discount_list)
      const community_list = computed(() => store.state.community_list)
      const secondhand_list = computed(() => store.state.secondhand_list)
      const rent_list = computed(() => store.state.rent_list)

      const currentUser = computed(() => store.state.user)
      const keyword = ref('')
      const sreach_type = ref('10')
      const onSearch = () => {
        // console.log('搜索', keyword.value)
        // console.log('搜索', sreach_type.value)
        switch (sreach_type.value) {
          case '10':
            if (keyword.value.length > 0) {
              router.push({ name: 'community_list', params: { keywords: keyword.value } })
            }
            break
          case '20':
            if (keyword.value.length > 0) {
              router.push({ name: 'secondhand_list', params: { keywords: keyword.value } })
            }
            break
        }
      }
      return {
        currentUser,
        discount_list,
        community_list,
        secondhand_list,
        rent_list,
        sreach_type,
        keyword,
        options: ref([
          {
            value: '10',
            label: '新房'
          },
          {
            value: '20',
            label: '二手房'
          },
          {
            value: '30',
            label: '租房'
          }
        ]),
        onSearch
      }
    }
  })
</script>

<style>
  .ant-input:focus {
    border: none;
    border-bottom: 0px solid white !important;
    box-shadow: none;
  }
  .header {
    background: #000 url(../../assets/image/home_banner_img_default.jpg) no-repeat;
    width: 100%;
    height: 566px;
    padding-top: 0px;
    background-position: center 0;
    display: block;
    position: relative;
  }

  .sreach_bg {
    width: 710px;
    height: 56px;
    background: #00000000 url(../../assets/image/home_search_rb_default.png) no-repeat;
  }

  .font_size1 {
    font-size: 45px;
    font-weight: bolder;
  }

  .font_size2 {
    font-size: 12px;
    font-weight: lighter;
  }

  .font_size3 {
    font-size: 28px;
    font-weight: lighter;
  }

  .font_size4 {
    font-size: 20px;
    font-weight: bold;
  }

  .font_size5 {
    font-size: 15px;
    font-weight: normal;
  }

  .input .el-input__inner {
    background-color: transparent !important;
    border: 0px solid #DCDFE6;
    color: white;
    font-size: 17px;
  }
</style>
