
  import { defineComponent, ref, watch } from 'vue'
  import useClickOutside from '@/hooks/useClickOutside'

  export default defineComponent({
    name: 'Dropdown',
    props: {
      title: {
        type: String,
        required: true
      }
    },
    setup() {
      const isOpen = ref(false)
      // 本节点
      const dropdownRef = ref<null | HTMLElement>(null)
      const toggleOpen = () => {
        isOpen.value = !isOpen.value
      }
      // 点击本节点外关闭本节点
      const isClickOutside = useClickOutside(dropdownRef)
      // 观察者 isClickOutside状态变化时触发
      watch(isClickOutside, () => {
        if (isOpen.value && isClickOutside.value) {
          isOpen.value = false
        }
      })
      return {
        isOpen,
        toggleOpen,
        dropdownRef
      }
    }
  })
