
  import {
    defineComponent,
    computed,
    onMounted,
    ref,
    PropType,
    UnwrapRef,
    reactive,
    toRaw,
    getCurrentInstance,
    watch
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import store from '@/store'
  interface FormState {
    page?: number, // 分页
    city_id?: number|string|null, // 城市id
    area_id?: string, // 区域id
    area_index: number, // 区域序号
    name?: string, // 关键字
    house_type?: number, // 户型
    house_type_index?: number, // 户型序号
    floor_type?: number, // 房屋类型
    floor_type_index?: number, // 房屋类型序号
    average_price_min?: string, // 均价下限
    average_price_max?: string, // 均价上限
    average_price_index?: number, // 均价序号
    order?: number, // 价格排序
    decorate?: number // 装修排序
    nextpage?: boolean // 下一页
  }
  export default defineComponent({
    name: 'CommunityList',
    setup() {
      const route = useRoute()
      // console.log('params', route.params)
      onMounted(() => {
        // 读取城市区域信息
        store.dispatch('getAreaList', city_id)
        // 读取新房列表
        store.dispatch('getCommunityBuildingList', toRaw(formState))
        // 读取特惠房源
        store.dispatch('getLimitCommunityList', city_id)
      })
      const city_id:string|null = localStorage.getItem('cityCode')
      const formState: UnwrapRef<FormState> = reactive({
        page: 1,
        city_id: city_id,
        area_id: '',
        area_index: 0,
        name: '',
        house_type: 0,
        house_type_index: 0,
        floor_type: 0,
        floor_type_index: 0,
        average_price_min: '',
        average_price_max: '',
        average_price_index: 0,
        order: 0,
        decorate: 0,
        nextpage: true
      })
      if (route.params) {
        const keywords:any = route.params.keywords
        // console.log('keywords', keywords)
        formState.name = keywords || ''
      }
      // 区域
      const options_area = computed(() => store.state.regions)
      const select_area = (index:number) => {
        formState.area_index = index
      }
      // 均价
      const options_average = [
        { label: '不限', value: 0 },
        { label: '1万以下', value: 1 },
        { label: '1万-1.5万', value: 2 },
        { label: '1.5万-2万', value: 3 },
        { label: '2万-5万', value: 4 },
        { label: '5万以上', value: 5 }
      ]
      // 类型
      const options_floor_type = [
        { label: '不限', value: 0 },
        { label: '住宅', value: 1 },
        { label: '商铺', value: 2 },
        { label: '公寓', value: 3 },
        { label: '别墅', value: 4 },
        { label: '写字楼', value: 5 },
        { label: '其他', value: 6 },
        { label: '洋房', value: 7 }
      ]
      // 户型
      const options_house_type = [
        { label: '不限', value: 0 },
        { label: '一房', value: 1 },
        { label: '二房', value: 2 },
        { label: '三房', value: 3 },
        { label: '四房', value: 4 },
        { label: '其他', value: 5 }
      ]
      // 价格排序
      const options_order = [
        { label: '默认', value: 0 },
        { label: '价格从低到高', value: 1 },
        { label: '价格从高到低', value: 2 }
      ]
      // 装修情况
      const options_decorate = [
        { label: '不限', value: 0 },
        { label: '毛坯', value: 1 },
        { label: '简装', value: 2 },
        { label: '精装', value: 3 }
      ]
      const list = computed(() => store.state.community_list)
      const goBack = () => {
        if (formState.page) {
          formState.page = formState.page - 1
          if (formState.page < 1) {
            formState.page = 1
          }
        }
        store.dispatch('getCommunityBuildingList', toRaw(formState))
      }
      const goNext = () => {
        if (formState.page) {
          if (formState.nextpage) {
            formState.page = formState.page + 1
          }
        }
        store.dispatch('getCommunityBuildingList', toRaw(formState))
      }
      watch(list, () => {
        if (list.value.length === 10) {
          formState.nextpage = true
        } else {
          formState.nextpage = false
        }
      })
      const onSreach = (searchValue: string) => {
        // console.log('搜索', searchValue)
        formState.area_id = options_area.value[formState.area_index].code
        switch (formState.average_price_index) {
          case 0:
            formState.average_price_min = ''
            formState.average_price_max = ''
            break
          case 1:
            formState.average_price_min = '0'
            formState.average_price_max = '10000'
            break
          case 2:
            formState.average_price_min = '10000'
            formState.average_price_max = '15000'
            break
          case 3:
            formState.average_price_min = '15000'
            formState.average_price_max = '20000'
            break
          case 4:
            formState.average_price_min = '20000'
            formState.average_price_max = '50000'
            break
          case 5:
            formState.average_price_min = '50000'
            formState.average_price_max = ''
            break
        }
        formState.floor_type = formState.floor_type_index
        formState.house_type = formState.house_type_index
        store.dispatch('getCommunityBuildingList', toRaw(formState))
      }
      const discount_list = computed(() => store.state.discount_list)
      return {
        formState,
        options_area,
        select_area,
        options_average,
        options_floor_type,
        options_house_type,
        options_order,
        options_decorate,
        list,
        discount_list,
        goBack,
        goNext,
        onSreach
      }
    }
  })
