import { ref, onMounted, onUnmounted, Ref } from 'vue'

// 点击本节点外的判断
const useClickOutside = (elementRef: Ref<null | HTMLElement>) => {
  const isClickOutside = ref(false)
  // 点击事件
  const handler = (e: MouseEvent) => {
    if (elementRef.value) {
      // 判断是否点击本节点
      if (elementRef.value.contains(e.target as HTMLElement)) {
        isClickOutside.value = false
      } else {
        isClickOutside.value = true
      }
    }
  }
  onMounted(() => {
    // 绑定点击屏幕事件
    document.addEventListener('click', handler)
  })
  onUnmounted(() => {
    // 释放点击屏幕事件
    document.removeEventListener('click', handler)
  })
  return isClickOutside
}

export default useClickOutside
