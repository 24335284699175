import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import ColumnDetail from './views/ColumnDetail.vue'
import store from './store'
import App from './App.vue'
import CreatePost from './views/CreatePost.vue'
import axios from 'axios'

import ElementPlus from 'element-plus'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/font.css'
import Index from './views/index/Index.vue'
import SelectCity from './views/index/SelectCity.vue'
import CommunityList from './views/index/CommunityList.vue'
import CommunityDetail from './views/index/CommunityDetail.vue'
import SecondHandList from './views/index/SecondHandList.vue'
import SecondHandDetail from './views/index/SecondHandDetail.vue'
import RentList from './views/index/RentList.vue'
import RentDetail from './views/index/RentDetail.vue'
import Center from './views/index/Center.vue'
import SencondHandAdd from './views/mine/SencondHandAdd.vue'
import SencondHandEdit from './views/mine/SencondHandEdit.vue'
import RentAdd from './views/mine/RentAdd.vue'
import RentEdit from './views/mine/RentEdit.vue'
import HousePublish from './views/mine/HousePublish.vue'
import About from './views/index/About.vue'
import qs from 'qs'
import createMessage from '@/hooks/createMessage'

// 创建路由
// history：采用哪种路由 ,
// createWebHistory: 一种路由方式
// routes: 路由信息
const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      meta: {
        title: '宜豆找房',
        navShow: false
      }
    },
    {
      path: '/select_city.html',
      name: 'select_city',
      component: SelectCity,
      meta: {
        title: '选择所在城市',
        navShow: true
      }
    },
    {
      path: '/community_list.html',
      name: 'community_list',
      component: CommunityList,
      meta: {
        title: '新房',
        navShow: true
      }
    },
    {
      path: '/community_detail.html/:id',
      name: 'community_detail',
      component: CommunityDetail,
      meta: {
        title: '新房详情',
        navShow: true
      }
    },
    {
      path: '/secondhand_list.html',
      name: 'secondhand_list',
      component: SecondHandList,
      meta: {
        title: '二手房',
        navShow: true
      }
    },
    {
      path: '/secondhand_detail.html/:id',
      name: 'secondhand_detail',
      component: SecondHandDetail,
      meta: {
        title: '二手房详情',
        navShow: true
      }
    },
    {
      path: '/rent_list.html',
      name: 'rent_list',
      component: RentList,
      meta: {
        title: '租房',
        navShow: true
      }
    },
    {
      path: '/rent_detail.html/:id',
      name: 'rent_detail',
      component: RentDetail,
      meta: {
        title: '租房详情',
        navShow: true
      }
    },
    {
      path: '/login.html',
      name: 'login',
      component: Login,
      meta: {
        redirctAlreadyLogin: true,
        title: '登录',
        navShow: true
      }
    },
    {
      path: '/center.html',
      name: 'center',
      component: Center,
      meta: {
        requiredLogin: true,
        title: '个人中心',
        navShow: true
      }
    },
    {
      path: '/house_publish.html',
      name: 'house_publish',
      component: HousePublish,
      meta: {
        requiredLogin: true,
        title: '房源管理',
        navShow: true
      }
    },
    {
      path: '/sencond_hand_add.html',
      name: 'sencond_hand_add',
      component: SencondHandAdd,
      meta: {
        requiredLogin: true,
        title: '我要卖房',
        navShow: true
      }
    },
    {
      path: '/sencond_hand_edit.html/:id',
      name: 'sencond_hand_edit',
      component: SencondHandEdit,
      meta: {
        requiredLogin: true,
        title: '二手房房源编辑',
        navShow: true
      }
    },
    {
      path: '/rent_add.html',
      name: 'rent_add',
      component: RentAdd,
      meta: {
        requiredLogin: true,
        title: '我要出租',
        navShow: true
      }
    },
    {
      path: '/rent_edit.html/:id',
      name: 'rent_edit',
      component: RentEdit,
      meta: {
        requiredLogin: true,
        title: '出租房源编辑',
        navShow: true
      }
    },
    {
      path: '/home.html',
      name: 'home',
      component: Home,
      meta: {
        title: '宜豆找房'
      }
    },
    {
      path: '/column.html/:id',
      name: 'column',
      component: ColumnDetail
    },
    {
      path: '/create.html',
      name: 'create',
      component: CreatePost,
      meta: {
        requiredLogin: true
      }
    },
    {
      path: '/about.html',
      name: 'about',
      component: About,
      meta: {
        title: '关于我们',
        navShow: true
      }
    }
  ]
})
// to 要到达的路由 from 来自路由 next 方法，调用参数继续跳转
router.beforeEach((to, from, next) => {
  // console.log('to:', to.path)
  // console.log('from', from)
  // console.log(to.meta)
  if (to.meta.title) {
    // 如果设置标题，拦截后设置标题
    // console.log(to.meta.title)
    const title = '' + to.meta.title
    document.title = title
  }

  if (to.meta.requiredLogin && !store.state.user.isLogin) {
    next({ name: 'login' })
  } else if (to.meta.redirctAlreadyLogin && store.state.user.isLogin) {
    next({ name: 'index' })
  } else {
    next()
  }
})

axios.defaults.baseURL = 'https://app.yidouzhaofang.com/api/'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use(config => {
  config.headers.client = 'pc'
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token')
  }
  // console.log('request', config)
  // store.commit('setLoading', true)
  store.commit('setError', { status: false, message: '' })
  if (config.method === 'post') {
    // console.log('request_s', config.data)
    // config.data = qs.stringify(config.data)
  }
  return config
})
axios.interceptors.response.use(config => {
  // console.log('response_s', config) // post测试
  if (config.data.code !== 200) {
    if (config.data.code === 403) {
      // 登录过期
      store.commit('outLogin', () => {
        // console.log('退出登录')
        setTimeout(() => {
          router.push('/login.html')
        }, 1000)
      })
    }
    const { msg } = config.data
    store.commit('setError', { status: true, message: msg })
    // store.commit('setLoading', false)
    return Promise.reject(msg)
  }
  store.commit('setError', { status: false, message: '' })
  setTimeout(() => {
    // store.commit('setLoading', false)
  }, 1000)
  return config
}, e => {
  // console.log('response_e', e.response)
  const { message } = e.response.data
  store.commit('setError', { status: true, message: message })
  // store.commit('setLoading', false)
  return Promise.reject(message)
})
// const param = new URLSearchParams()
// param.append('city_id', '451300')
// axios.post('community/getCommunityBuildingList', param).then(resp => {
//   console.log('community/getCommunityBuildingList', resp)
// })
// axios.post('home/index_3_2_0').then(res => {
//   console.log('home/index_3_2_0', res)
// })

// async function hello() {
//   const greeting = await Promise.resolve('hello')
//   return greeting
// }
// hello().then(value => {
//   console.log(value)
// })

// 保持登录
store.commit('saveLogin')

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(Antd)
app.mount('#app')
