<template>
  <div class="container-fluid bg_login">
    <div class="d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center justify-content-end" style="width: 1150px;margin-top: 120px;">
        <div class="bg-white d-flex flex-column align-items-center justify-content-center" style="width: 365px;height: 384px;border-radius: 10px;">
          <div class="w-100" style="padding: 0 20px;">
            <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-2">
              <span class="text-dark" style="font-size: 19px;font-weight: bold;">用户登录</span>
              <span class="text-dark" style="font-size: 19px;font-weight: bold;">注册</span>
            </div>
            <span class="mt-4" style="font-size: 13px;font-weight: bold;color: #999;">别担心，注册或登录不会导致手机号被泄露</span>
            <validate-form @form-submit="onFormSubmit">
              <div class="mb-2 mt-4">
                <validate-input
                  style="font-size: 13px;"
                  :rules="emailRules"
                  placeholder="请输入手机号"
                  type="text"
                  v-model="emailValue"
                  ref="inputRef"></validate-input>
              </div>
              <div class="mb-4">
                <validate-input
                  style="font-size: 13px;"
                  :rules="passwordRules"
                  placeholder="请输入密码"
                  type="password"
                  v-model="passwordValue"></validate-input>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-3">
                <div class="d-flex flex-row align-items-center">
                  <img class="me-2" src="../assets/image/denglu_icon_tongyi_default.png">
                  <span style="font-size: 13px;font-weight: bold;color: #999;">7天内免登录</span>
                </div>
                <span style="font-size: 13px;font-weight: bold;color: #999;">忘记密码</span>
              </div>
              <template #sumit>
                <span class="btn btn-primary w-100" style="background-color: #FF9326;border-color: #FF9326;">提交</span>
              </template>
            </validate-form>
            <div class="d-flex flex-row align-items-center w-100 mt-3">
              <span style="font-size: 11px;font-weight: bold;color: #999;">登录即代表同意</span>
              <span style="font-size: 11px;font-weight: bold;color: #F08200;">《用户协议》</span>
              <span style="font-size: 11px;font-weight: bold;color: #999;">以及</span>
              <span style="font-size: 11px;font-weight: bold;color: #F08200;">《隐私政策》</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import ValidateInput, { RulesProp } from '@/components/ValidateInput.vue'
  import ValidateForm from '@/components/ValidateForm.vue'
  import createMessage from '@/hooks/createMessage'

  const emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/

  export default defineComponent({
    name: 'App',
    components: {
      ValidateInput,
      ValidateForm
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const inputRef = ref<any>()
      const emailValue = ref('')
      const passwordValue = ref('')
      const emailRules: RulesProp = [
        { type: 'required', message: '手机号不能为空' },
        { type: 'phone', message: '手机号格式不正确' }
      ]
      const passwordRules: RulesProp = [
        { type: 'required', message: '密码不能为空' }
      ]
      const emailRef = reactive({
        val: '',
        error: false,
        message: ''
      })
      const validateEmail = () => {
        if (emailRef.val.trim() === '') {
          emailRef.error = true
          emailRef.message = '邮箱不能为空'
        } else if (!emailReg.test(emailRef.val)) {
          emailRef.error = true
          emailRef.message = '邮箱格式非法'
        }
      }
      const onFormSubmit = (result: boolean) => {
        // console.log('onFormSubmit', result)
        if (result) {
          const payload = {
            email: emailValue.value,
            password: passwordValue.value
          }
          store.dispatch('loginAndFetch', payload).then(data => {
            // console.log('login', data)
            createMessage('登录成功,2秒后跳转首页', 'success')
            setTimeout(() => {
              // 跳转页面
              // router.push({ name: 'column', params: { id: 1 } })
              router.push('/center.html')
            }, 500)
          }).catch(e => {
            // console.log('onFormSubmit', e)
          })
        }
      }
      return {
        emailRef,
        validateEmail,
        emailRules,
        emailValue,
        passwordRules,
        passwordValue,
        onFormSubmit,
        inputRef
      }
    }
  })
</script>

<style>
  .bg_login {
    padding-right: 0px;
    padding-left: 0px;
    background: #000 url(../assets/image/denglu_img_img_default.jpg) no-repeat;
    width: 100%;
    height: 880px;
    padding-top: 0px;
    background-position: center 0;
    display: block;
    position: relative;
  }
</style>
