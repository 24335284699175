
  import { defineComponent, ref, PropType } from 'vue'
  import axios from 'axios'
  import createMessage from '@/hooks/createMessage'

  type UploadStatus = 'ready' | 'loading' | 'success' | 'error'
  type CheckFunction = (file: File) => boolean;
  type ReturnFunction = (img: string) => null;

  export default defineComponent({
    name: 'Uploader',
    props: {
      action: {
        type: String,
        required: true
      },
      beforeUpload: {
        type: Function as PropType<CheckFunction>
      },
      finishUpload: {
        type: Function as PropType<ReturnFunction>
      }
    },
    methods: {
    },
    setup(props) {
      const fileInput = ref<null | HTMLInputElement>(null)
      const fileStatus = ref<UploadStatus>('ready')
      const triggerUpload = () => {
        if (fileInput.value) {
          fileInput.value.click()
        }
      }
      const handleFileChange = (e: Event) => {
        // console.log('handleFileChange')
        const currentTarget = e.target as HTMLInputElement
        if (currentTarget.files) {
          const files = Array.from(currentTarget.files)
          // console.log('files', files)
          if (props.beforeUpload) {
            const result = props.beforeUpload(files[0])
            if (!result) {
              return
            }
          }
          fileStatus.value = 'loading'
          axios.post('common/getOssUpdateInfo', {}, {}).then(resp => {
            // console.log('data', resp.data.data)
            const formData = new FormData()
            // formData.append('Content-Length', content_len)
            formData.append('OSSAccessKeyId', resp.data.data.OSSAccessKeyId)
            formData.append('policy', resp.data.data.policy)
            formData.append('signature', resp.data.data.signature)
            // 文件名字
            formData.append('key', resp.data.data.key)
            // formData.append('dir', data.path)
            formData.append('success_action_status', '200')
            // 文件必须声明在最后，否则异常
            formData.append('file', files[0])
            axios.post(resp.data.data.url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).catch(() => {
              // 无法判断是否上传成功，默认都成功
              // console.log('img', resp.data.data.url + '/' + resp.data.data.key)
              if (props.finishUpload) {
                props.finishUpload(resp.data.data.url + '/' + resp.data.data.key)
              }
            })
          })
        }
      }
      return {
        triggerUpload,
        fileInput,
        handleFileChange
      }
    }
  })
