
  import { defineComponent, ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  import { GlobalDataProps } from '../store'
  import { PostProps } from '../testData'
  import ValidateInput, { RulesProp } from '../components/ValidateInput.vue'
  import ValidateForm from '../components/ValidateForm.vue'
  import axios from 'axios'

  export default defineComponent({
    name: 'Login',
    components: {
      ValidateInput,
      ValidateForm
    },
    setup() {
      const uploadedData = ref()
      const store = useStore<GlobalDataProps>()
      const titleVal = ref('')
      const router = useRouter()
      const route = useRoute()
      const titleRules: RulesProp = [
        { type: 'required', message: '文章标题不能为空' }
      ]
      const contentVal = ref('')
      const contentRules: RulesProp = [
        { type: 'required', message: '文章详情不能为空' }
      ]
      const onFormSubmit = (result: boolean) => {
        if (result) {
          const { columnId } = store.state.user
          if (columnId) {
            const newPost: PostProps = {
              id: new Date().getTime(),
              title: titleVal.value,
              columnId: columnId,
              content: contentVal.value,
              createdAt: new Date().toLocaleString()
            }
            store.commit('createPost', newPost)
            router.push({ name: 'column', params: { id: columnId } })
          }
        }
      }
      const handleFileChange = (e: Event) => {
        const target = e.target as HTMLInputElement
        const files = target.files
        if (files) {
          const uploadedFile = files[0]
          const formData = new FormData()
          formData.append(uploadedFile.name, uploadedFile)
          axios.post('/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
      }
      return {
        titleRules,
        titleVal,
        contentVal,
        contentRules,
        onFormSubmit,
        uploadedData
      }
    }
  })
