
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'

  export default {
    name: 'center',
    setup() {
      onMounted(() => {
        store.dispatch('userGetUserInfo')
      })
      const store = useStore()
      const currentUser = computed(() => store.state.user)
      let role = '宜豆会员'
      switch (currentUser.value.user_role) {
        case 1:
          role = '宜豆会员'
          break
        case 2:
          role = '经纪人'
          break
        case 3:
          role = '新房总监'
          break
        case 4:
          role = '项目经理'
          break
        case 5:
          role = '置业顾问'
          break
        case 6:
          role = '商家'
          break
      }
      return {
        currentUser,
        role
      }
    }
  }
