
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import createMessage from '@/hooks/createMessage'
  import { Moment } from 'moment'
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
  import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
  import {
    houseTypeData,
    floorTypeStrData,
    selectDecoration2,
    selectPropertyRight,
    selectOrientation,
    selectElevator,
    payTypeData,
    rentTypeData,
    rentUsedData,
    selectHouseUse, SelectData, RentUsedData
  } from '@/testData'
  import Uploader from '@/components/Uploader.vue'
  import axios from 'axios'
  import { useRouter } from 'vue-router'

  export const imgsData:string[] = []
  export interface FormState {
    title?: string; // 标题
    community_id?: string; // 小区名称
    building_area?: string; // 面积
    house_type_name?: string; // 户型
    property_type?: string; // 房屋类型
    renovation_str?: string; // 装修
    orientation?: string; // 朝向
    elevator?: string; // 电梯
    rent_type?: string; // 租赁方式
    pay_type?: string; // 付费方式
    floor?: string; // 楼层
    total_floor?: string; // 总层
    rental_price?: string; // 月租金
    settlement_str?: string; // 入住时间
    deposit_str?: string; // 押金方式
    parking_str?: string; // 车位情况
    is_free_intermediary?: boolean|string; // 是否免佣
    service_str?: string; // 服务费
    intermediary_str?: string; // 中介费
    pic1?: string; // 室内图
    tags?: string; // 配套设施
  }
  interface imgState {
    imgs?: string[];
  }

  let timeout: any
  let currentValue = ''
  function fetch(value: string, callback: any) {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value

    function fake() {
      let cityCode = localStorage.getItem('cityCode')
      if (!cityCode) {
        cityCode = '450100'
      }
      const param = new URLSearchParams()
      param.append('city_id', cityCode)
      param.append('name', value)
      axios.post('community/searchCommunityList', param, {}).then(resp => {
        // console.log('community/searchCommunityList', resp.data.data)
        const result = resp.data.data
        const data: any[] = []
        result.forEach((r: any) => {
          data.push({
            value: r.id,
            label: r.name
          })
        })
        callback(data)
      })
    }
    timeout = setTimeout(fake, 300)
  }
  export default defineComponent({
    name: 'RentAdd',
    components: {
      Uploader
    },
    data() {
      return {
        imgs: imgsData, // 室内图
        rentUsedData: rentUsedData // 配套设施
      }
    },
    methods: {
      change() {
        this.$forceUpdate()
      },
      goBack() {
        this.$router.go(-1)
      },
      finishUpload (img: string) {
        // console.log('finishUpload', img)
        this.imgs.push(img)
      },
      del(index:number) {
        // console.log('删除', index)
        this.imgs.splice(index, 1)
      },
      click_used(index:number) {
        if (this.rentUsedData[index].show) {
          this.rentUsedData[index].show = false
        } else {
          this.rentUsedData[index].show = true
        }
      }
    },
    created(): void {
      this.imgs = []
    },
    setup(props) {
      const router = useRouter()
      const datab = getCurrentInstance()
      const formRef = ref()
      const formState: UnwrapRef<FormState> = reactive({
        title: '', // 标题
        community_id: '', // 小区id
        building_area: '', // 面积
        house_type_name: '', // 户型
        property_type: '', // 房屋类型
        renovation_str: '', // 装修
        orientation: '', // 朝向
        elevator: '', // 电梯
        rent_type: '', // 租赁方式
        pay_type: '', // 付费方式
        floor: '', // 楼层
        total_floor: '', // 总层
        rental_price: '', // 月租金
        settlement_str: '', // 入住时间
        deposit_str: '', // 押金方式
        parking_str: '', // 车位情况
        is_free_intermediary: true, // 是否免佣
        service_str: '', // 服务费
        intermediary_str: '', // 中介费
        pic1: '', // 室内图
        tags: '' // 配套设置
      })
      const checkNumber = async (rule: RuleObject, value: number) => {
        if (!value) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('请输入内容')
        } else {
          return Promise.resolve()
        }
      }
      const rules = {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 8, max: 50, message: '必须输入8-50个字符的标题信息', trigger: 'blur' }
        ],
        community_id: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        building_area: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        house_type_name: [
          { required: true, message: '请选择户型', trigger: 'change' }
        ],
        property_type: [
          { required: true, message: '请选择房屋类型', trigger: 'change' }
        ],
        renovation_str: [
          { required: true, message: '请选择装修情况', trigger: 'change' }
        ],
        orientation: [
          { required: true, message: '请选择房屋朝向', trigger: 'change' }
        ],
        elevator: [
          { required: true, message: '请选择电梯情况', trigger: 'change' }
        ],
        rent_type: [
          { required: true, message: '请选择租赁方式', trigger: 'change' }
        ],
        pay_type: [
          { required: true, message: '请选择付费方式', trigger: 'change' }
        ],
        floor: [
          { required: true, message: '请输入楼层', trigger: 'blur' }
        ],
        total_floor: [
          { required: true, message: '请输入总层', trigger: 'blur' }
        ],
        rental_price: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        settlement_str: [
          { required: true, message: '请输入入住时间', trigger: 'blur' }
        ],
        deposit_str: [
          { required: true, message: '请输入押金方式', trigger: 'blur' }
        ],
        parking_str: [
          { required: true, message: '请输入车位情况', trigger: 'blur' }
        ]
      }
      // 提交
      const onSubmit = () => {
        // 是否免租
        formState.is_free_intermediary = formState.is_free_intermediary ? '1' : '0'
        // 配套设施
        var tags = ''
        rentUsedData.forEach((item:any) => {
          if (item.show) {
            tags += item.name + ','
          }
        })
        if (tags.length > 0) {
          tags = tags.substr(0, tags.length - 1)
        }
        formState.tags = tags
        // 室内图
        let imgstr = ''
        const imglist:any = datab?.data.imgs
        imglist.forEach((item:string) => {
          imgstr += item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '') + ','
        })
        if (imgstr.length > 0) {
          imgstr = imgstr.substr(0, imgstr.length - 1)
        }
        // console.log('室内图', imgstr)
        formState.pic1 = imgstr
        // console.log('values', formState, toRaw(formState))
        // 表单验证
        // console.log('submit', formState)
        formRef.value.validate().then(() => {
          if (imglist.length < 3) {
            createMessage('房屋图片至少上传三张', 'error')
            return false
          }
          // 提交表单
          store.dispatch('rentHouse', toRaw(formState)).then(data => {
            // console.log('login', data)
            createMessage('提交成功', 'success')
            setTimeout(() => {
              // 跳转页面
              router.push('/center.html')
            }, 500)
          }).catch(e => {
            // console.log('onFormSubmit', e)
          })
        }).catch((error: ValidateErrorEntity<FormState>) => {
          console.log('error', error)
          createMessage('请按照要求填写完整的表单信息', 'error')
        })
      }
      // 选择小区
      const data = ref<any[]>([])
      const handleSearch = (val: string) => {
        // console.log('handleSearch', val)
        fetch(val, (d: any[]) => (data.value = d))
      }
      const handleChange = (val: string) => {
        // console.log('handleChange', val)
        formState.community_id = val
        fetch(val, (d: any[]) => (data.value = d))
      }
      // 上传图片 - 室内图
      const beforeUpload = (file: File) => {
        // console.log('beforeUpload')
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJPG) {
          createMessage('上传图片只能是JPG或PNG格式！', 'error')
        }
        return isJPG
      }
      return {
        labelCol: { span: 2 },
        rules,
        formRef,
        form: formState,
        onSubmit,
        data,
        handleSearch,
        handleChange,
        houseTypeData: houseTypeData,
        floorTypeStrData: floorTypeStrData,
        selectDecoration: selectDecoration2,
        selectOrientation: selectOrientation,
        selectElevator: selectElevator,
        rentTypeData: rentTypeData,
        payTypeData: payTypeData,
        beforeUpload
      }
    }
  })
