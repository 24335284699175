<template>
  <div class="row">
    <div class="col-4 mb-4" v-for="column in columnList" :key="column.id">
      <div class="card h-100 shadow-sm">
        <div class="card-body flex-column text-center">
          <img :src="column && column.cover_pic" class="rounded-circle border border-light my-3"
               :alt="column.title">
          <h5 class="card-title">{{column.name}}</h5>
          <p class="card-text text-left">{{column.address}}</p>
          <!--          <router-link :to="{name:'column',params:{id:column.id}}" class="btn btn-outline-primary">进入专栏</router-link>-->
          <router-link :to="`/column.html/${column.id}`" class="btn btn-outline-primary">查看详情</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed } from 'vue'
  import { ColumnProps } from '../store'

  export default defineComponent({
    name: 'ColumnList',
    components: {},
    props: {
      list: {
        type: Array as PropType<ColumnProps[]>, // 断言，可以通过.来读取属性提示
        required: true
      }
    },
    setup(props) {
      const columnList = computed(() => {
        // console.log(props.list)
        return props.list.map(column => {
          // console.log(column.avatar)
          if (!column.cover_pic) {
            column.cover_pic = require('@/assets/default_img.png')
          } else {
            // oss 的缩放功能
            column.cover_pic = column.cover_pic + '?x-oss-process=image/resize,m_pad,h_50,w_50'
          }
          return column
        })
      })
      return {
        columnList
      }
    }
  })
</script>

<style scoped>
  .card-body img{
    width: 50px;
    height: 50px;
  }
</style>
