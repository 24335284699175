<template>
  <div class="post-list">
    <article v-for="post in posts" :key="post._id" class="card mb-3 shadow-sm">
      <div class="card-body">
        <h4>
          <router-link :to="`/posts/${post._id}/`">{{post.name}}</router-link>
        </h4>
        <div class="row my-3 align-items-center">
          <div v-if="post.cover_pic" class="col-4">
            <img :src="post.cover_pic" :alt="post.name" class="rounded-lg w-100">
          </div>
          <p :class="{'col-8': post.cover_pic}" class="text-muted">{{post.area_str}}</p>
        </div>
        <span class="text-muted">{{post.now_price}} 元/平</span>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed } from 'vue'
  import { PostProps } from '../store'

  export default defineComponent({
    props: {
      list: {
        required: true,
        type: Array as PropType<PostProps[]>
      }
    },
    setup(props) {
      const posts = computed(() => {
        return props.list.map(post => {
          return post
        })
      })
      return {
        posts
      }
    }
  })
</script>

<style scoped>
  .post-list h4 a {
    text-decoration: none;
    color: #1a1a1a;
  }

  .post-list h4 a:hover {
    color: #0d6efd;
  }
</style>
