
  import { defineComponent, onUnmounted } from 'vue'
  import useDOWCreate from '@/hooks/useDOWCreate'

  export default defineComponent({
    name: 'Loader',
    props: {
      text: {
        type: String
      },
      backgroud: {
        type: String
      }
    },
    setup() {
      // 在页面中加入节点实现瞬间移动
      useDOWCreate('back')
    }
  })
