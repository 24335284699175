<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #F6F6F6;">
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;margin-top: 80px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex flex-row align-items-center" style="margin:130px 50px 10px 50px;">
          <span style="font-size: 24px;color: #1a1a1a;font-weight: bold;">房产服务</span>
        </div>
        <div class="d-flex flex-row align-items-center flex-wrap mt-3" style="margin: 20px 50px 50px 50px;">
          <router-link to="/sencond_hand_add.html" class="d-flex flex-column align-items-center justify-content-center me-5">
            <img src="../../assets/image/jingjirengerenzhongxin_icon_maifang_default.png"
                 style="width: 38px;height: 38px;">
            <span style="font-size: 15px;color: #333;font-weight: bold;margin-top: 10px;">我要卖房</span>
          </router-link>
          <router-link to="/rent_add.html" class="d-flex flex-column align-items-center justify-content-center me-5">
            <img src="../../assets/image/jingjirengerenzhongxin_icon_zufang_default.png"
                 style="width: 38px;height: 38px;">
            <span style="font-size: 15px;color: #333;font-weight: bold;margin-top: 10px;">我要出租</span>
          </router-link>
        </div>
      </div>
      <div class="d-flex flex-row align-items-end justify-content-between position-absolute"
           style="top: -40px;left: 50px;right: 0px;">
        <div class="d-flex flex-row align-items-end" style="flex: 1;">
          <img class="" style="width: 130px;height: 130px;border: 5px solid #ffffff;" v-if="currentUser.avatar"
               :src="currentUser.avatar">
          <img class="" style="width: 130px;height: 130px;border: 5px solid #ffffff;" v-if="!currentUser.avatar"
               src="../../assets/default_img.png">
          <div class="d-flex flex-column ms-3">
            <div class="d-flex flex-row align-items-end">
              <span style="font-size: 24px;font-weight: bold;" class="text-dark me-2">{{currentUser.nick_name ? currentUser.nick_name : '匿名用户'}}</span>
              <span
                style="font-size: 11px;font-weight: bold;background-color: #FF9326;padding: 0px 10px;border-radius: 3px;"
                class="text-white mb-1">{{role}}</span>
            </div>
            <div class="d-flex flex-row mt-2">
              <span style="font-size: 14px;font-weight: bold;color: #999;">{{currentUser.mobile}}</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center me-5">
          <router-link to="/house_publish.html" class="d-flex flex-column align-items-center justify-content-center">
            <span style="font-size: 28px;color: #1a1a1a;font-weight: bold;">{{currentUser.releaseCount}}</span>
            <span style="font-size: 16px;color: #1a1a1a;font-weight: normal;">房源管理</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'

  export default {
    name: 'center',
    setup() {
      onMounted(() => {
        store.dispatch('userGetUserInfo')
      })
      const store = useStore()
      const currentUser = computed(() => store.state.user)
      let role = '宜豆会员'
      switch (currentUser.value.user_role) {
        case 1:
          role = '宜豆会员'
          break
        case 2:
          role = '经纪人'
          break
        case 3:
          role = '新房总监'
          break
        case 4:
          role = '项目经理'
          break
        case 5:
          role = '置业顾问'
          break
        case 6:
          role = '商家'
          break
      }
      return {
        currentUser,
        role
      }
    }
  }
</script>

<style scoped>

</style>
