
  import { defineComponent, onUnmounted } from 'vue'
  import mitt from 'mitt'

  type ValidateFunc = () => boolean

  export const emitter = mitt()

  export default defineComponent({
    name: 'ValidateForm',
    emits: ['form-submit'],
    setup(props, context) {
      let funcArr: ValidateFunc[] = []
      const sumbitForm = () => {
        const result = funcArr.map(func => func()).every(result => result)
        context.emit('form-submit', result)
      }
      const callback = (func: any) => {
        funcArr.push(func)
      }
      emitter.on('form-item-created', callback)
      onUnmounted(() => {
        emitter.off('form-item-created', callback)
        funcArr = []
      })
      return {
        sumbitForm
      }
    }
  })
