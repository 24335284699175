
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DropdownItem',
    props: {
      disabled: {
        type: Boolean,
        requried: true
      }
    }
  })
