
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'
  import GlobalHeader from '@/components/GlobalHeader.vue'
  import { CommunityBuildings } from '@/model/bean'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'Index',
    components: {
      GlobalHeader
    },
    setup() {
      const router = useRouter()
      onMounted(() => {
        let cityCode = localStorage.getItem('cityCode')
        if (!cityCode) {
          cityCode = '450100'
        }
        store.dispatch('fetchIndex', cityCode)
      })
      const store = useStore()
      const discount_list = computed(() => store.state.discount_list)
      const community_list = computed(() => store.state.community_list)
      const secondhand_list = computed(() => store.state.secondhand_list)
      const rent_list = computed(() => store.state.rent_list)

      const currentUser = computed(() => store.state.user)
      const keyword = ref('')
      const sreach_type = ref('10')
      const onSearch = () => {
        // console.log('搜索', keyword.value)
        // console.log('搜索', sreach_type.value)
        switch (sreach_type.value) {
          case '10':
            if (keyword.value.length > 0) {
              router.push({ name: 'community_list', params: { keywords: keyword.value } })
            }
            break
          case '20':
            if (keyword.value.length > 0) {
              router.push({ name: 'secondhand_list', params: { keywords: keyword.value } })
            }
            break
        }
      }
      return {
        currentUser,
        discount_list,
        community_list,
        secondhand_list,
        rent_list,
        sreach_type,
        keyword,
        options: ref([
          {
            value: '10',
            label: '新房'
          },
          {
            value: '20',
            label: '二手房'
          },
          {
            value: '30',
            label: '租房'
          }
        ]),
        onSearch
      }
    }
  })
