
  import { defineComponent, PropType, computed } from 'vue'
  import { ColumnProps } from '../store'

  export default defineComponent({
    name: 'ColumnList',
    components: {},
    props: {
      list: {
        type: Array as PropType<ColumnProps[]>, // 断言，可以通过.来读取属性提示
        required: true
      }
    },
    setup(props) {
      const columnList = computed(() => {
        // console.log(props.list)
        return props.list.map(column => {
          // console.log(column.avatar)
          if (!column.cover_pic) {
            column.cover_pic = require('@/assets/default_img.png')
          } else {
            // oss 的缩放功能
            column.cover_pic = column.cover_pic + '?x-oss-process=image/resize,m_pad,h_50,w_50'
          }
          return column
        })
      })
      return {
        columnList
      }
    }
  })
