
  import { defineComponent, reactive, PropType, onMounted } from 'vue'
  import { emitter } from './ValidateForm.vue'

  const emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/
  const phoneReg = /^1[0-9]{10}$/

  interface RuleProp {
    type: 'required' | 'email' | 'phone';
    message: string
  }

  export type RulesProp = RuleProp[]
  export type TagType = 'input' | 'textarea'
  export default defineComponent({
    name: 'ValidateInput',
    props: {
      rules: Array as PropType<RulesProp>,
      modelValue: String,
      tag: {
        type: String as PropType<TagType>,
        default: 'input'
      }
    },
    // 不希望组件的根元素继承attribute
    inheritAttrs: false,
    setup(props, context) {
      const inputRef = reactive({
        val: props.modelValue || '',
        error: false,
        message: ''
      })
      const updateValue = (e: KeyboardEvent) => {
        const targetValue = (e.target as HTMLInputElement).value
        inputRef.val = targetValue
        context.emit('update:modelValue', targetValue)
      }
      const validateInput = () => {
        if (props.rules) {
          const allPassed = props.rules.every(rule => {
            let passed = true
            inputRef.message = rule.message
            switch (rule.type) {
              case 'required':
                passed = (inputRef.val.trim() !== '')
                break
              case 'email':
                passed = emailReg.test(inputRef.val)
                break
              case 'phone':
                passed = phoneReg.test(inputRef.val)
                break
              default:
                break
            }
            return passed
          })
          inputRef.error = !allPassed
          return allPassed
        }
        return true
      }
      onMounted(() => {
        emitter.emit('form-item-created', validateInput)
      })
      return {
        inputRef,
        validateInput,
        updateValue
      }
    }
  })
