
export interface SelectData {
  label: string;
  value: string;
}

export interface SelectNumData {
  label: string;
  value: number;
}

// 图片列表
export interface ImageListType {
  img: string; // 图片
}

// 城市信息
export interface City {
  cityName: string; // 城市名
  pinYin: string; // 拼音首字母大写
  py: string; // 拼音首字母小写
  code: string; // 城市编号
}
export interface CityData {
  hotCity: City[];
  cities: City[];
}

// 租房配套情况
export interface RentUsedData {
  id: number;
  name: string; // 名称
  simg: string; // 选中图片
  nimg: string; // 未选中图片
  show: boolean; // 是否显示
}

export const rentUsedData: RentUsedData[] = [
  { id: 0, name: '洗衣机', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_xiyiji_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_xiyiji_normal.png', show: false },
  { id: 0, name: '空调', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_kongtiao_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_kongtiao_normal.png', show: false },
  { id: 0, name: '衣柜', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_yigui_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_yigui_normal.png', show: false },
  { id: 0, name: '电视', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_dianshi_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_dianshi_normal.png', show: false },
  { id: 0, name: '冰箱', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_bingxiang_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_bingxiang_normal.png', show: false },
  { id: 0, name: '热水器', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_reshuiqi_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_reshuiqi_normal.png', show: false },
  { id: 0, name: '床', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_chuang_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_chuang_normal.png', show: false },
  { id: 0, name: '暖气', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_nuanqi_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_nuanqi_normal.png', show: false },
  { id: 0, name: '网络', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_wifi_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_wifi_normal.png', show: false },
  { id: 0, name: '天然气', simg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_tianranqi_default.png', nimg: 'https://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/woyaochuzu_icon_tianranqi_normal.png', show: false }
]

export const cityData: CityData = {
  hotCity: [
    {
      cityName: '南宁',
      pinYin: 'N',
      py: 'n',
      code: '450100'
    },
    {
      cityName: '防城港',
      pinYin: 'F',
      py: 'f',
      code: '450600'
    }
  ],
  cities: [
    { cityName: '阿拉善', pinYin: 'A', py: 'a', code: '152900' }, {
      cityName: '鞍山',
      pinYin: 'A',
      py: 'a',
      code: '210300'
    }, { cityName: '安庆', pinYin: 'A', py: 'a', code: '340800' }, {
      cityName: '安阳',
      pinYin: 'A',
      py: 'a',
      code: '410500'
    }, { cityName: '北京', pinYin: 'B', py: 'b', code: '110100' }, {
      cityName: '保定',
      pinYin: 'B',
      py: 'b',
      code: '130600'
    }, { cityName: '包头', pinYin: 'B', py: 'b', code: '150200' }, {
      cityName: '巴彦淖尔',
      pinYin: 'B',
      py: 'b',
      code: '150800'
    }, { cityName: '本溪', pinYin: 'B', py: 'b', code: '210500' }, {
      cityName: '白山',
      pinYin: 'B',
      py: 'b',
      code: '220600'
    }, { cityName: '白城', pinYin: 'B', py: 'b', code: '220800' }, {
      cityName: '蚌埠',
      pinYin: 'B',
      py: 'b',
      code: '340300'
    }, { cityName: '亳州', pinYin: 'B', py: 'b', code: '341600' }, {
      cityName: '滨州',
      pinYin: 'B',
      py: 'b',
      code: '371600'
    }, { cityName: '承德', pinYin: 'C', py: 'c', code: '130800' }, {
      cityName: '沧州',
      pinYin: 'C',
      py: 'c',
      code: '130900'
    }, { cityName: '长治', pinYin: 'C', py: 'c', code: '140400' }, {
      cityName: '赤峰',
      pinYin: 'C',
      py: 'c',
      code: '150400'
    }, { cityName: '朝阳', pinYin: 'C', py: 'c', code: '211300' }, {
      cityName: '长春',
      pinYin: 'C',
      py: 'c',
      code: '220100'
    }, { cityName: '常州', pinYin: 'C', py: 'c', code: '320400' }, {
      cityName: '滁州',
      pinYin: 'C',
      py: 'c',
      code: '341100'
    }, { cityName: '池州', pinYin: 'C', py: 'c', code: '341700' }, {
      cityName: '大同',
      pinYin: 'D',
      py: 'd',
      code: '140200'
    }, { cityName: '大连', pinYin: 'D', py: 'd', code: '210200' }, {
      cityName: '丹东',
      pinYin: 'D',
      py: 'd',
      code: '210600'
    }, { cityName: '大庆', pinYin: 'D', py: 'd', code: '230600' }, {
      cityName: '大兴安岭',
      pinYin: 'D',
      py: 'd',
      code: '232700'
    }, { cityName: '东营', pinYin: 'D', py: 'd', code: '370500' }, {
      cityName: '德州',
      pinYin: 'D',
      py: 'd',
      code: '371400'
    }, { cityName: '鄂尔多斯', pinYin: 'E', py: 'e', code: '150600' }, {
      cityName: '鄂州',
      pinYin: 'E',
      py: 'e',
      code: '420700'
    }, { cityName: '恩施', pinYin: 'E', py: 'e', code: '422800' }, {
      cityName: '抚顺',
      pinYin: 'F',
      py: 'f',
      code: '210400'
    }, { cityName: '阜新', pinYin: 'F', py: 'f', code: '210900' }, {
      cityName: '阜阳',
      pinYin: 'F',
      py: 'f',
      code: '341200'
    }, { cityName: '福州', pinYin: 'F', py: 'f', code: '350100' }, {
      cityName: '抚州',
      pinYin: 'F',
      py: 'f',
      code: '361000'
    }, { cityName: '赣州', pinYin: 'G', py: 'g', code: '360700' }, {
      cityName: '邯郸',
      pinYin: 'H',
      py: 'h',
      code: '130400'
    }, { cityName: '衡水', pinYin: 'H', py: 'h', code: '131100' }, {
      cityName: '呼和浩特',
      pinYin: 'H',
      py: 'h',
      code: '150100'
    }, { cityName: '呼伦贝尔', pinYin: 'H', py: 'h', code: '150700' }, {
      cityName: '葫芦岛',
      pinYin: 'H',
      py: 'h',
      code: '211400'
    }, { cityName: '哈尔滨', pinYin: 'H', py: 'h', code: '230100' }, {
      cityName: '鹤岗',
      pinYin: 'H',
      py: 'h',
      code: '230400'
    }, { cityName: '黑河', pinYin: 'H', py: 'h', code: '231100' }, {
      cityName: '淮安',
      pinYin: 'H',
      py: 'h',
      code: '320800'
    }, { cityName: '杭州', pinYin: 'H', py: 'h', code: '330100' }, {
      cityName: '湖州',
      pinYin: 'H',
      py: 'h',
      code: '330500'
    }, { cityName: '合肥', pinYin: 'H', py: 'h', code: '340100' }, {
      cityName: '淮南',
      pinYin: 'H',
      py: 'h',
      code: '340400'
    }, { cityName: '淮北', pinYin: 'H', py: 'h', code: '340600' }, {
      cityName: '黄山',
      pinYin: 'H',
      py: 'h',
      code: '341000'
    }, { cityName: '菏泽', pinYin: 'H', py: 'h', code: '371700' }, {
      cityName: '鹤壁',
      pinYin: 'H',
      py: 'h',
      code: '410600'
    }, { cityName: '黄石', pinYin: 'H', py: 'h', code: '420200' }, {
      cityName: '黄冈',
      pinYin: 'H',
      py: 'h',
      code: '421100'
    }, { cityName: '晋城', pinYin: 'J', py: 'j', code: '140500' }, {
      cityName: '晋中',
      pinYin: 'J',
      py: 'j',
      code: '140700'
    }, { cityName: '锦州', pinYin: 'J', py: 'j', code: '210700' }, {
      cityName: '吉林',
      pinYin: 'J',
      py: 'j',
      code: '220200'
    }, { cityName: '鸡西', pinYin: 'J', py: 'j', code: '230300' }, {
      cityName: '佳木斯',
      pinYin: 'J',
      py: 'j',
      code: '230800'
    }, { cityName: '嘉兴', pinYin: 'J', py: 'j', code: '330400' }, {
      cityName: '金华',
      pinYin: 'J',
      py: 'j',
      code: '330700'
    }, { cityName: '景德镇', pinYin: 'J', py: 'j', code: '360200' }, {
      cityName: '九江',
      pinYin: 'J',
      py: 'j',
      code: '360400'
    }, { cityName: '吉安', pinYin: 'J', py: 'j', code: '360800' }, {
      cityName: '济南',
      pinYin: 'J',
      py: 'j',
      code: '370100'
    }, { cityName: '济宁', pinYin: 'J', py: 'j', code: '370800' }, {
      cityName: '焦作',
      pinYin: 'J',
      py: 'j',
      code: '410800'
    }, { cityName: '济源', pinYin: 'J', py: 'j', code: '419001' }, {
      cityName: '荆门',
      pinYin: 'J',
      py: 'j',
      code: '420800'
    }, { cityName: '荆州', pinYin: 'J', py: 'j', code: '421000' }, {
      cityName: '开封',
      pinYin: 'K',
      py: 'k',
      code: '410200'
    }, { cityName: '廊坊', pinYin: 'L', py: 'l', code: '131000' }, {
      cityName: '临汾',
      pinYin: 'L',
      py: 'l',
      code: '141000'
    }, { cityName: '吕梁', pinYin: 'L', py: 'l', code: '141100' }, {
      cityName: '辽阳',
      pinYin: 'L',
      py: 'l',
      code: '211000'
    }, { cityName: '辽源', pinYin: 'L', py: 'l', code: '220400' }, {
      cityName: '连云港',
      pinYin: 'L',
      py: 'l',
      code: '320700'
    }, { cityName: '丽水', pinYin: 'L', py: 'l', code: '331100' }, {
      cityName: '六安',
      pinYin: 'L',
      py: 'l',
      code: '341500'
    }, { cityName: '龙岩', pinYin: 'L', py: 'l', code: '350800' }, {
      cityName: '莱芜',
      pinYin: 'L',
      py: 'l',
      code: '371200'
    }, { cityName: '临沂', pinYin: 'L', py: 'l', code: '371300' }, {
      cityName: '聊城',
      pinYin: 'L',
      py: 'l',
      code: '371500'
    }, { cityName: '洛阳', pinYin: 'L', py: 'l', code: '410300' }, {
      cityName: '漯河',
      pinYin: 'L',
      py: 'l',
      code: '411100'
    }, { cityName: '牡丹江', pinYin: 'M', py: 'm', code: '231000' }, {
      cityName: '马鞍山',
      pinYin: 'M',
      py: 'm',
      code: '340500'
    }, { cityName: '南京', pinYin: 'N', py: 'n', code: '320100' }, {
      cityName: '南通',
      pinYin: 'N',
      py: 'n',
      code: '320600'
    }, { cityName: '宁波', pinYin: 'N', py: 'n', code: '330200' }, {
      cityName: '南平',
      pinYin: 'N',
      py: 'n',
      code: '350700'
    }, { cityName: '宁德', pinYin: 'N', py: 'n', code: '350900' }, {
      cityName: '南昌',
      pinYin: 'N',
      py: 'n',
      code: '360100'
    }, { cityName: '南阳', pinYin: 'N', py: 'n', code: '411300' }, {
      cityName: '盘锦',
      pinYin: 'P',
      py: 'p',
      code: '211100'
    }, { cityName: '莆田', pinYin: 'P', py: 'p', code: '350300' }, {
      cityName: '萍乡',
      pinYin: 'P',
      py: 'p',
      code: '360300'
    }, { cityName: '平顶山', pinYin: 'P', py: 'p', code: '410400' }, {
      cityName: '濮阳',
      pinYin: 'P',
      py: 'p',
      code: '410900'
    }, { cityName: '秦皇岛', pinYin: 'Q', py: 'q', code: '130300' }, {
      cityName: '齐齐哈尔',
      pinYin: 'Q',
      py: 'q',
      code: '230200'
    }, { cityName: '七台河', pinYin: 'Q', py: 'q', code: '230900' }, {
      cityName: '衢州',
      pinYin: 'Q',
      py: 'q',
      code: '330800'
    }, { cityName: '泉州', pinYin: 'Q', py: 'q', code: '350500' }, {
      cityName: '青岛',
      pinYin: 'Q',
      py: 'q',
      code: '370200'
    }, { cityName: '潜江', pinYin: 'Q', py: 'q', code: '429005' }, {
      cityName: '日照',
      pinYin: 'R',
      py: 'r',
      code: '371100'
    }, { cityName: '石家庄', pinYin: 'S', py: 's', code: '130100' }, {
      cityName: '朔州',
      pinYin: 'S',
      py: 's',
      code: '140600'
    }, { cityName: '沈阳', pinYin: 'S', py: 's', code: '210100' }, {
      cityName: '四平',
      pinYin: 'S',
      py: 's',
      code: '220300'
    }, { cityName: '松原', pinYin: 'S', py: 's', code: '220700' }, {
      cityName: '双鸭山',
      pinYin: 'S',
      py: 's',
      code: '230500'
    }, { cityName: '绥化', pinYin: 'S', py: 's', code: '231200' }, {
      cityName: '上海',
      pinYin: 'S',
      py: 's',
      code: '310100'
    }, { cityName: '苏州', pinYin: 'S', py: 's', code: '320500' }, {
      cityName: '宿迁',
      pinYin: 'S',
      py: 's',
      code: '321300'
    }, { cityName: '绍兴', pinYin: 'S', py: 's', code: '330600' }, {
      cityName: '宿州',
      pinYin: 'S',
      py: 's',
      code: '341300'
    }, { cityName: '三明', pinYin: 'S', py: 's', code: '350400' }, {
      cityName: '上饶',
      pinYin: 'S',
      py: 's',
      code: '361100'
    }, { cityName: '三门峡', pinYin: 'S', py: 's', code: '411200' }, {
      cityName: '商丘',
      pinYin: 'S',
      py: 's',
      code: '411400'
    }, { cityName: '十堰', pinYin: 'S', py: 's', code: '420300' }, {
      cityName: '随州',
      pinYin: 'S',
      py: 's',
      code: '421300'
    }, { cityName: '神农架林区', pinYin: 'S', py: 's', code: '429021' }, {
      cityName: '天津',
      pinYin: 'T',
      py: 't',
      code: '120100'
    }, { cityName: '唐山', pinYin: 'T', py: 't', code: '130200' }, {
      cityName: '太原',
      pinYin: 'T',
      py: 't',
      code: '140100'
    }, { cityName: '通辽', pinYin: 'T', py: 't', code: '150500' }, {
      cityName: '铁岭',
      pinYin: 'T',
      py: 't',
      code: '211200'
    }, { cityName: '通化', pinYin: 'T', py: 't', code: '220500' }, {
      cityName: '泰州',
      pinYin: 'T',
      py: 't',
      code: '321200'
    }, { cityName: '台州', pinYin: 'T', py: 't', code: '331000' }, {
      cityName: '铜陵',
      pinYin: 'T',
      py: 't',
      code: '340700'
    }, { cityName: '泰安', pinYin: 'T', py: 't', code: '370900' }, {
      cityName: '天门',
      pinYin: 'T',
      py: 't',
      code: '429006'
    }, { cityName: '乌海', pinYin: 'W', py: 'w', code: '150300' }, {
      cityName: '乌兰察布',
      pinYin: 'W',
      py: 'w',
      code: '150900'
    }, { cityName: '无锡', pinYin: 'W', py: 'w', code: '320200' }, {
      cityName: '温州',
      pinYin: 'W',
      py: 'w',
      code: '330300'
    }, { cityName: '芜湖', pinYin: 'W', py: 'w', code: '340200' }, {
      cityName: '潍坊',
      pinYin: 'W',
      py: 'w',
      code: '370700'
    }, { cityName: '威海', pinYin: 'W', py: 'w', code: '371000' }, {
      cityName: '武汉',
      pinYin: 'W',
      py: 'w',
      code: '420100'
    }, { cityName: '邢台', pinYin: 'X', py: 'x', code: '130500' }, {
      cityName: '忻州',
      pinYin: 'X',
      py: 'x',
      code: '140900'
    }, { cityName: '兴安', pinYin: 'X', py: 'x', code: '152200' }, {
      cityName: '锡林郭勒',
      pinYin: 'X',
      py: 'x',
      code: '152500'
    }, { cityName: '徐州', pinYin: 'X', py: 'x', code: '320300' }, {
      cityName: '宣城',
      pinYin: 'X',
      py: 'x',
      code: '341800'
    }, { cityName: '厦门', pinYin: 'X', py: 'x', code: '350200' }, {
      cityName: '新余',
      pinYin: 'X',
      py: 'x',
      code: '360500'
    }, { cityName: '新乡', pinYin: 'X', py: 'x', code: '410700' }, {
      cityName: '许昌',
      pinYin: 'X',
      py: 'x',
      code: '411000'
    }, { cityName: '信阳', pinYin: 'X', py: 'x', code: '411500' }, {
      cityName: '襄阳',
      pinYin: 'X',
      py: 'x',
      code: '420600'
    }, { cityName: '孝感', pinYin: 'X', py: 'x', code: '420900' }, {
      cityName: '咸宁',
      pinYin: 'X',
      py: 'x',
      code: '421200'
    }, { cityName: '仙桃', pinYin: 'X', py: 'x', code: '429004' }, {
      cityName: '阳泉',
      pinYin: 'Y',
      py: 'y',
      code: '140300'
    }, { cityName: '运城', pinYin: 'Y', py: 'y', code: '140800' }, {
      cityName: '营口',
      pinYin: 'Y',
      py: 'y',
      code: '210800'
    }, { cityName: '延边', pinYin: 'Y', py: 'y', code: '222400' }, {
      cityName: '伊春',
      pinYin: 'Y',
      py: 'y',
      code: '230700'
    }, { cityName: '盐城', pinYin: 'Y', py: 'y', code: '320900' }, {
      cityName: '扬州',
      pinYin: 'Y',
      py: 'y',
      code: '321000'
    }, { cityName: '鹰潭', pinYin: 'Y', py: 'y', code: '360600' }, {
      cityName: '宜春',
      pinYin: 'Y',
      py: 'y',
      code: '360900'
    }, { cityName: '烟台', pinYin: 'Y', py: 'y', code: '370600' }, {
      cityName: '宜昌',
      pinYin: 'Y',
      py: 'y',
      code: '420500'
    }, { cityName: '张家口', pinYin: 'Z', py: 'z', code: '130700' }, {
      cityName: '镇江',
      pinYin: 'Z',
      py: 'z',
      code: '321100'
    }, { cityName: '舟山', pinYin: 'Z', py: 'z', code: '330900' }, {
      cityName: '漳州',
      pinYin: 'Z',
      py: 'z',
      code: '350600'
    }, { cityName: '淄博', pinYin: 'Z', py: 'z', code: '370300' }, {
      cityName: '枣庄',
      pinYin: 'Z',
      py: 'z',
      code: '370400'
    }, { cityName: '郑州', pinYin: 'Z', py: 'z', code: '410100' }, {
      cityName: '周口',
      pinYin: 'Z',
      py: 'z',
      code: '411600'
    }, { cityName: '驻马店', pinYin: 'Z', py: 'z', code: '411700' },
    { cityName: '阿坝', pinYin: 'A', py: 'a', code: '513200' }, {
      cityName: '安顺',
      pinYin: 'A',
      py: 'a',
      code: '520400'
    }, { cityName: '阿里', pinYin: 'A', py: 'a', code: '542500' }, {
      cityName: '安康',
      pinYin: 'A',
      py: 'a',
      code: '610900'
    }, { cityName: '阿克苏', pinYin: 'A', py: 'a', code: '652900' }, {
      cityName: '阿勒泰',
      pinYin: 'A',
      py: 'a',
      code: '654300'
    }, { cityName: '阿拉尔', pinYin: 'A', py: 'a', code: '659002' }, {
      cityName: '北海',
      pinYin: 'B',
      py: 'b',
      code: '450500'
    }, { cityName: '百色', pinYin: 'B', py: 'b', code: '451000' }, {
      cityName: '白沙',
      pinYin: 'B',
      py: 'b',
      code: '469025'
    }, { cityName: '保亭', pinYin: 'B', py: 'b', code: '469029' }, {
      cityName: '巴中',
      pinYin: 'B',
      py: 'b',
      code: '511900'
    }, { cityName: '毕节', pinYin: 'B', py: 'b', code: '520500' }, {
      cityName: '保山市',
      pinYin: 'B',
      py: 'b',
      code: '530500'
    }, { cityName: '宝鸡', pinYin: 'B', py: 'b', code: '610300' }, {
      cityName: '白银',
      pinYin: 'B',
      py: 'b',
      code: '620400'
    }, { cityName: '博尔塔拉', pinYin: 'B', py: 'b', code: '652700' }, {
      cityName: '巴音郭楞',
      pinYin: 'B',
      py: 'b',
      code: '652800'
    }, { cityName: '北屯', pinYin: 'B', py: 'b', code: '659005' }, {
      cityName: '长沙',
      pinYin: 'C',
      py: 'c',
      code: '430100'
    }, { cityName: '常德', pinYin: 'C', py: 'c', code: '430700' }, {
      cityName: '郴州',
      pinYin: 'C',
      py: 'c',
      code: '431000'
    }, { cityName: '潮州', pinYin: 'C', py: 'c', code: '445100' }, {
      cityName: '崇左',
      pinYin: 'C',
      py: 'c',
      code: '451400'
    }, { cityName: '澄迈', pinYin: 'C', py: 'c', code: '469023' }, {
      cityName: '昌江',
      pinYin: 'C',
      py: 'c',
      code: '469026'
    }, { cityName: '重庆', pinYin: 'C', py: 'c', code: '500100' }, {
      cityName: '重庆郊县',
      pinYin: 'C',
      py: 'c',
      code: '500200'
    }, { cityName: '成都', pinYin: 'C', py: 'c', code: '510100' }, {
      cityName: '楚雄',
      pinYin: 'C',
      py: 'c',
      code: '532300'
    }, { cityName: '昌都', pinYin: 'C', py: 'c', code: '540300' }, {
      cityName: '昌吉',
      pinYin: 'C',
      py: 'c',
      code: '652300'
    }, { cityName: '东莞', pinYin: 'D', py: 'd', code: '441900' }, {
      cityName: '东沙群岛',
      pinYin: 'D',
      py: 'd',
      code: '442100'
    }, { cityName: '儋州', pinYin: 'D', py: 'd', code: '460400' }, {
      cityName: '东方',
      pinYin: 'D',
      py: 'd',
      code: '469007'
    }, { cityName: '定安', pinYin: 'D', py: 'd', code: '469021' }, {
      cityName: '德阳',
      pinYin: 'D',
      py: 'd',
      code: '510600'
    }, { cityName: '达州', pinYin: 'D', py: 'd', code: '511700' }, {
      cityName: '大理',
      pinYin: 'D',
      py: 'd',
      code: '532900'
    }, { cityName: '德宏', pinYin: 'D', py: 'd', code: '533100' }, {
      cityName: '迪庆',
      pinYin: 'D',
      py: 'd',
      code: '533400'
    }, { cityName: '定西', pinYin: 'D', py: 'd', code: '621100' }, {
      cityName: '佛山',
      pinYin: 'F',
      py: 'f',
      code: '440600'
    }, { cityName: '防城港', pinYin: 'F', py: 'f', code: '450600' }, {
      cityName: '广州',
      pinYin: 'G',
      py: 'g',
      code: '440100'
    }, { cityName: '桂林', pinYin: 'G', py: 'g', code: '450300' }, {
      cityName: '贵港',
      pinYin: 'G',
      py: 'g',
      code: '450800'
    }, { cityName: '广元', pinYin: 'G', py: 'g', code: '510800' }, {
      cityName: '广安',
      pinYin: 'G',
      py: 'g',
      code: '511600'
    }, { cityName: '甘孜', pinYin: 'G', py: 'g', code: '513300' }, {
      cityName: '贵阳',
      pinYin: 'G',
      py: 'g',
      code: '520100'
    }, { cityName: '甘南', pinYin: 'G', py: 'g', code: '623000' }, {
      cityName: '果洛',
      pinYin: 'G',
      py: 'g',
      code: '632600'
    }, { cityName: '固原', pinYin: 'G', py: 'g', code: '640400' }, {
      cityName: '衡阳',
      pinYin: 'H',
      py: 'h',
      code: '430400'
    }, { cityName: '怀化', pinYin: 'H', py: 'h', code: '431200' }, {
      cityName: '惠州',
      pinYin: 'H',
      py: 'h',
      code: '441300'
    }, { cityName: '河源', pinYin: 'H', py: 'h', code: '441600' }, {
      cityName: '贺州',
      pinYin: 'H',
      py: 'h',
      code: '451100'
    }, { cityName: '河池', pinYin: 'H', py: 'h', code: '451200' }, {
      cityName: '海口',
      pinYin: 'H',
      py: 'h',
      code: '460100'
    }, { cityName: '红河', pinYin: 'H', py: 'h', code: '532500' }, {
      cityName: '汉中',
      pinYin: 'H',
      py: 'h',
      code: '610700'
    }, { cityName: '海东', pinYin: 'H', py: 'h', code: '630200' }, {
      cityName: '海北',
      pinYin: 'H',
      py: 'h',
      code: '632200'
    }, { cityName: '黄南', pinYin: 'H', py: 'h', code: '632300' }, {
      cityName: '海南',
      pinYin: 'H',
      py: 'h',
      code: '632500'
    }, { cityName: '海西', pinYin: 'H', py: 'h', code: '632800' }, {
      cityName: '哈密市',
      pinYin: 'H',
      py: 'h',
      code: '650500'
    }, { cityName: '和田', pinYin: 'H', py: 'h', code: '653200' }, {
      cityName: '江门',
      pinYin: 'J',
      py: 'j',
      code: '440700'
    }, { cityName: '揭阳', pinYin: 'J', py: 'j', code: '445200' }, {
      cityName: '嘉峪关',
      pinYin: 'J',
      py: 'j',
      code: '620200'
    }, { cityName: '金昌', pinYin: 'J', py: 'j', code: '620300' }, {
      cityName: '酒泉',
      pinYin: 'J',
      py: 'j',
      code: '620900'
    }, { cityName: '昆明', pinYin: 'K', py: 'k', code: '530100' }, {
      cityName: '克拉玛依',
      pinYin: 'K',
      py: 'k',
      code: '650200'
    }, { cityName: '克孜勒苏', pinYin: 'K', py: 'k', code: '653000' }, {
      cityName: '喀什',
      pinYin: 'K',
      py: 'k',
      code: '653100'
    }, { cityName: '可克达拉', pinYin: 'K', py: 'k', code: '659008' }, {
      cityName: '昆玉',
      pinYin: 'K',
      py: 'k',
      code: '659009'
    }, { cityName: '娄底', pinYin: 'L', py: 'l', code: '431300' }, {
      cityName: '柳州',
      pinYin: 'L',
      py: 'l',
      code: '450200'
    }, { cityName: '来宾', pinYin: 'L', py: 'l', code: '451300' }, {
      cityName: '临高',
      pinYin: 'L',
      py: 'l',
      code: '469024'
    }, { cityName: '乐东', pinYin: 'L', py: 'l', code: '469027' }, {
      cityName: '陵水',
      pinYin: 'L',
      py: 'l',
      code: '469028'
    }, { cityName: '泸州', pinYin: 'L', py: 'l', code: '510500' }, {
      cityName: '乐山',
      pinYin: 'L',
      py: 'l',
      code: '511100'
    }, { cityName: '凉山彝族自治州', pinYin: 'L', py: 'l', code: '513400' }, {
      cityName: '六盘水',
      pinYin: 'L',
      py: 'l',
      code: '520200'
    }, { cityName: '丽江', pinYin: 'L', py: 'l', code: '530700' }, {
      cityName: '临沧',
      pinYin: 'L',
      py: 'l',
      code: '530900'
    }, { cityName: '拉萨', pinYin: 'L', py: 'l', code: '540100' }, {
      cityName: '林芝',
      pinYin: 'L',
      py: 'l',
      code: '540400'
    }, { cityName: '兰州', pinYin: 'L', py: 'l', code: '620100' }, {
      cityName: '陇南',
      pinYin: 'L',
      py: 'l',
      code: '621200'
    }, { cityName: '临夏', pinYin: 'L', py: 'l', code: '622900' }, {
      cityName: '茂名',
      pinYin: 'M',
      py: 'm',
      code: '440900'
    }, { cityName: '梅州', pinYin: 'M', py: 'm', code: '441400' }, {
      cityName: '绵阳',
      pinYin: 'M',
      py: 'm',
      code: '510700'
    }, { cityName: '眉山', pinYin: 'M', py: 'm', code: '511400' }, {
      cityName: '南宁',
      pinYin: 'N',
      py: 'n',
      code: '450100'
    }, { cityName: '内江', pinYin: 'N', py: 'n', code: '511000' }, {
      cityName: '南充',
      pinYin: 'N',
      py: 'n',
      code: '511300'
    }, { cityName: '怒江', pinYin: 'N', py: 'n', code: '533300' }, {
      cityName: '那曲',
      pinYin: 'N',
      py: 'n',
      code: '540600'
    }, { cityName: '攀枝花', pinYin: 'P', py: 'p', code: '510400' }, {
      cityName: '普洱',
      pinYin: 'P',
      py: 'p',
      code: '530800'
    }, { cityName: '平凉', pinYin: 'P', py: 'p', code: '620800' }, {
      cityName: '清远',
      pinYin: 'Q',
      py: 'q',
      code: '441800'
    }, { cityName: '钦州', pinYin: 'Q', py: 'q', code: '450700' }, {
      cityName: '琼海',
      pinYin: 'Q',
      py: 'q',
      code: '469002'
    }, { cityName: '琼中', pinYin: 'Q', py: 'q', code: '469030' }, {
      cityName: '黔西南',
      pinYin: 'Q',
      py: 'q',
      code: '522300'
    }, { cityName: '黔东南', pinYin: 'Q', py: 'q', code: '522600' }, {
      cityName: '黔南',
      pinYin: 'Q',
      py: 'q',
      code: '522700'
    }, { cityName: '曲靖', pinYin: 'Q', py: 'q', code: '530300' }, {
      cityName: '庆阳',
      pinYin: 'Q',
      py: 'q',
      code: '621000'
    }, { cityName: '日喀则', pinYin: 'R', py: 'r', code: '540200' }, {
      cityName: '邵阳',
      pinYin: 'S',
      py: 's',
      code: '430500'
    }, { cityName: '韶关', pinYin: 'S', py: 's', code: '440200' }, {
      cityName: '深圳',
      pinYin: 'S',
      py: 's',
      code: '440300'
    }, { cityName: '汕头', pinYin: 'S', py: 's', code: '440500' }, {
      cityName: '汕尾',
      pinYin: 'S',
      py: 's',
      code: '441500'
    }, { cityName: '三亚', pinYin: 'S', py: 's', code: '460200' }, {
      cityName: '三沙',
      pinYin: 'S',
      py: 's',
      code: '460300'
    }, { cityName: '遂宁', pinYin: 'S', py: 's', code: '510900' }, {
      cityName: '山南',
      pinYin: 'S',
      py: 's',
      code: '540500'
    }, { cityName: '商洛', pinYin: 'S', py: 's', code: '611000' }, {
      cityName: '石嘴山',
      pinYin: 'S',
      py: 's',
      code: '640200'
    }, { cityName: '石河子', pinYin: 'S', py: 's', code: '659001' }, {
      cityName: '双河',
      pinYin: 'S',
      py: 's',
      code: '659007'
    }, { cityName: '屯昌', pinYin: 'T', py: 't', code: '469022' }, {
      cityName: '铜仁',
      pinYin: 'T',
      py: 't',
      code: '520600'
    }, { cityName: '铜川', pinYin: 'T', py: 't', code: '610200' }, {
      cityName: '天水',
      pinYin: 'T',
      py: 't',
      code: '620500'
    }, { cityName: '吐鲁番市', pinYin: 'T', py: 't', code: '650400' }, {
      cityName: '塔城',
      pinYin: 'T',
      py: 't',
      code: '654200'
    }, { cityName: '图木舒克', pinYin: 'T', py: 't', code: '659003' }, {
      cityName: '铁门关',
      pinYin: 'T',
      py: 't',
      code: '659006'
    }, { cityName: '梧州', pinYin: 'W', py: 'w', code: '450400' }, {
      cityName: '五指山',
      pinYin: 'W',
      py: 'w',
      code: '469001'
    }, { cityName: '文昌', pinYin: 'W', py: 'w', code: '469005' }, {
      cityName: '万宁',
      pinYin: 'W',
      py: 'w',
      code: '469006'
    }, { cityName: '文山', pinYin: 'W', py: 'w', code: '532600' }, {
      cityName: '渭南',
      pinYin: 'W',
      py: 'w',
      code: '610500'
    }, { cityName: '武威', pinYin: 'W', py: 'w', code: '620600' }, {
      cityName: '吴忠',
      pinYin: 'W',
      py: 'w',
      code: '640300'
    }, { cityName: '乌鲁木齐', pinYin: 'W', py: 'w', code: '650100' }, {
      cityName: '五家渠',
      pinYin: 'W',
      py: 'w',
      code: '659004'
    }, { cityName: '湘潭', pinYin: 'X', py: 'x', code: '430300' }, {
      cityName: '湘西',
      pinYin: 'X',
      py: 'x',
      code: '433100'
    }, { cityName: '西双版纳', pinYin: 'X', py: 'x', code: '532800' }, {
      cityName: '西安',
      pinYin: 'X',
      py: 'x',
      code: '610100'
    }, { cityName: '咸阳', pinYin: 'X', py: 'x', code: '610400' }, {
      cityName: '西宁',
      pinYin: 'X',
      py: 'x',
      code: '630100'
    }, { cityName: '岳阳', pinYin: 'Y', py: 'y', code: '430600' }, {
      cityName: '益阳',
      pinYin: 'Y',
      py: 'y',
      code: '430900'
    }, { cityName: '永州', pinYin: 'Y', py: 'y', code: '431100' }, {
      cityName: '阳江',
      pinYin: 'Y',
      py: 'y',
      code: '441700'
    }, { cityName: '云浮', pinYin: 'Y', py: 'y', code: '445300' }, {
      cityName: '玉林',
      pinYin: 'Y',
      py: 'y',
      code: '450900'
    }, { cityName: '宜宾', pinYin: 'Y', py: 'y', code: '511500' }, {
      cityName: '雅安',
      pinYin: 'Y',
      py: 'y',
      code: '511800'
    }, { cityName: '玉溪', pinYin: 'Y', py: 'y', code: '530400' }, {
      cityName: '延安',
      pinYin: 'Y',
      py: 'y',
      code: '610600'
    }, { cityName: '榆林', pinYin: 'Y', py: 'y', code: '610800' }, {
      cityName: '玉树',
      pinYin: 'Y',
      py: 'y',
      code: '632700'
    }, { cityName: '银川', pinYin: 'Y', py: 'y', code: '640100' }, {
      cityName: '伊犁',
      pinYin: 'Y',
      py: 'y',
      code: '654000'
    }, { cityName: '株洲', pinYin: 'Z', py: 'z', code: '430200' }, {
      cityName: '张家界',
      pinYin: 'Z',
      py: 'z',
      code: '430800'
    }, { cityName: '珠海', pinYin: 'Z', py: 'z', code: '440400' }, {
      cityName: '湛江',
      pinYin: 'Z',
      py: 'z',
      code: '440800'
    }, { cityName: '肇庆', pinYin: 'Z', py: 'z', code: '441200' }, {
      cityName: '中山',
      pinYin: 'Z',
      py: 'z',
      code: '442000'
    }, { cityName: '自贡', pinYin: 'Z', py: 'z', code: '510300' }, {
      cityName: '资阳',
      pinYin: 'Z',
      py: 'z',
      code: '512000'
    }, { cityName: '遵义', pinYin: 'Z', py: 'z', code: '520300' }, {
      cityName: '昭通',
      pinYin: 'Z',
      py: 'z',
      code: '530600'
    }, { cityName: '张掖', pinYin: 'Z', py: 'z', code: '620700' }, {
      cityName: '中卫',
      pinYin: 'Z',
      py: 'z',
      code: '640500'
    }
  ]
}

export const houseTypeData: SelectData[] = [
  {
    value: '1室0厅0卫',
    label: '1室0厅0卫'
  },
  {
    value: '1室1厅0卫',
    label: '1室1厅0卫'
  },
  {
    value: '1室1厅1卫',
    label: '1室1厅1卫'
  },
  {
    value: '2室1厅1卫',
    label: '2室1厅1卫'
  },
  {
    value: '2室2厅1卫',
    label: '2室2厅1卫'
  },
  {
    value: '3室1厅1卫',
    label: '3室1厅1卫'
  },
  {
    value: '3室2厅1卫',
    label: '3室2厅1卫'
  },
  {
    value: '3室2厅2卫',
    label: '3室2厅2卫'
  },
  {
    value: '4室1厅1卫',
    label: '4室1厅1卫'
  },
  {
    value: '4室2厅1卫',
    label: '4室2厅1卫'
  },
  {
    value: '4室2厅2卫',
    label: '4室2厅2卫'
  },
  {
    value: '5室1厅1卫',
    label: '5室1厅1卫'
  },
  {
    value: '5室2厅1卫',
    label: '5室2厅1卫'
  },
  {
    value: '5室2厅2卫',
    label: '5室2厅2卫'
  },
  {
    value: '6室以上',
    label: '6室以上'
  }
]
export const payTypeData: SelectData[] = [
  { value: '1', label: '月付' },
  { value: '2', label: '季付' },
  { value: '3', label: '年付' }
]
export const rentTypeData: SelectData[] = [
  { value: '1', label: '整租' },
  { value: '2', label: '合租' }
]
export const floorTypeStrData: SelectData[] = [
  { value: '1', label: '住宅' },
  { value: '2', label: '商铺' },
  { value: '3', label: '公寓' },
  { value: '4', label: '别墅' },
  { value: '5', label: '写字楼' },
  { value: '7', label: '洋房' },
  { value: '6', label: '其他' }
]
export const selectDecoration: SelectData[] = [
  { value: '1', label: '毛坯' },
  { value: '2', label: '简装' },
  { value: '3', label: '精装' }
]
export const selectDecoration2: SelectData[] = [
  { value: '毛坯', label: '毛坯' },
  { value: '简装', label: '简装' },
  { value: '精装', label: '精装' }
]
export const selectPropertyRight: SelectData[] = [
  { value: '1', label: '70年' },
  { value: '2', label: '50年' },
  { value: '3', label: '40年' }
]
export const selectOrientation: SelectData[] = [
  { value: '东', label: '东' },
  { value: '南', label: '南' },
  { value: '西', label: '西' },
  { value: '北', label: '北' },
  { value: '东南', label: '东南' },
  { value: '东北', label: '东北' },
  { value: '西南', label: '西南' },
  { value: '西北', label: '西北' }
]
export const selectElevator: SelectData[] = [
  { value: '无', label: '无' },
  { value: '有', label: '有' }
]
export const selectHouseUse: SelectData[] = [
  { value: '商品房住宅', label: '商品房住宅' },
  { value: '商住两用', label: '商住两用' },
  { value: '保障性住房', label: '保障性住房' },
  { value: '使用权', label: '使用权' },
  { value: '单位房', label: '单位房' },
  { value: '其他', label: '其他' },
  { value: '动迁配套房', label: '动迁配套房' }
]
export interface ColumnProps {
  id: number;
  title: string;
  avatar?: string;
  description: string;
}

export interface PostProps {
  id: number;
  title: string;
  content?: string;
  image?: string;
  createdAt: string;
  columnId: number;
}

export const testData: ColumnProps[] = [
  {
    id: 1,
    title: 'test1的专栏',
    description: '这是的test1专栏，有一段非常有意思的简介，可以更新一下欧, 这是的test1专栏，有一段非常有意思的简介，可以更新一下欧',
    avatar: 'http://yidouapp.oss-cn-shenzhen.aliyuncs.com/images/a8b3da2bd9e69fac16d7c2bd068a030f.jpg'
  },
  {
    id: 2,
    title: 'test2的专栏',
    description: '这是的test2专栏，有一段非常有意思的简介，可以更新一下欧',
    avatar: 'http://yidouapp.oss-cn-shenzhen.aliyuncs.com/images/a8b3da2bd9e69fac16d7c2bd068a030f.jpg'
  },
  {
    id: 3,
    title: 'test3的专栏',
    description: '这是的test1专栏，有一段非常有意思的简介，可以更新一下欧 这是的test1专栏，有一段非常有意思的简介，可以更新一下欧'
    // avatar: 'http://yidouapp.oss-cn-shenzhen.aliyuncs.com/images/a8b3da2bd9e69fac16d7c2bd068a030f.jpg'
  },
  {
    id: 4,
    title: 'test4的专栏',
    description: '这是的test2专栏，有一段非常有意思的简介，可以更新一下欧',
    avatar: 'http://yidouapp.oss-cn-shenzhen.aliyuncs.com/images/a8b3da2bd9e69fac16d7c2bd068a030f.jpg'
  }
]

export const testPosts: PostProps[] = [
  {
    id: 1,
    title: '这是我的第一篇文章',
    content: 'this is a new post you Very often we will need to map routes with the given pattern to the same component. For example we may have a User component which should be rendered for all users but with dif...',
    image: 'http://yidouapp.oss-cn-shenzhen.aliyuncs.com/images/a8b3da2bd9e69fac16d7c2bd068a030f.jpg',
    createdAt: '2020-06-11 10:34:22',
    columnId: 1
  },
  {
    id: 2,
    title: '这是我的第二篇文章',
    content: 'this is a new post you Very often we will need to map routes with the given pattern to the same component. For example we may have a User component which should be rendered for all users but with dif...',
    createdAt: '2020-06-11 10:34:22',
    columnId: 1
  },
  {
    id: 3,
    title: '这是我的第三篇文章',
    content: 'this is a new post you Very often we will need to map routes with the given pattern to the same component. For example we may have a User component which should be rendered for all users but with dif...',
    image: 'http://yidouapp.oss-cn-shenzhen.aliyuncs.com/images/a8b3da2bd9e69fac16d7c2bd068a030f.jpg',
    createdAt: '2020-06-11 10:34:22',
    columnId: 1
  }
]
