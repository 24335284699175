
  import { defineComponent, computed, watch, onUnmounted, toRaw } from 'vue'
  import { useStore } from 'vuex'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import GlobalHeader from '@/components/GlobalHeader.vue'
  import { UserProps } from './store'
  import Loader from '@/components/Loader.vue'
  import createMessage from '@/hooks/createMessage'

  export default defineComponent({
    name: 'App',
    components: {
      GlobalHeader,
      Loader
    },
    // watch: {
    //   $route(currentRoute: any) {
    //     const url = currentRoute.path
    //     console.log('url', url)
    //   }
    // },
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }
    },
    setup() {
      const store = useStore()
      const currentUser = computed(() => store.state.user)
      const isLoading = computed(() => store.state.loading)
      const error = computed(() => store.state.error)
      console.log('currentUser', toRaw(currentUser.value))
      watch(() => error.value.status, () => {
        const { status, message } = error.value
        if (status && message) {
          createMessage(message, 'error')
        }
      })
      onUnmounted(() => {
        console.log('onUnmounted')
      })
      return {
        currentUser,
        isLoading,
        error
      }
    }
  })
