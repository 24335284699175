<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #F6F6F6;">
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;" id="parent">
      <div class="d-flex flex-column w-100 position-relative" style="min-height: 700px;">
        <!-- 城市列表 -->
        <div class="d-flex flex-row align-items-center mt-5 ms-5"><span style="font-size: 24px;color: #333333;font-weight: bold;">选择所在城市</span></div>
        <a-input-search
          v-model:value="value"
          placeholder="搜索城市"
          enter-button="搜索"
          size="large"
          class="mt-3 ms-5"
          style="width: 600px;"
          @search="onSearch"
        />
        <div class="d-flex flex-column mt-4 ms-5">
          <span style="font-size: 16px;color: #333333;font-weight: bold;">热门城市</span>
          <div class="d-flex flex-row align-items-center flex-wrap mt-2">
            <router-link class="me-3 mt-2" style="font-size: 16px;color: #666;" @click="click_city(item)" to="#" v-for="(item,index) in hots" :key="index">{{item.cityName}}</router-link>
          </div>
        </div>
        <div class="d-flex flex-column mt-5 ms-5 mb-5">
          <span style="font-size: 16px;color: #333333;font-weight: bold;">全部城市</span>
          <div class="d-flex flex-column mt-4" v-for="(item,index) in list" :key="index">
            <span style="font-size: 24px;color: #a1a1a1;font-weight: bold;background-color: #f1f1f1;width: 30px;text-align: center;" :title="getId(index)">{{ getId(index) }}</span>
            <div class="d-flex flex-row align-items-center flex-wrap mt-2">
              <router-link class="me-3 mt-2" style="font-size: 16px;color: #666;" to="#" @click="click_city(co)" v-for="(co,ci) in item" :key="ci">{{co.cityName}}</router-link>
            </div>
          </div>
        </div>
        <!-- 导航 -->
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import { cityData } from '@/testData'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    name: 'SelectCity',
    setup() {
      onMounted(() => {
        // store.dispatch('userGetUserInfo')
      })
      const store = useStore()
      const router = useRouter()
      const hots = cityData.hotCity
      var mu = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'w', 'x', 'y', 'z']
      var tmp = []
      var letter = []
      var list = []
      for (var i = 0; i < mu.length; i++) {
        var item = mu[i]
        for (var j = 0; j < cityData.cities.length; j++) {
          var py = cityData.cities[j].py
          if (py.substring(0, 1) === item) {
            if (tmp.indexOf(item) === -1) {
              list[i] = [cityData.cities[j]]
              tmp.push(item)
              letter.push(item.toUpperCase())
            } else {
              list[i].push(cityData.cities[j])
            }
          }
        }
      }
      // console.log('letter', letter)
      // console.log('list', list)
      const getId = (index) => {
        return letter[index]
      }
      const click_city = (item) => {
        // console.log('click_city', item)
        localStorage.setItem('cityCode', item.code)
        localStorage.setItem('cityName', item.cityName)
        location.reload()
        // router.push('/')
      }
      return {
        hots,
        letter,
        getId,
        click_city,
        list
      }
    }
  })
</script>

<style scoped>

</style>
