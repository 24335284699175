
  import { defineComponent, PropType, computed } from 'vue'
  import { PostProps } from '../store'

  export default defineComponent({
    props: {
      list: {
        required: true,
        type: Array as PropType<PostProps[]>
      }
    },
    setup(props) {
      const posts = computed(() => {
        return props.list.map(post => {
          return post
        })
      })
      return {
        posts
      }
    }
  })
