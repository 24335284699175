<!--自定义公共head组件-->
<template>
  <!-- $route.meta.navShow 是否显示背景颜色 -->
  <nav class="navbar navbar-dark justify-content-between" style="min-width: 1150px;" :class="$route.meta.navShow ? 'bg-dark' : ''">
    <div class="d-flex align-items-center justify-content-center ms-5">
      <router-link class="navbar-brand ms-4" to="/">
        <img src="../assets/image/home_icon_logo_default.png" style="width: 159px;height: 45px;">
      </router-link>
      <div class="d-flex align-items-center justify-content-center px-1 mt-4" style="border: 1px solid #fff;border-radius: 14px;" @click="link_select_city">
        <img src="../assets/image/dingwei_bai@2x.png" style="width: 12px;height: 12px;">
        <div class="text-white ms-1" style="font-size: 12px;">{{cityName}}</div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <router-link class="navbar-brand ms-4 px-2" to="/">
        <div style="font-size: 18px;font-weight: normal">首页</div>
      </router-link>
      <router-link class="navbar-brand ms-4 px-2" to="/community_list.html">
        <div style="font-size: 18px;">新房</div>
      </router-link>
      <router-link class="navbar-brand ms-4 px-2" to="/secondhand_list.html">
        <div style="font-size: 18px;">二手房</div>
      </router-link>
      <router-link class="navbar-brand ms-4 px-2" to="/rent_list.html">
        <div style="font-size: 18px;">租房</div>
      </router-link>
      <a class="navbar-brand ms-4 px-2" href="http://yidoukeji.cn">
        <div style="font-size: 18px;">关于我们</div>
      </a>
      <a class="navbar-brand ms-4 px-2" href="https://yidouzhaofang.com/download/download.html">
        <div style="font-size: 18px;">下载APP</div>
      </a>
    </div>
    <div v-if="!user.isLogin" class="mb-0 me-4">
      <div class="d-flex align-items-center">
        <img src="../assets/image/weidenglu.png" style="width: 24px;height: 24px;">
        <router-link to="/login.html" class="navbar-brand my-2 ms-2" style="margin-right: 0!important;">
          <div style="font-size: 16px;">登录</div>
        </router-link>
        <div class="mx-1">/</div>
        <router-link to="/" class="navbar-brand my-2">
          <div style="font-size: 16px;">注册</div>
        </router-link>
      </div>
    </div>
    <ul v-else class="list-inline mb-0 me-4">
      <li class="list-inline-item">
        <dropdown :title="`您好 ${user.nick_name ? user.nick_name : '匿名用户'}`">
          <dropdown-item>
            <router-link to="/center.html" class="dropdown-item">会员中心</router-link>
          </dropdown-item>
          <dropdown-item disabled><a href="#" class="dropdown-item">修改密码</a></dropdown-item>
          <el-divider border-style="dashed" style="margin: 10px 0!important;"></el-divider>
          <dropdown-item><a href="#" class="dropdown-item" @click.prevent="exit">退出登录</a></dropdown-item>
        </dropdown>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
  import { defineComponent, PropType, watch } from 'vue'
  import Dropdown from '@/components/Dropdown.vue'
  import DropdownItem from '@/components/DropdownItem.vue'
  import store, { UserProps } from '../store'
  import createMessage from '@/hooks/createMessage'
  import router, { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'GlobalHeader',
    components: {
      Dropdown,
      DropdownItem
    },
    props: {
      user: {
        type: Object as PropType<UserProps>,
        required: true
      },
      is_bg: {
        type: String,
        default: 'other'
      }
    },
    setup() {
      let cityCode = localStorage.getItem('cityCode')
      let cityName = localStorage.getItem('cityName')
      if (!cityCode) {
        cityCode = '450100'
        cityName = '南宁'
        localStorage.setItem('cityCode', '450100')
        localStorage.setItem('cityName', '南宁')
      }
      const router = useRouter()
      const exit = () => {
        // console.log('exit', '退出登录')
        store.commit('outLogin', () => {
          createMessage('已退出登录', 'success')
          setTimeout(() => {
            // 跳转页面
            router.push('/')
          }, 500)
        })
      }
      const link_select_city = () => {
        router.push('/select_city.html')
      }
      return {
        exit,
        link_select_city,
        cityCode,
        cityName
      }
    }
  })
</script>

<style>
  nav{
    color: white!important;
  }
</style>
