// vuex 状态管理工具
import { createStore, Commit } from 'vuex'
import axios from 'axios'
import { CommunityBuildings, SecondHandHouse, RentHouse } from './model/bean'

export interface Common {
  id?: number;
  name?: string;
}

// export interface ResponseType<P = {}> {
//   code: number;
//   msg: string;
//   data: P;
// }
export interface ImageProps {
  _id?: string;
  url?: string;
  createdAt?: string;
  fitUrl?: string;
}

export interface UserProps {
  isLogin: boolean;
  nick_name?: string;
  mobile?: string;
  _id?: string;
  column?: string;
  email?: string;
  avatar?: ImageProps;
  description?: string;
  columnId?: number;
  releaseCount?: number;
}

export interface ColumnProps {
  id: number;
  title?: string;
  avatar?: ImageProps;
  description?: string;
  cover_pic?: string;
  name?: string;
  address?: string;
  share_description?: string
}

export interface PostProps {
  id?: number;
  title: string;
  excerpt?: string;
  content?: string;
  image?: ImageProps | string;
  createdAt?: string;
  column: string;
  author?: string | UserProps;
  isHTML?: boolean;
  name?: string;
  cover_pic?: string;
  area_str?: string;
  now_price?: string;
}

// 省市区信息
export interface Pca {
  province?: Common;
  city?: Common;
  area?: Common;
}

export interface Regions {
  name?: string;
  code?: string;
  pinyin?: string;
}

// interface ListProps<P> {
//   [id: string]: P;
// }

export interface GlobalErrorProps {
  status: boolean;
  message?: string;
}

// export interface GlobalDataProps {
//   token: string;
//   error: GlobalErrorProps;
//   loading: boolean;
//   columns: { data: ListProps<ColumnProps>; isLoaded: boolean; total: number };
//   posts: { data: ListProps<PostProps>; loadedColumns: string[] };
//   user: UserProps;
// }

export interface GlobalDataProps {
  error: GlobalErrorProps;
  columns: ColumnProps[];
  posts: PostProps[];
  user: UserProps;
  cityCode: string;
  cityName: string;
  column: ColumnProps;
  loading: boolean;
  token: string;
  discount_list: CommunityBuildings[]; // 特惠房源列表
  community_list: CommunityBuildings[]; // 新房列表
  secondhand_list: SecondHandHouse[]; // 二手房列表
  secondhand: SecondHandHouse; // 二手房详情
  rent_list: RentHouse[]; // 租房列表
  rent: RentHouse, // 租房详情
  community: CommunityBuildings, // 新房详情
  pca: Pca;// 省市区信息
  regions: Regions[]; // 省市区列表
}

export const postAndCommit = async (url: string, mutationName: string, param: URLSearchParams, commit: Commit) => {
  const { data } = await axios.post(url, param)
  commit(mutationName, data)
  return data
}

const store = createStore<GlobalDataProps>({
  state: {
    error: {
      status: false
    },
    token: localStorage.getItem('token') || '',
    cityCode: localStorage.getItem('cityCode') || '',
    cityName: localStorage.getItem('cityName') || '',
    loading: false,
    columns: [],
    column: {
      id: 0
    },
    posts: [],
    user: {
      isLogin: false
    },
    discount_list: [],
    community_list: [],
    secondhand_list: [],
    secondhand: { id: 0, name: '' },
    rent: { id: 0, name: '' },
    rent_list: [],
    community: { id: 0, name: '' },
    pca: {},
    regions: []
  },
  mutations: {
    // login(state) {
    //   state.user = { ...state.user, isLogin: true, nickName: 'shaka' }
    // },
    createPost(state, newPost) {
      state.posts.push(newPost)
    },
    fetchColumns(state, rawData) {
      state.columns = rawData.data
    },
    fetchColumn(state, rawData) {
      state.column = rawData.data
    },
    fetchPosts(state, rawData) {
      state.posts = rawData.data
    },
    setLoading(state, status) {
      state.loading = status
    },
    login(state, rawData) {
      const { token } = rawData.data
      state.token = token
      localStorage.setItem('token', token)
      localStorage.setItem('nickName', rawData.data.userInfo.nick_name)
      // console.log('nickName', rawData.data.userInfo.nick_name)
      // axios.defaults.headers.common.authorization = `${token}`
      // axios.defaults.headers.common.contentType = 'application/x-www-form-urlencoded'
    },
    setError(state, e: GlobalErrorProps) {
      state.error = e
    },
    fetchCurrentUser(state, rawData) {
      console.log('fetchCurrentUser', rawData.data)
      state.user = { isLogin: true, ...rawData.data }
    },
    saveLogin(state) {
      // console.log('saveLogin', '保持登录:' + localStorage.getItem('token') + ',' + localStorage.getItem('nickName'))
      if (localStorage.getItem('token')) {
        state.user.isLogin = true
        state.user.nick_name = localStorage.getItem('nickName') || '未知'
      }
    },
    outLogin(state, func) {
      if (localStorage.getItem('token')) {
        localStorage.setItem('token', '')
        localStorage.setItem('nickName', '')
        state.user.isLogin = false
        state.user.nick_name = ''
        func()
      }
    },
    // *********************
    // *********************
    // 读取首页数据返回
    fetchIndex(state, rawData) {
      state.discount_list = rawData.data.discount_list
      state.community_list = rawData.data.community_list
      state.secondhand_list = rawData.data.secondhand_list
      state.rent_list = rawData.data.rent_list
    },
    // 读取用户数据
    userGetUserInfo(state, rawData) {
      state.user = { isLogin: true, ...rawData.data }
    },
    // 读取所在省市区信息
    getPCAForCityCode(state, rawData) {
      state.pca = rawData.data
    },
    // 读取我的房源
    userMyRelease(state, rawData) {
      state.secondhand_list = rawData.data
      state.rent_list = rawData.data
    },
    // 读取二手房数据
    getsecondHandHouseDetail(state, rawData) {
      state.secondhand = rawData.data
    },
    // 读取二手房列表
    getSecondHouseList(state, rawData) {
      state.secondhand_list = rawData.data
    },
    // 读取租房数据
    getRentDetail(state, rawData) {
      state.rent = rawData.data
    },
    // 读取新房数据
    getCommunityDetail(state, rawData) {
      state.community = rawData.data
    },
    // 读取租房列表
    getRentList(state, rawData) {
      state.rent_list = rawData.data
    },
    // 读取附近的楼盘
    getNearbyCommunityList(state, rawData) {
      state.community_list = rawData.data
    },
    // 读取特惠房源列表
    getLimitCommunityList(state, rawData) {
      state.discount_list = rawData.data
    },
    // 读取城市的区域信息
    getAreaList(state, rawData) {
      state.regions = rawData.data
    },
    // 提交统一返回接收
    commonPost(state, rawData) {
      // console.log('commonPost', rawData)
    }
  },
  actions: {
    fetchColumns(context) {
      const param = new URLSearchParams()
      param.append('city_id', '451300')
      postAndCommit('community/getCommunityBuildingList', 'fetchColumns', param, context.commit)
      // axios.post('community/getCommunityBuildingList', param).then(resp => {
      //   console.log('community/getCommunityBuildingList', resp)
      //   context.commit('fetchColumns', resp.data)
      // })
    },
    fetchColumn({ commit }, cid) {
      const param = new URLSearchParams()
      param.append('id', cid)
      param.append('type', '10')
      postAndCommit('community/getBuildingDetail', 'fetchColumn', param, commit)
      // axios.post('community/getBuildingDetail', param).then(resp => {
      //   console.log('community/getBuildingDetail', resp.data)
      //   commit('fetchColumn', resp.data)
      // })
    },
    fetchPosts({ commit }) {
      const param = new URLSearchParams()
      param.append('city_id', '451300')
      postAndCommit('community/getRandCommunityList', 'fetchPosts', param, commit)
      // axios.post('community/getRandCommunityList', param).then(resp => {
      //   console.log('community/getRandCommunityList', resp)
      //   commit('fetchPosts', resp.data)
      // })
    },
    login({ commit }, payload) {
      const param = new URLSearchParams()
      param.append('mobile', payload.email)
      param.append('password', payload.password)
      return postAndCommit('user/login', 'login', param, commit)
    },
    fetchCurrentUser({ commit }) {
      const param = new URLSearchParams()
      postAndCommit('user/getUserInfo', 'fetchCurrentUser', param, commit)
    },
    loginAndFetch({ dispatch }, loginData) {
      return dispatch('login', loginData).then(() => {
        return dispatch('fetchCurrentUser')
      })
    },
    // *******************
    // *******************
    // 读取首页数据
    fetchIndex({ commit }, city_id) {
      const param = new URLSearchParams()
      param.append('city_id', city_id)
      postAndCommit('home/index_pc', 'fetchIndex', param, commit)
    },
    // 读取用户数据
    userGetUserInfo({ commit }) {
      const param = new URLSearchParams()
      postAndCommit('user/getUserInfo', 'userGetUserInfo', param, commit)
    },
    // 读取所在省市区信息
    getPCAForCityCode({ commit }, city_id) {
      const param = new URLSearchParams()
      param.append('cityCode', city_id)
      postAndCommit('home/getPCAForCityCode', 'getPCAForCityCode', param, commit)
    },
    // 读取我的房源
    userMyRelease({ commit }, data) {
      const param = new URLSearchParams()
      param.append('page', data.page)
      param.append('type', data.type)
      postAndCommit('user/myRelease', 'userMyRelease', param, commit)
    },
    // 上下架房源
    userUpDownHouseShow({ commit }, data) {
      const param = new URLSearchParams()
      param.append('house_id', data.id)
      param.append('type', data.type)
      postAndCommit('user/upDownHouseShow', 'commonPost', param, commit)
    },
    // 删除二手房
    delSecondHandHouse({ commit }, data) {
      const param = new URLSearchParams()
      param.append('id', data.id)
      postAndCommit('secondHandHouse/delHouse', 'commonPost', param, commit)
    },
    // 新增二手房
    sellHouse({ commit }, param) {
      const param1 = new URLSearchParams()
      for (const key in param) {
        param1.append(key, param[key])
      }
      return postAndCommit('secondHandHouse_v2/sellHouse', 'commonPost', param1, commit)
    },
    // 编辑二手房
    sellHouseEdit({ commit }, param) {
      const param1 = new URLSearchParams()
      for (const key in param) {
        param1.append(key, param[key])
      }
      return postAndCommit('secondHandHouse_v2/sellHouseEdit', 'commonPost', param1, commit)
    },
    // 读取二手房数据
    getsecondHandHouseDetail({ commit }, id) {
      const param = new URLSearchParams()
      param.append('id', id)
      return postAndCommit('secondHandHouse_v2/getHouseDetail', 'getsecondHandHouseDetail', param, commit)
    },
    // 读取更多二手房列表
    getRandESHouseList({ commit }, data) {
      const param = new URLSearchParams()
      param.append('city_id', data.city_id)
      param.append('community_id', data.community_id)
      return postAndCommit('secondHandHouse/getRandESHouseList', 'getSecondHouseList', param, commit)
    },
    // 读取二手房列表
    getHouseList({ commit }, data) {
      const param = new URLSearchParams()
      for (const key in data) {
        param.append(key, data[key])
      }
      return postAndCommit('secondHandHouse/getHouseList', 'getSecondHouseList', param, commit)
    },
    // 新增租房
    rentHouse({ commit }, param) {
      const param1 = new URLSearchParams()
      for (const key in param) {
        param1.append(key, param[key])
      }
      return postAndCommit('rent_v2/issueHouse', 'commonPost', param1, commit)
    },
    // 编辑租房
    sellRentEdit({ commit }, param) {
      const param1 = new URLSearchParams()
      for (const key in param) {
        param1.append(key, param[key])
      }
      return postAndCommit('rent_v2/issueHouseEdit', 'commonPost', param1, commit)
    },
    // 删除租房
    delRentHouse({ commit }, data) {
      const param = new URLSearchParams()
      param.append('id', data.id)
      postAndCommit('rent/delHouse', 'commonPost', param, commit)
    },
    // 读取租房数据
    getRentDetail({ commit }, id) {
      const param = new URLSearchParams()
      param.append('id', id)
      return postAndCommit('rent_v2/getHouseDetail', 'getRentDetail', param, commit)
    },
    // 读取更多租房列表
    getRandZFHouseList({ commit }, data) {
      const param = new URLSearchParams()
      param.append('city_id', data.city_id)
      param.append('community_id', data.community_id)
      return postAndCommit('rent/getRandZFHouseList', 'getRentList', param, commit)
    },
    // 读取租房列表
    getRentHouseList({ commit }, data) {
      const param = new URLSearchParams()
      for (const key in data) {
        param.append(key, data[key])
      }
      return postAndCommit('rent/getHouseList', 'getRentList', param, commit)
    },
    // 读取新房详情
    getCommunityDetail({ commit }, id) {
      const param = new URLSearchParams()
      param.append('id', id)
      return postAndCommit('community_v2/getBuildingDetail', 'getCommunityDetail', param, commit)
    },
    // 读取新房列表
    getCommunityBuildingList({ commit }, data) {
      const param = new URLSearchParams()
      for (const key in data) {
        param.append(key, data[key])
      }
      return postAndCommit('community/getCommunityBuildingList', 'getNearbyCommunityList', param, commit)
    },
    // 读取特惠房源列表
    getLimitCommunityList({ commit }, city_id) {
      const param = new URLSearchParams()
      param.append('city_id', city_id)
      return postAndCommit('home/get_limit_community_list', 'getLimitCommunityList', param, commit)
    },
    // 读取附近楼盘
    getNearbyCommunityList({ commit }, data) {
      const param = new URLSearchParams()
      param.append('city_id', data.city_id)
      param.append('building_id', data.building_id)
      return postAndCommit('community_v2/getNearbyCommunityList', 'getNearbyCommunityList', param, commit)
    },
    // 读取城市区域信息
    getAreaList({ commit }, city_id) {
      const param = new URLSearchParams()
      param.append('city_id', city_id)
      return postAndCommit('home/getAreaList', 'getAreaList', param, commit)
    }
  },
  getters: {
    getColumnById: (state) => (id: number) => {
      return state.column
    },
    getPostById: (state) => () => {
      return state.posts
    },
    getIndexDiscountList: (state) => () => {
      return state.discount_list
    }
  }
})

export default store
