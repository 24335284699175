<template>
  <teleport to="#message">
    <div class="alert message-info fixed-top w-50 mx-auto d-flex justify-content-between mt-2"
         :class="classObject" v-if="isVisible">
      <span>{{message}}</span>
      <button class="btn-close" aria-label="Close" @click.prevent="hide">
      </button>
    </div>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent, onUnmounted, PropType, ref } from 'vue'
  import useDOWCreate from '@/hooks/useDOWCreate'

  export type MessageType = 'success' | 'error' | 'default'

  export default defineComponent({
    name: 'message',
    props: {
      message: String,
      type: {
        type: String as PropType<MessageType>,
        default: 'default'
      }
    },
    setup(props, context) {
      // 在页面中加入节点实现瞬间移动
      useDOWCreate('message')

      const isVisible = ref(true)
      const classObject = {
        'alert-success': props.type === 'success',
        'alert-danger': props.type === 'error',
        'alert-primary': props.type === 'default'
      }
      const hide = () => {
        isVisible.value = false
        context.emit('close-message', true)
      }
      return {
        classObject,
        isVisible,
        hide
      }
    }
  })
</script>

<style scoped>

</style>
