<template>
  <div id="container" style="width: 100%;height: 585px;"></div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import AMapLoader from '@amap/amap-jsapi-loader'
  import { shallowRef } from '@vue/reactivity'
  export default defineComponent({
    name: 'MapContainer',
    props: {
      lat: {
        type: Number,
        required: true
      },
      lng: {
        type: Number,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      address: {
        type: String,
        required: true
      }
    },
    methods: {
      initMap() {
        AMapLoader.load({
          key: 'c4719d7128ccaa0ce4d4ee5d212c14e5', // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.PlaceSearch', 'AMap.Geolocation'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          var map = new AMap.Map('container', { // 设置地图容器id
            viewMode: '3D', // 是否为3D地图模式
            zoom: 17, // 初始化地图级别
            center: [this.lng, this.lat] // 初始化地图中心点位置
          })
          var content = '<div style="display: flex;flex-direction: column;width: 469px;align-items: center;">' +
            '<div style="display: flex;flex-direction: column;width: 100%;align-items: center;background-color: #fff;border-radius: 5px;padding: 5px 10px;height: 66px;">' +
            '<div style="display: flex;flex-direction: row;font-size: 20px;color: #333333;font-weight: bold;">' + this.title + '</div>' +
            '<div style="display: flex;flex-direction: row;font-size: 15px;color: #333333;">' + this.address + '</div>' +
            '</div>' +
            '<img src="http://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/map_down.png" style="width: 29px;height: 29px;margin-top: -3px;">' +
            '<img src="http://yidouapp.oss-cn-shenzhen.aliyuncs.com/tmp/xinfangxiangqing_icon_dingwei_default.png" style="width: 45px;height: 45px;margin-top: -3px;">' +
            '</div>'
          const marker = new AMap.Marker({
            position: [this.lng, this.lat],
            content: content,
            offset: new AMap.Pixel(-244, -122) // 相对于基点的偏移位置
          })
          map.add(marker)
          const placeSearch = new AMap.PlaceSearch({
            city: '防城港',
            citylimit: true, // 是否强制在设置的城市内搜索，默认false
            type: '餐饮服务', // 兴趣点类别，用‘|’分隔，默认：'餐饮服务|商务住宅|生活服务'
            pageSize: 20, // 每页条数，默认10，范围1-50
            pageIndex: 1, // 页码
            map: map
          }).searchNearBy('餐饮', [this.lng, this.lat], 2000, function (status: any, result: any) {
            console.log('地图实列', status)
            console.log('地图实列', result)
          })
          // console.log('placeSearch', placeSearch)
          // placeSearch.searchNearBy('餐饮', [this.lng, this.lat], 2000, function (status: any, result: any) {
          //   console.log('地图实列', result)
          // })
        }).catch(e => {
          console.log(e)
        })
      },
      searchNear(keywords: string, AMap:any) {
        const placeSearch = new AMap.PlaceSearch({
          citylimit: true, // 是否强制在设置的城市内搜索，默认false
          type: '地铁', // 兴趣点类别，用‘|’分隔，默认：'餐饮服务|商务住宅|生活服务'
          pageSize: 20, // 每页条数，默认10，范围1-50
          pageIndex: 1, // 页码
          extensions: 'all', // 默认base，返回基本地址信息；all：返回详细信息
          autoFitView: true // 是否自动调整地图视野到marker点都处于可见范围
        })
      }
    },
    mounted(): void {
      // DOM初始化完成进行地图初始化
      this.initMap()
    },
    watch: {
      title(newValue, oldValue) {
        console.log('newValue', newValue)
        console.log('oldValue', oldValue)
        this.initMap()
      }
    },
    setup() {
      const map = shallowRef(null)
      return {
        map
      }
    }
  })
</script>

<style scoped>
  #container{
    padding:0px;
    margin: 0px;
    width: 100%;
    height: 800px;
  }
</style>
