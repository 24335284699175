
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import createMessage from '@/hooks/createMessage'
  import moment, { Moment } from 'moment'
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
  import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
  import {
    houseTypeData,
    floorTypeStrData,
    selectDecoration,
    // selectPropertyRight,
    selectOrientation,
    selectElevator,
    selectHouseUse, SelectData
  } from '@/testData'
  import Uploader from '@/components/Uploader.vue'
  import axios from 'axios'
  import { useRoute, useRouter } from 'vue-router'

  export const imgsData: string[] = []
  export const imgstrOutsideData: string[] = []
  export const imgsHouseData: string[] = []

  interface imgState {
    imgs?: string[];
    imgs_outside?: string[];
    imgs_house?: string[];
  }
  interface FormState {
    id: number; // id
    title?: string; // 标题
    community_id?: string, // 小区id
    community_name?: string, // 小区名称
    building_area?: string, // 面积
    house_type?: string, // 户型
    property_type?: number, // 房屋类型
    decoration?: string, // 装修
    property_right?: number, // 产权
    orientation?: string, // 朝向
    elevator?: string, // 电梯
    total_price?: string, // 期望售价
    floor?: string, // 楼层
    all_floor?: string, // 总层
    proportion_str?: string, // 梯户比 - 梯
    proportion_str2?: string, // 梯户比 - 户
    on_date_str?: string, // 挂牌时间
    house_use?: string, // 房屋用途
    housing_years?: string, // 建成年代
    ownership?: string, // 产权所属
    mortgage_str?: string, // 抵押情况
    is_discounts?: number|boolean, // 优惠活动状态 0关闭 1开启
    discounts?: number, // 优惠金额
    discounts_time?: string, // 截止时间
    pic1?: string, // 室内图
    imgstr_outside?: string, // 室外图
    imgstr_house?: string // 户型图
  }

  let timeout: any
  let currentValue = ''
  function fetch(value: string, callback: any) {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value

    function fake() {
      let cityCode = localStorage.getItem('cityCode')
      if (!cityCode) {
        cityCode = '450100'
      }
      const param = new URLSearchParams()
      param.append('city_id', cityCode)
      param.append('name', value)
      axios.post('community/searchCommunityList', param, {}).then(resp => {
        // console.log('community/searchCommunityList', resp.data.data)
        const result = resp.data.data
        const data: any[] = []
        result.forEach((r: any) => {
          data.push({
            value: r.id,
            label: r.name
          })
        })
        callback(data)
      })
    }
    timeout = setTimeout(fake, 300)
  }

  export default defineComponent({
    name: 'SencondHandAdd',
    components: {
      Uploader
    },
    methods: {
      change() {
        this.$forceUpdate()
      },
      goBack() {
        this.$router.go(-1)
      }
    },
    setup(props) {
      const router = useRouter()
      const discounts_day = ref<Moment>()
      const route = useRoute()
      const id = +route.params.id
      // console.log('id', id)
      onMounted(() => {
        // 读取二手房数据
        store.dispatch('getsecondHandHouseDetail', id)
      })
      const secondhand = computed(() => store.state.secondhand)
      // console.log('secondhand', secondhand)
      setTimeout(() => {
        // console.log('secondhand', secondhand.value)
        formState.title = secondhand.value.name
        formState.community_id = secondhand.value.community_id
        formState.community_name = secondhand.value.community_name
        formState.building_area = secondhand.value.building_area
        formState.house_type = secondhand.value.house_type
        formState.property_type = secondhand.value.property_type_id
        formState.decoration = secondhand.value.decoration_str
        formState.property_right = secondhand.value.property_right
        formState.orientation = secondhand.value.orientation
        formState.elevator = secondhand.value.elevator_str
        formState.total_price = secondhand.value.total_price
        formState.floor = secondhand.value.floor
        formState.all_floor = secondhand.value.all_floor
        formState.proportion_str = secondhand.value.proportion_str
        if (secondhand.value.proportion_str2) {
          formState.proportion_str2 = secondhand.value.proportion_str2.replace('户', '')
        }
        formState.on_date_str = secondhand.value.on_date_str
        formState.house_use = secondhand.value.house_use
        formState.ownership = secondhand.value.ownership_str
        formState.housing_years = secondhand.value.housing_years
        formState.mortgage_str = secondhand.value.mortgage_str
        formState.is_discounts = secondhand.value.is_discounts === 1
        formState.discounts = secondhand.value.discounts
        formState.discounts_time = secondhand.value.discounts_time
        discounts_day.value = moment(secondhand.value.discounts_time)
        // if (secondhand.value.detail_albums) {
        //   secondhand.value.detail_albums.forEach((item: string) => {
        //     if (imgState.imgs) {
        //       imgState.imgs.push(item.replace('!syp1', ''))
        //     }
        //   })
        // }
        imgState.imgs = secondhand.value.detail_albums
        // if (secondhand.value.outside_albums) {
        //   secondhand.value.outside_albums.forEach((item: string) => {
        //     if (imgState.imgs_outside) {
        //       imgState.imgs_outside.push(item.replace('!syp1', ''))
        //     }
        //   })
        // }
        imgState.imgs_outside = secondhand.value.outside_albums
        // if (secondhand.value.house_albums) {
        //   secondhand.value.house_albums.forEach((item: string) => {
        //     if (imgState.imgs_house) {
        //       imgState.imgs_house.push(item.replace('!syp1', ''))
        //     }
        //   })
        // }
        imgState.imgs_house = secondhand.value.house_albums
      }, 1000)
      const datab = getCurrentInstance()
      const formRef = ref()
      const imgState:UnwrapRef<imgState> = {
        imgs: [],
        imgs_outside: [],
        imgs_house: []
      }
      const formState: UnwrapRef<FormState> = reactive({
        id: id,
        title: '', // 标题
        community_id: '', // 小区id
        community_name: '', // 小区名称
        building_area: '', // 面积
        house_type: '', // 户型
        // property_type: 0, // 房屋类型
        decoration: '', // 装修
        // property_right: 0, // 产权
        orientation: '', // 朝向
        elevator: '', // 电梯
        total_price: '', // 期望售价
        floor: '', // 楼层
        all_floor: '', // 总层
        proportion_str: '', // 梯户比 - 梯
        proportion_str2: '', // 梯户比 - 户
        on_date_str: '', // 挂牌时间
        house_use: '', // 房屋用途
        housing_years: '', // 建成年代
        ownership: '', // 产权所属
        mortgage_str: '', // 抵押情况
        is_discounts: 0, // 优惠活动状态 0关闭 1开启
        discounts: 0, // 优惠金额
        discounts_time: '', // 截止时间
        pic1: '', // 室内图
        imgstr_outside: '', // 室外图
        imgstr_house: '' // 户型图
      })
      const checkNumber = async (rule: RuleObject, value: number) => {
        if (!value) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('请输入内容')
        } else {
          return Promise.resolve()
        }
      }
      const rules = {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 8, max: 50, message: '必须输入8-50个字符的标题信息', trigger: 'blur' }
        ],
        community_id: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        building_area: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        house_type: [
          { required: true, message: '请选择户型', trigger: 'change' }
        ],
        property_type: [
          { required: true, validator: checkNumber, trigger: 'change' }
        ],
        decoration: [
          { required: true, message: '请选择装修情况', trigger: 'change' }
        ],
        property_right: [
          { required: true, validator: checkNumber, trigger: 'change' }
        ],
        orientation: [
          { required: true, message: '请选择房屋朝向', trigger: 'change' }
        ],
        elevator: [
          { required: true, message: '请选择电梯情况', trigger: 'change' }
        ],
        total_price: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        floor: [
          { required: true, message: '请输入楼层', trigger: 'blur' }
        ],
        all_floor: [
          { required: true, message: '请输入总层', trigger: 'blur' }
        ],
        proportion_str: [
          { required: true, message: '请输入梯户比', trigger: 'blur' }
        ],
        proportion_str2: [
          { required: true, message: '请输入梯户比', trigger: 'blur' }
        ],
        on_date_str: [
          { required: true, message: '请输入挂牌时间', trigger: 'blur' }
        ],
        house_use: [
          { required: true, message: '请输入梯户比', trigger: 'change' }
        ]
      }
      // 上传图片 - 室内图
      const beforeUpload = (file: File) => {
        // console.log('beforeUpload')
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJPG) {
          createMessage('上传图片只能是JPG或PNG格式！', 'error')
        }
        return isJPG
      }
      // 提交
      const onSubmit = () => {
        // 室内图
        let imgstr = ''
        if (imgState.imgs) {
          imgState.imgs.forEach((item:string) => {
            item = item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '')
            item = item.replace('!syp1', '')
            imgstr += item + ','
          })
          if (imgState.imgs.length < 3) {
            createMessage('室内图至少上传三张', 'error')
            return false
          }
        }
        if (imgstr.length > 0) {
          imgstr = imgstr.substr(0, imgstr.length - 1)
        }
        // console.log('室内图', imgstr)
        formState.pic1 = imgstr
        // 室外图
        let imgOutstr = ''
        if (imgState.imgs_outside) {
          imgState.imgs_outside.forEach((item: string) => {
            item = item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '')
            item = item.replace('!syp1', '')
            imgOutstr += item + ','
          })
          if (imgState.imgs_outside.length < 1) {
            createMessage('室外图至少上传一张', 'error')
            return false
          }
        }
        if (imgOutstr.length > 0) {
          imgOutstr = imgOutstr.substr(0, imgOutstr.length - 1)
        }
        // console.log('室外图', imgOutstr)
        formState.imgstr_outside = imgOutstr
        // 户型图
        let imghousestr = ''
        if (imgState.imgs_house) {
          imgState.imgs_house.forEach((item:string) => {
            item = item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '')
            item = item.replace('!syp1', '')
            imghousestr += item + ','
          })
          if (imgState.imgs_house.length < 1) {
            createMessage('户型图至少上传一张', 'error')
            return false
          }
        }
        if (imghousestr.length > 0) {
          imghousestr = imghousestr.substr(0, imghousestr.length - 1)
        }
        // console.log('户型图', imghousestr)
        formState.imgstr_house = imghousestr
        // 表单验证
        // console.log('submit', formState)
        formRef.value.validate().then(() => {
          if (formState.is_discounts) {
            formState.is_discounts = 1
          } else {
            formState.is_discounts = 0
          }
          formState.discounts_time = discounts_day.value?.format('YYYY-MM-DD HH:mm:ss')
          // console.log('values', formState, toRaw(formState))
          // 提交表单
          store.dispatch('sellHouseEdit', toRaw(formState)).then(data => {
            // console.log('login', data)
            createMessage('提交成功', 'success')
            setTimeout(() => {
              // 跳转页面
              router.push('/house_publish.html')
            }, 500)
          }).catch(e => {
            // console.log('onFormSubmit', e)
          })
        }).catch((error: ValidateErrorEntity<FormState>) => {
          console.log('error', error)
          createMessage('请按照要求填写完整的表单信息', 'error')
        })
      }
      const data = ref<any[]>([])
      const handleSearch = (val: string) => {
        // console.log('handleSearch', val)
        fetch(val, (d: any[]) => (data.value = d))
      }
      const handleChange = (val: string) => {
        // console.log('handleChange', val)
        formState.community_id = val
        fetch(val, (d: any[]) => (data.value = d))
      }
      const finishUpload2 = (img: string) => {
        // console.log('finishUpload', img)
        if (imgState && imgState.imgs_outside) {
          imgState.imgs_outside.push(img)
        }
        // console.log('finishUpload', imgState?.imgs_outside)
      }
      const del2 = (index:number) => {
        // console.log('删除', index)
        if (imgState && imgState.imgs_outside) {
          imgState.imgs_outside.splice(index, 1)
        }
      }
      const finishUpload3 = (img: string) => {
        // console.log('finishUpload', img)
        if (imgState && imgState.imgs_house) {
          imgState.imgs_house.push(img)
        }
      }
      const del3 = (index:number) => {
        // console.log('删除', index)
        if (imgState && imgState.imgs_house) {
          imgState.imgs_house.splice(index, 1)
        }
      }
      const finishUpload = (img: string) => {
        // console.log('finishUpload', img)
        if (imgState && imgState.imgs) {
          imgState.imgs.push(img)
        }
      }
      const del = (index:number) => {
        // console.log('删除', index)
        if (imgState && imgState.imgs) {
          imgState.imgs.splice(index, 1)
        }
      }
      return {
        labelCol: { span: 2 },
        locale,
        houseTypeData: houseTypeData,
        floorTypeStrData: [
          { value: 1, label: '住宅' },
          { value: 2, label: '商铺' },
          { value: 3, label: '公寓' },
          { value: 4, label: '别墅' },
          { value: 5, label: '写字楼' },
          { value: 6, label: '洋房' },
          { value: 7, label: '其他' }
        ],
        selectDecoration: selectDecoration,
        selectPropertyRight: [
          { value: 1, label: '70年' },
          { value: 2, label: '50年' },
          { value: 3, label: '40年' }
        ],
        selectOrientation: selectOrientation,
        selectElevator: selectElevator,
        selectHouseUse: selectHouseUse,
        discounts_day, // 优惠活动截止时间
        beforeUpload,
        onSubmit,
        formRef,
        rules,
        form: formState,
        handleSearch,
        handleChange,
        data,
        finishUpload,
        finishUpload2,
        finishUpload3,
        del,
        del2,
        del3,
        imgstate: imgState
      }
    }
  })
