<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #ffffff;">
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex flex-row align-items-center justify-content-between mt-3">
          <a-breadcrumb>
            <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
            <a-breadcrumb-item>新房</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="d-flex flex-row align-items-center me-3">
            <a-input-search
              v-model:value="formState.name"
              placeholder="请输入楼盘信息"
              size="small"
              class="mt-3 ms-5"
              style="width: 348px;"
              @search="onSreach"
            >
              <template #enterButton>
                <a-button style="background-color: #FF9326;color: white;width: 94px;">搜索</a-button>
              </template>
            </a-input-search>
          </div>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 34px;color: #333333;font-weight: bold;">精选新房</span>
          <span style="font-size: 13px;color: #333333;font-weight: bold;">SELECT NEW HOUSE</span>
        </div>
        <div class="d-flex flex-column mt-3" style="background-color: #F9F9F9;min-height: 200px;padding: 20px;">
          <div class="d-flex flex-row align-items-center">
            <span style="width: 80px;font-size: 15px;color: #333333;font-weight: bold;">位置</span>
            <div class="d-flex flex-row align-items-center">
              <span style="font-size: 15px;color: #FF9326;margin-right: 8px;">按区域</span>
              <img src="../../assets/image/xinfangliebiao_icon_up_default.png" style="width: 13px;height: 13px;">
            </div>
          </div>
          <a-divider />
          <div class="d-flex flex-row align-items-center">
            <span style="width: 80px;font-size: 17px;color: #333333;"></span>
            <div class="d-flex flex-column align-items-center justify-content-center me-3" v-for="(item,index) in options_area" :key="index" @click="select_area(index)">
              <span style="font-size: 15px;margin-bottom: 2px;" :style="formState.area_index == index ? 'color: #FF9326;' : 'color: #999;'">{{item.name}}</span>
              <div style="width: 30px;background-color: #FF9326" :style="formState.area_index == index ? 'height: 2px;' : 'height: 0px;'"></div>
            </div>
          </div>
          <a-divider />
          <div class="d-flex flex-row align-items-center">
            <span style="width: 80px;font-size: 15px;color: #333333;font-weight: bold;">均价</span>
            <div class="d-flex flex-row align-items-center">
              <a-radio-group :options="options_average" v-model:value="formState.average_price_index" />
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2">
            <span style="width: 80px;font-size: 15px;color: #333333;font-weight: bold;">类型</span>
            <div class="d-flex flex-row align-items-center">
              <a-radio-group :options="options_floor_type" v-model:value="formState.floor_type_index" />
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2">
            <span style="width: 80px;font-size: 15px;color: #333333;font-weight: bold;">户型</span>
            <div class="d-flex flex-row align-items-center">
              <a-radio-group :options="options_house_type" v-model:value="formState.house_type_index" />
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2">
            <span style="width: 80px;font-size: 15px;color: #333333;font-weight: bold;">装修</span>
            <div class="d-flex flex-row align-items-center">
              <a-radio-group :options="options_decorate" v-model:value="formState.decorate" />
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2">
            <span style="width: 80px;font-size: 15px;color: #333333;font-weight: bold;">价格</span>
            <div class="d-flex flex-row align-items-center">
              <a-radio-group :options="options_order" v-model:value="formState.order" />
            </div>
          </div>
          <a-button type="primary" @click="onSreach" size="small" style="width: 100px;margin-top: 20px;margin-left: 80px;">提交</a-button>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column flex-grow-1" style="margin-top: 50px;margin-bottom: 50px;" v-if="list.length > 0">
            <div class="d-flex flex-column" v-for="(item,index) in list" :key="index">
              <a :href="`/community_detail.html/${item.id}`">
              <div class="d-flex flex-row align-items-center">
                <img :src="item.cover_pic" style="width: 253px;height: 169px;border-radius: 5px;object-fit: cover">
                <div class="d-flex flex-column justify-content-center ms-3 h-100 flex-grow-1">
                  <div class="d-flex flex-row align-items-center">
                    <span style="font-size: 23px;color: #333333;font-weight: bold;">{{item.name}}</span>
                    <span style="font-size: 15px;color: white;padding: 1px 10px;border-radius: 5px;background-color: #FD6769;margin-left: 15px;">{{item.status_str}}</span>
                    <span style="font-size: 15px;color: white;padding: 1px 10px;border-radius: 5px;background-color: #6392FF;margin-left: 5px;">{{item.property_type_str}}</span>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-2">
                    <span style="font-size: 13px;color: #96AFC0;background-color: #F2F5F7;padding: 1px 10px;border-radius: 5px;margin-right: 10px;"
                          v-for="(item,index) in item.feature_name" :key="index" >{{item}}</span>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-2">
                    <span style="font-size: 18px;color: #999;">{{item.address}}</span>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-2">
                    <span style="font-size: 22px;color: #FF9326;font-weight: bold;">{{item.average_price ? item.average_price.replace('元/㎡','') : '价格未知'}} 元/㎡</span>
                  </div>
                </div>
              </div>
              </a>
              <a-divider />
            </div>
            <div class="d-flex flex-row align-items-center w-100 justify-content-end">
              <a-button @click="goBack" v-if="formState.page !== 1">上一页</a-button>
              <div style="font-size: 18px;color: #666;margin: 0 20px;">第{{formState.page}}页</div>
              <a-button @click="goNext" v-if="formState.nextpage">下一页</a-button>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center flex-grow-1" style="min-height: 500px;margin-top: 50px;margin-bottom: 50px;" v-if="list.length === 0">
            <img src="../../assets/sreach.png" style="width: 300px;">
            <span style="font-size: 28px;color: #999999;">暂无数据</span>
          </div>
          <div class="d-flex flex-column" style="min-width: 188px;margin-top: 40px;margin-left: 50px;">
            <span style="font-size: 19px;color: #333333;font-weight: bold;margin-bottom: 20px;">特惠房源</span>
            <div class="d-flex flex-column mb-2" v-for="(item,index) in discount_list" :key="index">
              <a :href="`/community_detail.html/${item.id}`">
              <img :src="item.cover_pic" style="width: 188px;height: 188px;">
              </a>
              <span style="font-size: 15px;color: #333;font-weight: bold;margin-top: 10px;">{{item.name}}</span>
              <span style="font-size: 15px;color: #FF9326;font-weight: bold;">{{item.average_price ? item.average_price.replace('元/㎡','') : '价格未知'}} 元/㎡</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    onMounted,
    ref,
    PropType,
    UnwrapRef,
    reactive,
    toRaw,
    getCurrentInstance,
    watch
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import store from '@/store'
  interface FormState {
    page?: number, // 分页
    city_id?: number|string|null, // 城市id
    area_id?: string, // 区域id
    area_index: number, // 区域序号
    name?: string, // 关键字
    house_type?: number, // 户型
    house_type_index?: number, // 户型序号
    floor_type?: number, // 房屋类型
    floor_type_index?: number, // 房屋类型序号
    average_price_min?: string, // 均价下限
    average_price_max?: string, // 均价上限
    average_price_index?: number, // 均价序号
    order?: number, // 价格排序
    decorate?: number // 装修排序
    nextpage?: boolean // 下一页
  }
  export default defineComponent({
    name: 'CommunityList',
    setup() {
      const route = useRoute()
      // console.log('params', route.params)
      onMounted(() => {
        // 读取城市区域信息
        store.dispatch('getAreaList', city_id)
        // 读取新房列表
        store.dispatch('getCommunityBuildingList', toRaw(formState))
        // 读取特惠房源
        store.dispatch('getLimitCommunityList', city_id)
      })
      const city_id:string|null = localStorage.getItem('cityCode')
      const formState: UnwrapRef<FormState> = reactive({
        page: 1,
        city_id: city_id,
        area_id: '',
        area_index: 0,
        name: '',
        house_type: 0,
        house_type_index: 0,
        floor_type: 0,
        floor_type_index: 0,
        average_price_min: '',
        average_price_max: '',
        average_price_index: 0,
        order: 0,
        decorate: 0,
        nextpage: true
      })
      if (route.params) {
        const keywords:any = route.params.keywords
        // console.log('keywords', keywords)
        formState.name = keywords || ''
      }
      // 区域
      const options_area = computed(() => store.state.regions)
      const select_area = (index:number) => {
        formState.area_index = index
      }
      // 均价
      const options_average = [
        { label: '不限', value: 0 },
        { label: '1万以下', value: 1 },
        { label: '1万-1.5万', value: 2 },
        { label: '1.5万-2万', value: 3 },
        { label: '2万-5万', value: 4 },
        { label: '5万以上', value: 5 }
      ]
      // 类型
      const options_floor_type = [
        { label: '不限', value: 0 },
        { label: '住宅', value: 1 },
        { label: '商铺', value: 2 },
        { label: '公寓', value: 3 },
        { label: '别墅', value: 4 },
        { label: '写字楼', value: 5 },
        { label: '其他', value: 6 },
        { label: '洋房', value: 7 }
      ]
      // 户型
      const options_house_type = [
        { label: '不限', value: 0 },
        { label: '一房', value: 1 },
        { label: '二房', value: 2 },
        { label: '三房', value: 3 },
        { label: '四房', value: 4 },
        { label: '其他', value: 5 }
      ]
      // 价格排序
      const options_order = [
        { label: '默认', value: 0 },
        { label: '价格从低到高', value: 1 },
        { label: '价格从高到低', value: 2 }
      ]
      // 装修情况
      const options_decorate = [
        { label: '不限', value: 0 },
        { label: '毛坯', value: 1 },
        { label: '简装', value: 2 },
        { label: '精装', value: 3 }
      ]
      const list = computed(() => store.state.community_list)
      const goBack = () => {
        if (formState.page) {
          formState.page = formState.page - 1
          if (formState.page < 1) {
            formState.page = 1
          }
        }
        store.dispatch('getCommunityBuildingList', toRaw(formState))
      }
      const goNext = () => {
        if (formState.page) {
          if (formState.nextpage) {
            formState.page = formState.page + 1
          }
        }
        store.dispatch('getCommunityBuildingList', toRaw(formState))
      }
      watch(list, () => {
        if (list.value.length === 10) {
          formState.nextpage = true
        } else {
          formState.nextpage = false
        }
      })
      const onSreach = (searchValue: string) => {
        // console.log('搜索', searchValue)
        formState.area_id = options_area.value[formState.area_index].code
        switch (formState.average_price_index) {
          case 0:
            formState.average_price_min = ''
            formState.average_price_max = ''
            break
          case 1:
            formState.average_price_min = '0'
            formState.average_price_max = '10000'
            break
          case 2:
            formState.average_price_min = '10000'
            formState.average_price_max = '15000'
            break
          case 3:
            formState.average_price_min = '15000'
            formState.average_price_max = '20000'
            break
          case 4:
            formState.average_price_min = '20000'
            formState.average_price_max = '50000'
            break
          case 5:
            formState.average_price_min = '50000'
            formState.average_price_max = ''
            break
        }
        formState.floor_type = formState.floor_type_index
        formState.house_type = formState.house_type_index
        store.dispatch('getCommunityBuildingList', toRaw(formState))
      }
      const discount_list = computed(() => store.state.discount_list)
      return {
        formState,
        options_area,
        select_area,
        options_average,
        options_floor_type,
        options_house_type,
        options_order,
        options_decorate,
        list,
        discount_list,
        goBack,
        goNext,
        onSreach
      }
    }
  })
</script>

<style scoped>

</style>
