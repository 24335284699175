<template>
  <div class="column-detail-page w-75 mx-auto">
    <div class="column-info row mb-4 border-bottom pb-4 align-items-center" v-if="column">
      <div class="col-3 text-center">
        <img :src="column.cover_pic" :alt="column.name" class="rounded-circle border w-100">
      </div>
      <div class="col-9">
        <h4>{{column.name}}</h4>
        <p class="text-muted">{{column.share_description}}</p>
      </div>
    </div>
    <post-list :list="list"></post-list>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import PostList from '../components/PostList.vue'

  export default defineComponent({
    name: 'ColumnDetail',
    components: {
      PostList
    },
    setup() {
      const route = useRoute()
      const currentId = +route.params.id // + 转成number
      onMounted(() => {
        store.dispatch('fetchColumn', currentId)
        store.dispatch('fetchPosts')
      })
      // 从数组中找到条件相等的元素返回
      // find只查询第一个符合条件的成员返回
      // filter返回符合条件的所有成员
      // const column = testData.find(c => c.id === currentId)
      // const list = testPosts.filter(post => post.columnId === currentId)
      const store = useStore()
      const column = computed(() => store.getters.getColumnById(currentId))
      const list = computed(() => store.getters.getPostById())
      console.log('list2', list)
      return {
        route,
        column,
        list
      }
    }
  })
</script>

<style scoped>

</style>
