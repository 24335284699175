<template>
  <li
    class="dropdown-option"
    :class="{'is-disabled':disabled}">
    <slot></slot>
  </li>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DropdownItem',
    props: {
      disabled: {
        type: Boolean,
        requried: true
      }
    }
  })
</script>

<style>
  .dropdown-option.is-disabled * {
    color: #C1C1C1 !important;
    pointer-events: none;
    background-color: transparent;
  }
</style>
