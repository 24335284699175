
  import { defineComponent, reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import ValidateInput, { RulesProp } from '@/components/ValidateInput.vue'
  import ValidateForm from '@/components/ValidateForm.vue'
  import createMessage from '@/hooks/createMessage'

  const emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/

  export default defineComponent({
    name: 'App',
    components: {
      ValidateInput,
      ValidateForm
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const inputRef = ref<any>()
      const emailValue = ref('')
      const passwordValue = ref('')
      const emailRules: RulesProp = [
        { type: 'required', message: '手机号不能为空' },
        { type: 'phone', message: '手机号格式不正确' }
      ]
      const passwordRules: RulesProp = [
        { type: 'required', message: '密码不能为空' }
      ]
      const emailRef = reactive({
        val: '',
        error: false,
        message: ''
      })
      const validateEmail = () => {
        if (emailRef.val.trim() === '') {
          emailRef.error = true
          emailRef.message = '邮箱不能为空'
        } else if (!emailReg.test(emailRef.val)) {
          emailRef.error = true
          emailRef.message = '邮箱格式非法'
        }
      }
      const onFormSubmit = (result: boolean) => {
        // console.log('onFormSubmit', result)
        if (result) {
          const payload = {
            email: emailValue.value,
            password: passwordValue.value
          }
          store.dispatch('loginAndFetch', payload).then(data => {
            // console.log('login', data)
            createMessage('登录成功,2秒后跳转首页', 'success')
            setTimeout(() => {
              // 跳转页面
              // router.push({ name: 'column', params: { id: 1 } })
              router.push('/center.html')
            }, 500)
          }).catch(e => {
            // console.log('onFormSubmit', e)
          })
        }
      }
      return {
        emailRef,
        validateEmail,
        emailRules,
        emailValue,
        passwordRules,
        passwordValue,
        onFormSubmit,
        inputRef
      }
    }
  })
