<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #F6F6F6;">
    <div class="d-flex flex-row align-items-center mt-3 mb-3" style="width: 1150px;">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/center.html">个人中心</a></a-breadcrumb-item>
        <a-breadcrumb-item>我要卖房</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex flex-row align-items-center justify-content-between" style="margin: 60px 80px 0px 80px;">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/image/form_tag_1.png" style="width: 40px;height: 40px;">
            <span style="font-size: 20px;font-weight: normal;color: #FF9326;margin-top: 10px;">填写信息</span>
          </div>
          <div style="height: 1px;flex: 1;background-color: #FF9326;margin: 0 20px;"></div>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/image/form_tag_2.png" style="width: 40px;height: 40px;">
            <span style="font-size: 20px;font-weight: normal;color: #FF9326;margin-top: 10px;">审核认证</span>
          </div>
          <div style="height: 1px;flex: 1;background-color: #FF9326;margin: 0 20px;"></div>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/image/form_tag_3.png" style="width: 40px;height: 40px;">
            <span style="font-size: 20px;font-weight: normal;color: #FF9326;margin-top: 10px;">房产展示</span>
          </div>
        </div>
        <a-form ref="formRef" :model="form" label-width="120px" label-position="left" :label-col="labelCol" :rules="rules"
                style="margin: 60px 80px;">
          <a-form-item label="标题" name="title">
            <a-input v-model:value="form.title" placeholder="例如:小区名称+装修情况+户型+特点" maxlength="50" show-word-limit
                     type="text" style="width: 800px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="小区" name="community_id">
            <a-select
              v-model:value="form.community_id"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="data"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
            ></a-select>
          </a-form-item>
          <a-form-item label="面积" name="building_area">
            <a-input-number :min="1" :max="999" v-model:value="form.building_area" placeholder="请输入面积" maxlength="5"
                            show-word-limit type="text"
                            style="width: 300px;margin-left: 10px;"></a-input-number>
            <span style="font-size: 14px;color: #333333;margin-left: 10px;">㎡</span>
          </a-form-item>
          <a-form-item label="户型" name="house_type">
            <a-select
              v-model:value="form.house_type"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="houseTypeData"
            ></a-select>
          </a-form-item>
          <a-form-item label="房屋类型" name="property_type">
            <a-select
              v-model:value="form.property_type"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="floorTypeStrData"
            ></a-select>
          </a-form-item>
          <a-form-item label="装修" name="decoration">
            <a-select
              v-model:value="form.decoration"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectDecoration"
            ></a-select>
          </a-form-item>
          <a-form-item label="产权" name="property_right">
            <a-select
              v-model:value="form.property_right"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectPropertyRight"
            ></a-select>
          </a-form-item>
          <a-form-item label="朝向" name="orientation">
            <a-select
              v-model:value="form.orientation"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectOrientation"
            ></a-select>
          </a-form-item>
          <a-form-item label="电梯" name="elevator">
            <a-select
              v-model:value="form.elevator"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectElevator"
            ></a-select>
          </a-form-item>
          <a-form-item label="期望售价" name="total_price">
            <a-input-number :min="1" :max="999" v-model:value="form.total_price" placeholder="请输入售价" maxlength="7"
                            show-word-limit type="text"
                            style="width: 300px;margin-left: 10px;"></a-input-number>
            <span style="font-size: 14px;color: #333333;margin-left: 10px;">万元</span>
          </a-form-item>
          <a-divider>补充信息</a-divider>
          <a-form-item label="楼层" name="floor">
            <a-input-group style="margin-left: 10px;" compact>
              <a-input style="width: 150px;" v-model:value="form.floor" maxlength="3" suffix="楼"/>
              <a-input style="width: 150px;" v-model:value="form.all_floor" maxlength="3" suffix="总层"/>
            </a-input-group>
          </a-form-item>
          <a-form-item label="梯户比" name="proportion_str">
            <a-input-group style="margin-left: 10px;" compact>
              <a-input style="width: 150px;" v-model:value="form.proportion_str" maxlength="2" suffix="梯"/>
              <a-input style="width: 150px;" v-model:value="form.proportion_str2" maxlength="2" suffix="户"/>
            </a-input-group>
          </a-form-item>
          <a-form-item label="挂牌时间" name="on_date_str">
            <a-input v-model:value="form.on_date_str" placeholder="请输入挂牌时间" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="房屋用途" name="house_use">
            <a-select
              v-model:value="form.house_use"
              show-search
              style="width: 300px;margin-left: 10px;"
              :options="selectHouseUse"
            ></a-select>
          </a-form-item>
          <a-form-item label="建成年代">
            <a-input v-model:value="form.housing_years" placeholder="请输入建成年代" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="产权所属">
            <a-input v-model:value="form.ownership" placeholder="请输入产权所属" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-form-item label="抵押情况">
            <a-input v-model:value="form.mortgage_str" placeholder="请输入抵押情况" maxlength="10" show-word-limit
                     type="text" style="width: 300px;margin-left: 10px;"></a-input>
          </a-form-item>
          <a-divider>优惠活动</a-divider>
          <a-form-item label="优惠活动">
            <a-switch v-model:checked="form.is_discounts" style="width: 100px;margin-left: 10px;"/>
          </a-form-item>
          <a-form-item label="优惠金额" v-if="form.is_discounts">
            <a-input-number :min="0" :max="99999" v-model:value="form.discounts" placeholder="请输入金额" maxlength="7"
                            show-word-limit type="text"
                            style="width: 300px;margin-left: 10px;"></a-input-number>
            <span style="font-size: 14px;color: #333333;margin-left: 10px;">元</span>
          </a-form-item>
          <a-form-item label="截止时间" v-if="form.is_discounts">
            <a-date-picker :locale="locale" v-model:value="discounts_day"
                           style="width: 300px;margin-left: 10px;"/>
          </a-form-item>
          <a-divider>室内图上传（至少上传三张,第一张将作为封面图）</a-divider>
          <div class="d-flex flex-row align-items-center flex-wrap">
            <div style="width: 113px;height: 133px" class="me-3 mt-3 position-relative d-flex flex-column align-items-center" v-for="(item,index) in imgs" :key="index">
              <a-image :src="item" style="width: 113px;height: 113px;object-fit: cover"/>
              <span @click.prevent="del(index)">删除</span>
            </div>
            <uploader action="/upload" class="mt-3" style="margin-bottom: 21px;" :before-upload="beforeUpload" :finish-upload="finishUpload"></uploader>
          </div>
          <a-divider>室外图上传（至少上传一张）</a-divider>
          <div class="d-flex flex-row align-items-center flex-wrap">
            <div style="width: 113px;height: 133px" class="me-3 mt-3 position-relative d-flex flex-column align-items-center" v-for="(item,index) in imgs_outside" :key="index">
              <a-image :src="item" style="width: 113px;height: 113px;object-fit: cover"/>
              <span @click.prevent="del2(index)">删除</span>
            </div>
            <uploader action="/upload" class="mt-3" style="margin-bottom: 21px;" :before-upload="beforeUpload" :finish-upload="finishUpload2"></uploader>
          </div>
          <a-divider>户型图上传（至少上传一张）</a-divider>
          <div class="d-flex flex-row align-items-center flex-wrap">
            <div style="width: 113px;height: 133px" class="me-3 mt-3 position-relative d-flex flex-column align-items-center" v-for="(item,index) in imgs_house" :key="index">
              <a-image :src="item" style="width: 113px;height: 113px;object-fit: cover"/>
              <span @click.prevent="del3(index)">删除</span>
            </div>
            <uploader action="/upload" class="mt-3" style="margin-bottom: 21px;" :before-upload="beforeUpload" :finish-upload="finishUpload3"></uploader>
          </div>
          <a-divider/>
          <a-form-item>
            <a-button type="primary" @click="onSubmit">提交审核</a-button>
            <a-button @click="goBack">返回上一步</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import createMessage from '@/hooks/createMessage'
  import { Moment } from 'moment'
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
  import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
  import {
    houseTypeData,
    floorTypeStrData,
    selectDecoration,
    selectPropertyRight,
    selectOrientation,
    selectElevator,
    selectHouseUse, SelectData
  } from '@/testData'
  import Uploader from '@/components/Uploader.vue'
  import axios from 'axios'
  import { useRouter } from 'vue-router'

  export const imgsData: string[] = []
  export const imgstrOutsideData: string[] = []
  export const imgsHouseData: string[] = []

  interface FormState {
    title?: string; // 标题
    community_id?: string, // 小区名称
    building_area?: string, // 面积
    house_type?: string, // 户型
    property_type?: string, // 房屋类型
    decoration?: string, // 装修
    property_right?: string, // 产权
    orientation?: string, // 朝向
    elevator?: string, // 电梯
    total_price?: string, // 期望售价
    floor?: string, // 楼层
    all_floor?: string, // 总层
    proportion_str?: string, // 梯户比 - 梯
    proportion_str2?: string, // 梯户比 - 户
    on_date_str?: string, // 挂牌时间
    house_use?: string, // 房屋用途
    housing_years?: string, // 建成年代
    ownership?: string, // 产权所属
    mortgage_str?: string, // 抵押情况
    is_discounts?: number, // 优惠活动状态 0关闭 1开启
    discounts?: number, // 优惠金额
    discounts_time?: string, // 截止时间
    pic1?: string, // 室内图
    imgstr_outside?: string, // 室外图
    imgstr_house?: string // 户型图
  }

  let timeout: any
  let currentValue = ''
  function fetch(value: string, callback: any) {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value

    function fake() {
      let cityCode = localStorage.getItem('cityCode')
      if (!cityCode) {
        cityCode = '450100'
      }
      const param = new URLSearchParams()
      param.append('city_id', cityCode)
      param.append('name', value)
      axios.post('community/searchCommunityList', param, {}).then(resp => {
        // console.log('community/searchCommunityList', resp.data.data)
        const result = resp.data.data
        const data: any[] = []
        result.forEach((r: any) => {
          data.push({
            value: r.id,
            label: r.name
          })
        })
        callback(data)
      })
    }
    timeout = setTimeout(fake, 300)
  }

  export default defineComponent({
    name: 'SencondHandAdd',
    components: {
      Uploader
    },
    data() {
      return {
        labelCol: { span: 2 },
        locale,
        title: ref('123'),
        communitys: ref([
          {
            value: '1',
            label: 'Option1'
          },
          {
            value: '2',
            label: 'Option2'
          },
          {
            value: '3',
            label: 'Option3'
          },
          {
            value: '4',
            label: 'Option4'
          },
          {
            value: '5',
            label: 'Option5'
          }
        ]),
        houseTypeData: houseTypeData,
        floorTypeStrData: floorTypeStrData,
        selectDecoration: selectDecoration,
        selectPropertyRight: selectPropertyRight,
        selectOrientation: selectOrientation,
        selectElevator: selectElevator,
        selectHouseUse: selectHouseUse,
        imgs: imgsData, // 室内图
        imgs_outside: imgstrOutsideData, // 室外图
        imgs_house: imgsHouseData, // 户型图
        discounts_day: ref<Moment>() // 优惠活动截止时间
      }
    },
    methods: {
      change() {
        this.$forceUpdate()
      },
      goBack() {
        this.$router.go(-1)
      },
      finishUpload (img: string) {
        // console.log('finishUpload', img)
        this.imgs.push(img)
      },
      del(index:number) {
        // console.log('删除', index)
        this.imgs.splice(index, 1)
      },
      finishUpload2 (img: string) {
        // console.log('finishUpload', img)
        this.imgs_outside.push(img)
      },
      del2(index:number) {
        // console.log('删除', index)
        this.imgs_outside.splice(index, 1)
      },
      finishUpload3 (img: string) {
        // console.log('finishUpload', img)
        this.imgs_house.push(img)
      },
      del3(index:number) {
        // console.log('删除', index)
        this.imgs_house.splice(index, 1)
      }
    },
    created(): void {
      this.imgs = []
      this.imgs_outside = []
      this.imgs_house = []
    },
    setup(props) {
      const router = useRouter()
      onMounted(() => {
        let cityCode = localStorage.getItem('cityCode')
        if (!cityCode) {
          cityCode = '450100'
        }
        // 读取省市区信息
        store.dispatch('getPCAForCityCode', cityCode)
      })
      const pca = computed(() => store.state.pca)
      const province_id = pca.value.province?.id
      const city_id = pca.value.city?.id
      const area_id = pca.value.area?.id
      const datab = getCurrentInstance()
      // console.log('我要卖房', pca)
      const formRef = ref()
      const formState: UnwrapRef<FormState> = reactive({
        title: '', // 标题
        community_id: '', // 小区id
        building_area: '', // 面积
        house_type: '', // 户型
        property_type: '', // 房屋类型
        decoration: '', // 装修
        property_right: '', // 产权
        orientation: '', // 朝向
        elevator: '', // 电梯
        total_price: '', // 期望售价
        floor: '', // 楼层
        all_floor: '', // 总层
        proportion_str: '', // 梯户比 - 梯
        proportion_str2: '', // 梯户比 - 户
        on_date_str: '', // 挂牌时间
        house_use: '', // 房屋用途
        housing_years: '', // 建成年代
        ownership: '', // 产权所属
        mortgage_str: '', // 抵押情况
        is_discounts: 0, // 优惠活动状态 0关闭 1开启
        discounts: 0, // 优惠金额
        discounts_time: '', // 截止时间
        pic1: '', // 室内图
        imgstr_outside: '', // 室外图
        imgstr_house: '' // 户型图
      })
      const checkNumber = async (rule: RuleObject, value: number) => {
        if (!value) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('请输入内容')
        } else {
          return Promise.resolve()
        }
      }
      const rules = {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 8, max: 50, message: '必须输入8-50个字符的标题信息', trigger: 'blur' }
        ],
        community_id: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        building_area: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        house_type: [
          { required: true, message: '请选择户型', trigger: 'change' }
        ],
        property_type: [
          { required: true, message: '请选择房屋类型', trigger: 'change' }
        ],
        decoration: [
          { required: true, message: '请选择装修情况', trigger: 'change' }
        ],
        property_right: [
          { required: true, message: '请选择产权年限', trigger: 'change' }
        ],
        orientation: [
          { required: true, message: '请选择房屋朝向', trigger: 'change' }
        ],
        elevator: [
          { required: true, message: '请选择电梯情况', trigger: 'change' }
        ],
        total_price: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ],
        floor: [
          { required: true, message: '请输入楼层', trigger: 'blur' }
        ],
        all_floor: [
          { required: true, message: '请输入总层', trigger: 'blur' }
        ],
        proportion_str: [
          { required: true, message: '请输入梯户比', trigger: 'blur' }
        ],
        proportion_str2: [
          { required: true, message: '请输入梯户比', trigger: 'blur' }
        ],
        on_date_str: [
          { required: true, message: '请输入挂牌时间', trigger: 'blur' }
        ],
        house_use: [
          { required: true, message: '请输入梯户比', trigger: 'change' }
        ]
      }
      // 上传图片 - 室内图
      const beforeUpload = (file: File) => {
        // console.log('beforeUpload')
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJPG) {
          createMessage('上传图片只能是JPG或PNG格式！', 'error')
        }
        return isJPG
      }
      // 提交
      const onSubmit = () => {
        // 室内图
        let imgstr = ''
        const imglist:any = datab?.data.imgs
        imglist.forEach((item:string) => {
          imgstr += item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '') + ','
        })
        if (imgstr.length > 0) {
          imgstr = imgstr.substr(0, imgstr.length - 1)
        }
        // console.log('室内图', imgstr)
        formState.pic1 = imgstr
        // 室外图
        let imgOutstr = ''
        const imgOutlist:any = datab?.data.imgs_outside
        imgOutlist.forEach((item:string) => {
          imgOutstr += item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '') + ','
        })
        if (imgOutstr.length > 0) {
          imgOutstr = imgOutstr.substr(0, imgOutstr.length - 1)
        }
        // console.log('室外图', imgOutstr)
        formState.imgstr_outside = imgOutstr
        // 户型图
        let imghousestr = ''
        const imgHouselist:any = datab?.data.imgs_house
        imgHouselist.forEach((item:string) => {
          imghousestr += item.replace('https://yidouapp.oss-cn-shenzhen.aliyuncs.com/', '') + ','
        })
        if (imghousestr.length > 0) {
          imghousestr = imghousestr.substr(0, imghousestr.length - 1)
        }
        // console.log('户型图', imghousestr)
        formState.imgstr_house = imghousestr
        // 表单验证
        // console.log('submit', formState)
        formRef.value.validate().then(() => {
          if (imglist.length < 3) {
            createMessage('室内图至少上传三张', 'error')
            return false
          }
          if (imgOutlist.length < 1) {
            createMessage('室外图至少上传一张', 'error')
            return false
          }
          if (imgHouselist.length < 1) {
            createMessage('户型图至少上传一张', 'error')
            return false
          }
          if (formState.is_discounts) {
            formState.is_discounts = 1
          } else {
            formState.is_discounts = 0
          }
          if (datab?.data.discounts_day) {
            const day:any = datab?.data.discounts_day
            formState.discounts_time = day.format('YYYY-MM-DD HH:mm:ss')
          } else {
            formState.discounts_time = ''
          }
          // console.log('values', formState, toRaw(formState))
          // 提交表单
          store.dispatch('sellHouse', toRaw(formState)).then(data => {
            // console.log('login', data)
            createMessage('提交成功', 'success')
            setTimeout(() => {
              // 跳转页面
              router.push('/center.html')
            }, 500)
          }).catch(e => {
            // console.log('onFormSubmit', e)
          })
        }).catch((error: ValidateErrorEntity<FormState>) => {
          console.log('error', error)
          createMessage('请按照要求填写完整的表单信息', 'error')
        })
      }
      const data = ref<any[]>([])
      const handleSearch = (val: string) => {
        // console.log('handleSearch', val)
        fetch(val, (d: any[]) => (data.value = d))
      }
      const handleChange = (val: string) => {
        // console.log('handleChange', val)
        formState.community_id = val
        fetch(val, (d: any[]) => (data.value = d))
      }
      return {
        beforeUpload,
        onSubmit,
        formRef,
        rules,
        form: formState,
        handleSearch,
        handleChange,
        data
      }
    }
  })
</script>

<style>
</style>
