<template>
  <div class="container-fluid">
    <!-- 自定义公共head组件 user登录用户信息 $route.meta.navShow全局变量main.ts中页面配置信息中meta下的navShow是否显示head -->
    <global-header :user="currentUser" v-show="$route.meta.navShow"></global-header>
    <Loader text="加载中" backgroud="rgba(0,0,0,0.8)" v-if="isLoading"></Loader>
    <router-view v-if="isRouterAlive"></router-view>
    <footer class="text-center py-4 text-secondary bg-dark mt-6 d-flex flex-column align-items-center justify-content-center">
      <div class="d-flex align-items-center justify-content-between" style="height: 181px;width: 1150px;">
        <div class="d-flex flex-column">
          <span class="w-100" style="font-size: 11px;color: white;text-align: left;">Copyrights © 2019-2022 广西宜豆网络科技有限公司丨地址：南宁市青秀区绿地中央广场A9-1-402</span>
          <span class="w-100 mt-1" style="font-size: 11px;color: white;text-align: left;">所有丨营业执照丨ICP证丨网络文化经营许可证丨宜豆找房平台知识产权保护</span>
          <span class="w-100 mt-1" style="font-size: 11px;color: white;text-align: left;">违法和不良信息举报电话：0770-2713855丨违法和不良举报邮箱：123823036@qq.com</span>
          <span class="w-100 mt-1" style="font-size: 11px;color: white;text-align: left;">
            <el-link style="font-size: 11px;color: white;" href="https://beian.miit.gov.cn/">桂ICP备2020007622号-1</el-link>&nbsp;丨&nbsp;
            <el-link style="font-size: 11px;color: white;" href="https://www.12377.cn/">网上有害信息举报专区</el-link>
          </span>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="./assets/image/app_downurl.png" style="width: 100px;height: 100px">
            <span style="font-size: 11px;" class="text-white mt-2">《宜豆找房》</span>
            <span style="font-size: 11px;" class="text-white">APP下载</span>
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center ms-5">
            <img src="./assets/image/gh_346703206506_860.jpg" style="width: 100px;height: 100px">
            <span style="font-size: 11px;" class="text-white mt-2">《宜豆找房》</span>
            <span style="font-size: 11px;" class="text-white">小程序</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, watch, onUnmounted, toRaw } from 'vue'
  import { useStore } from 'vuex'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import GlobalHeader from '@/components/GlobalHeader.vue'
  import { UserProps } from './store'
  import Loader from '@/components/Loader.vue'
  import createMessage from '@/hooks/createMessage'

  export default defineComponent({
    name: 'App',
    components: {
      GlobalHeader,
      Loader
    },
    // watch: {
    //   $route(currentRoute: any) {
    //     const url = currentRoute.path
    //     console.log('url', url)
    //   }
    // },
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }
    },
    setup() {
      const store = useStore()
      const currentUser = computed(() => store.state.user)
      const isLoading = computed(() => store.state.loading)
      const error = computed(() => store.state.error)
      console.log('currentUser', toRaw(currentUser.value))
      watch(() => error.value.status, () => {
        const { status, message } = error.value
        if (status && message) {
          createMessage(message, 'error')
        }
      })
      onUnmounted(() => {
        console.log('onUnmounted')
      })
      return {
        currentUser,
        isLoading,
        error
      }
    }
  })
</script>

<style>
  * {
    font-family: 'SourceHanSans';
  }

  .container {
    padding-right: 0px;
    padding-left: 0px;
  }
</style>
