<template>
  <form class="validate-form-container">
    <slot name="default"></slot>
    <div class="submit-area" @click.prevent="sumbitForm">
      <slot name="sumit">
        <button type="button" class="btn btn-primary">提交</button>
      </slot>
    </div>
  </form>
</template>

<script lang="ts">
  import { defineComponent, onUnmounted } from 'vue'
  import mitt from 'mitt'

  type ValidateFunc = () => boolean

  export const emitter = mitt()

  export default defineComponent({
    name: 'ValidateForm',
    emits: ['form-submit'],
    setup(props, context) {
      let funcArr: ValidateFunc[] = []
      const sumbitForm = () => {
        const result = funcArr.map(func => func()).every(result => result)
        context.emit('form-submit', result)
      }
      const callback = (func: any) => {
        funcArr.push(func)
      }
      emitter.on('form-item-created', callback)
      onUnmounted(() => {
        emitter.off('form-item-created', callback)
        funcArr = []
      })
      return {
        sumbitForm
      }
    }
  })
</script>

<style>

</style>
