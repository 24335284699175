<template>
  <div class="d-flex flex-column align-items-center" style="background-color: #ffffff;">
    <div class="d-flex flex-row align-items-center bg-white position-relative" style="width: 1150px;">
      <div class="d-flex flex-column w-100" style="min-height: 700px;">
        <div class="d-flex flex-row align-items-center justify-content-between mt-3">
          <a-breadcrumb>
            <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
            <a-breadcrumb-item><a href="/community_list.html">新房</a></a-breadcrumb-item>
            <a-breadcrumb-item>{{community.name}}</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="d-flex flex-row align-items-center me-3">
            <a-input-search
              v-model:value="keyword"
              placeholder="请输入楼盘名或地址"
              size="small"
              class="mt-3 ms-5"
              style="width: 348px;"
              @search="onSreach"
            >
              <template #enterButton>
                <a-button style="background-color: #FF9326;color: white;width: 94px;">搜索</a-button>
              </template>
            </a-input-search>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center" style="margin-top: 40px;">
          <span style="font-size: 26px;color: #333333;font-weight: bold;">{{community.name}}</span>
          <span style="font-size: 15px;color: white;padding: 1px 10px;border-radius: 5px;background-color: #FD6769;margin-left: 15px;">{{community.status_str}}</span>
          <span style="font-size: 15px;color: white;padding: 1px 10px;border-radius: 5px;background-color: #6392FF;margin-left: 5px;">{{community.property_type_str}}</span>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <div style="width: 516px;margin-top: 20px;">
              <a-carousel arrows dots-class="slick-dots slick-thumb">
                <template #customPaging="props">
                  <a>
                    <img :src="getImgUrl(props.i)"/>
                  </a>
                </template>
                <div v-for="(item,index) in community.detail_albums" :key="index">
                  <a-image :src="item" style="width: 500px;height: 337px;object-fit: cover;"/>
                </div>
              </a-carousel>
            </div>
          </div>
          <div class="d-flex flex-column" style="margin-left: 45px;flex: 1;">
            <div class="d-flex flex-row align-items-center" style="margin-top: 60px;">
              <span style="font-size: 15px;color: #999999;margin-right: 15px;">参考均价</span>
              <span style="font-size: 28px;color:#FD6769;font-weight: bold;margin-bottom: 4px;margin-right: 2px;">{{community.average_price}}</span>
              <span style="font-size: 17px;color:#FD6769;margin-top: 2px;">元/㎡</span>
            </div>
            <div class="d-flex flex-row align-items-center mt-2">
              <span style="font-size: 13px;color: #96AFC0;background-color: #F2F5F7;padding: 1px 10px;border-radius: 5px;margin-right: 10px;"
              v-for="(item,index) in community.feature_name" :key="index" >{{item}}</span>
            </div>
            <a-divider />
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center" style="width: 300px;">
                <span style="width: 80px;font-size: 17px;color: #BBB;">首付</span>
                <span style="font-size: 17px;color: #333;">{{community.frist_payment_ratio_str}}</span>
              </div>
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <span style="width: 80px;font-size: 17px;color: #BBB;">首付比例</span>
                <span style="font-size: 17px;color: #333;">{{community.frist_payments_str}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center" style="width: 300px;">
                <span style="width: 80px;font-size: 17px;color: #BBB;">总价</span>
                <span style="font-size: 17px;color: #333;">{{community.total_price}}</span>
              </div>
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <span style="width: 80px;font-size: 17px;color: #BBB;">建面</span>
                <span style="font-size: 17px;color: #333;">{{community.building_area}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center" style="width: 300px;">
                <span style="width: 80px;font-size: 17px;color: #BBB;">戶型</span>
                <span style="font-size: 17px;color: #333;">{{community.house_type}}</span>
              </div>
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <span style="width: 80px;font-size: 17px;color: #BBB;">年代</span>
                <span style="font-size: 17px;color: #333;">{{community.built_in_s}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center" style="width: 300px;">
                <span style="width: 80px;font-size: 17px;color: #BBB;">产权</span>
                <span style="font-size: 17px;color: #333;">{{community.property_right_str}}</span>
              </div>
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <span style="width: 80px;font-size: 17px;color: #BBB;">装修</span>
                <span style="font-size: 17px;color: #333;">{{community.decorate_str}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center" style="width: 300px;">
                <span style="width: 80px;font-size: 17px;color: #BBB;">朝向</span>
                <span style="font-size: 17px;color: #333;">{{community.orientation}}</span>
              </div>
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <span style="width: 80px;font-size: 17px;color: #BBB;">电梯</span>
                <span style="font-size: 17px;color: #333;">{{community.info ? community.info.elevator:'未知'}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center" style="width: 300px;">
                <span style="width: 80px;font-size: 17px;color: #BBB;">楼层</span>
                <span style="font-size: 17px;color: #333;">{{community.floor}}</span>
              </div>
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <span style="width: 80px;font-size: 17px;color: #BBB;">状态</span>
                <span style="font-size: 17px;color: #333;">{{community.status_str}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <span style="width: 80px;font-size: 17px;color: #BBB;">开发商</span>
                <span style="font-size: 17px;color: #333;">{{community.development_enterprise}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <span style="width: 80px;font-size: 17px;color: #BBB;">地址</span>
                <span style="font-size: 17px;color: #333;">{{community.address}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 24px;color: #333333;font-weight: bold;">置业顾问</span>
          <div class="d-flex flex-row align-items-center mt-3">
            <div class="d-flex flex-row align-items-center me-3">
              <img src="../../assets/image/home_sale_logo.png" style="width: 20px;height: 20px;">
              <span style="font-size: 14px;color: #999999;margin-left: 10px;">案场专职</span>
            </div>
            <div class="d-flex flex-row align-items-center me-3">
              <img src="../../assets/image/home_sale_logo.png" style="width: 20px;height: 20px;">
              <span style="font-size: 14px;color: #999999;margin-left: 10px;">楼盘导购</span>
            </div>
            <div class="d-flex flex-row align-items-center me-3">
              <img src="../../assets/image/home_sale_logo.png" style="width: 20px;height: 20px;">
              <span style="font-size: 14px;color: #999999;margin-left: 10px;">户型解析</span>
            </div>
            <div class="d-flex flex-row align-items-center me-3">
              <img src="../../assets/image/home_sale_logo.png" style="width: 20px;height: 20px;">
              <span style="font-size: 14px;color: #999999;margin-left: 10px;">政策解读</span>
            </div>
          </div>
          <div class="d-flex align-items-center mt-3" style="min-height: 250px;overflow-x: scroll;">
            <div class="d-flex flex-column" style="width: 130px;margin-right: 20px;" v-for="(item,index) in community.brokerList" :key="index">
              <img :src="item.avatar" style="width: 100%;height: 130px;object-fit: cover;border-radius: 5px;">
              <div class="d-flex flex-column mt-2" style="flex: 1;height: 100%;">
                <span style="font-size: 15px;color: #333333;">{{item.name}}</span>
                <div class="d-flex flex-row align-items-center mt-1">
                  <img src="../../assets/image/xinfangxiangqing_icon_dianhua_default.png" style="width: 18px;height: 18px;">
                  <span style="font-size: 15px;color: #FF9326;margin-left: 5px;">{{item.phone}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 24px;color: #333333;font-weight: bold;">户型介绍</span>
          <div class="d-flex align-items-center mt-3" style="min-height: 300px;overflow-x: scroll;">
            <div class="d-flex flex-column" style="width: 160px;margin-right: 20px;" v-for="(item,index) in community.communityPhotoAlbums" :key="index">
              <a-image :src="item.path" style="width: 100%;height: 180px;object-fit: cover;border-width: 1px;border-style: solid;border-color: #999;"/>
              <div class="d-flex flex-column mt-2" style="flex: 1;height: 100%;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                  <span style="font-size: 15px;color: #333333;">{{item.description}}</span>
                  <span style="font-size: 11px;color: #fff;background-color: #6392FF;padding: 0px 10px;border-radius: 5px;">{{item.house_status}}</span>
                </div>
                <span style="font-size: 15px;color: #333333;">{{item.type_name}}</span>
                <span style="font-size: 15px;color: #333333;">建面 {{item.house_area}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;" v-if="community.latitude > 0">
          <span style="font-size: 24px;color: #333333;font-weight: bold;margin-bottom: 20px;">楼盘位置</span>
          <map-container :lat="community.latitude" :lng="community.longitude" :title="community.name" :address="community.address"></map-container>
        </div>
        <div class="d-flex flex-column" style="margin-top: 40px;">
          <span style="font-size: 24px;color: #333333;font-weight: bold;">附近楼盘</span>
          <div class="d-flex flex-row align-items-center justify-content-between mt-3 flex-wrap">
            <div class="d-flex flex-column mt-2" style="width: 267px;" v-for="(item,index) in coumunityList" :key="index">
              <div class="d-flex flex-column position-relative" style="border-radius: 5px;" v-if="index < 8">
                <a :href="`/community_detail.html/${item.id}`">
                  <img :src="item.cover_pic" class="w-100" style="height: 205px;object-fit: cover;border-radius: 5px;">
                  <div class="position-absolute w-100 h-100" style="top: 0;z-index: 1;background-color: #1a1a1a66"></div>
                  <span class="position-absolute text-center w-100 font_size3 text-white"
                        style="top: 50%;left: 0;z-index: 2;">{{item.name}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, onMounted, ref, PropType, UnwrapRef, reactive, toRaw, getCurrentInstance } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import store from '@/store'
  import MapContainer from '@/components/MapContainer.vue'
  export default defineComponent({
    name: 'CommunityDetail',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      MapContainer
    },
    setup() {
      onMounted(() => {
        store.dispatch('getCommunityDetail', id)
        store.dispatch('getNearbyCommunityList', {
          city_id: localStorage.getItem('cityCode'),
          building_id: id
        })
      })
      const route = useRoute()
      const router = useRouter()
      const id = +route.params.id
      // console.log('id', id)
      const keyword = ref('')
      const community = computed(() => store.state.community)
      const coumunityList = computed(() => store.state.community_list)
      const getImgUrl = (i) => {
        return community.value.detail_albums[i]
      }
      const onSreach = () => {
        // console.log('搜索', keyword)
        router.push({ name: 'community_list', params: { keywords: keyword.value } })
      }
      return {
        keyword,
        community,
        coumunityList,
        getImgUrl,
        onSreach
      }
    }
  })
</script>

<style scoped>
  .ant-carousel :deep(.slick-dots) {
    position: relative;
    height: auto;
    width: 96% !important;
    text-align: left!important;
    margin-left: 3px!important;
  }
  .ant-carousel :deep(.slick-slide img) {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    max-width: 100%;
  }
  .ant-carousel :deep(.slick-arrow) {
    display: none !important;
  }
  .ant-carousel :deep(.slick-thumb) {
    bottom: 0px;
  }
  .ant-carousel :deep(.slick-thumb li) {
    width: 95px!important;
    height: 66px!important;
  }
  .ant-carousel :deep(.slick-thumb li img) {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
  }
  .ant-carousel :deep .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
</style>
